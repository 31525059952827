import { Components, Theme } from '@mui/material';
import themeTypography from '../themeTypography';
import colors from '../themeColorPalette';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        ...themeTypography.text.xs.medium,
        backgroundColor: colors.grey[900],
        color: '#FFF',
        borderRadius: '8px',
      },
    },
  },
};

export default components.MuiTooltip;
