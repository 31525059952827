const displayXL = {
  fontSize: 60,
  fontStyle: 'normal',
  lineHeight: '72px',
  letterSpacing: '-1.2px',
};

const displayLg = {
  fontSize: 48,
  fontStyle: 'normal',
  lineHeight: '60px',
  letterSpacing: '-0.96px',
};

const displayMd = {
  fontSize: 36,
  fontStyle: 'normal',
  lineHeight: '44px',
  letterSpacing: '-0.72px',
};

const displaySm = {
  fontSize: 30,
  fontStyle: 'normal',
  lineHeight: '38px',
};

const displayXs = {
  fontSize: 24,
  fontStyle: 'normal',
  lineHeight: '32px',
};

const textXL = {
  fontSize: 20,
  fontStyle: 'normal',
  lineHeight: '30px',
};

const textLg = {
  fontSize: 18,
  fontStyle: 'normal',
  lineHeight: '28px',
};

const textMd = {
  fontSize: 16,
  fontStyle: 'normal',
  lineHeight: '24px',
};

const textSm = {
  fontSize: 14,
  fontStyle: 'normal',
  lineHeight: '20px',
};

const textXs = {
  fontSize: 12,
  fontStyle: 'normal',
  lineHeight: '18px',
};

const untitledTypography = {
  display: {
    xl: {
      regular: {
        ...displayXL,
        fontWeight: 400,
      },
      medium: {
        ...displayXL,
        fontWeight: 500,
      },
      semibold: {
        ...displayXL,
        fontWeight: 600,
      },
      bold: {
        ...displayXL,
        fontWeight: 700,
      },
    },
    lg: {
      regular: {
        ...displayLg,
        fontWeight: 400,
      },
      medium: {
        ...displayLg,
        fontWeight: 500,
      },
      semibold: {
        ...displayLg,
        fontWeight: 600,
      },
      bold: {
        ...displayLg,
        fontWeight: 700,
      },
    },
    md: {
      regular: {
        ...displayMd,
        fontWeight: 400,
      },
      medium: {
        ...displayMd,
        fontWeight: 500,
      },
      semibold: {
        ...displayMd,
        fontWeight: 600,
      },
      bold: {
        ...displayMd,
        fontWeight: 700,
      },
    },

    sm: {
      regular: {
        ...displaySm,
        fontWeight: 400,
      },
      medium: {
        ...displaySm,
        fontWeight: 500,
      },
      semibold: {
        ...displaySm,
        fontWeight: 600,
      },
      bold: {
        ...displaySm,
        fontWeight: 700,
      },
    },
    xs: {
      regular: {
        ...displayXs,
        fontWeight: 400,
      },
      medium: {
        ...displayXs,
        fontWeight: 500,
      },
      semibold: {
        ...displayXs,
        fontWeight: 600,
      },
      bold: {
        ...displayXs,
        fontWeight: 700,
      },
    },
  },
  text: {
    xl: {
      regular: {
        ...textXL,
        fontWeight: 400,
      },
      medium: {
        ...textXL,
        fontWeight: 500,
      },
      semibold: {
        ...textXL,
        fontWeight: 600,
      },
      bold: {
        ...textXL,
        fontWeight: 700,
      },
    },
    lg: {
      regular: {
        ...textLg,
        fontWeight: 400,
      },
      medium: {
        ...textLg,
        fontWeight: 500,
      },
      semibold: {
        ...textLg,
        fontWeight: 600,
      },
      bold: {
        ...textLg,
        fontWeight: 700,
      },
    },
    md: {
      regular: {
        ...textMd,
        fontWeight: 400,
      },
      medium: {
        ...textMd,
        fontWeight: 500,
      },
      semibold: {
        ...textMd,
        fontWeight: 600,
      },
      bold: {
        ...textMd,
        fontWeight: 700,
      },
    },

    sm: {
      regular: {
        ...textSm,
        fontWeight: 400,
      },
      medium: {
        ...textSm,
        fontWeight: 500,
      },
      semibold: {
        ...textSm,
        fontWeight: 600,
      },
      bold: {
        ...textSm,
        fontWeight: 700,
      },
    },
    xs: {
      regular: {
        ...textXs,
        fontWeight: 400,
      },
      medium: {
        ...textXs,
        fontWeight: 500,
      },
      semibold: {
        ...textXs,
        fontWeight: 600,
      },
      bold: {
        ...textXs,
        fontWeight: 700,
      },
    },
  },
};

export default untitledTypography;
