const colorPalette = {
  primary: {
    '25': '#E6F6F6',
    '50': '#CCEDED',
    '100': '#B3E4E4',
    '200': '#E9D7FE',
    '300': '#9FDBDB',
    '400': '#1AAFAF',
    '500': '#00A6A6',
    '600': '#008585',
    '700': '#006464',
    '800': '#004242',
    '900': '#002121',
    '950': '#001111',
  },
  error: {
    '25': '#FFFBFA',
    '50': '#FEF3F2',
    '100': '#FEE4E2',
    '200': '#FECDCA',
    '300': '#FDA29B',
    '400': '#F97066',
    '500': '#F04438',
    '600': '#D92D20',
    '700': '#B42318',
    '800': '#912018',
    '900': '#7A271A',
    '950': '#55160C',
  },
  warning: {
    '25': '#FFFCF5',
    '50': '#FFFAEB',
    '100': '#FEF0C7',
    '200': '#FEDF89',
    '300': '#FEC84B',
    '400': '#FDB022',
    '500': '#F79009',
    '600': '#DC6803',
    '700': '#B54708',
    '800': '#93370D',
    '900': '#7A2E0E',
    '950': '#4E1D09',
  },
  grey: {
    '25': '#FCFCFD',
    '50': '#F9FAFB',
    '100': '#F2F4F7',
    '200': '#EAECF0',
    '300': '#D0D5DD',
    '400': '#98A2B3',
    '500': '#667085',
    '600': '#475467',
    '700': '#344054',
    '800': '#1D2939',
    '900': '#101828',
    '950': '#0C111D',
  },
  success: {
    '25': '#F6FEF9',
    '50': '#ECFDF3',
    '100': '#DCFAE6',
    '200': '#ABEFC6',
    '300': '#75E0A7',
    '400': '#47CD89',
    '500': '#17B26A',
    '600': '#079455',
    '700': '#067647',
    '800': '#085D3A',
    '900': '#074D31',
    '950': '#053321',
  },
  blue: {
    '25': '#F5FAFF',
    '50': '#eff8ff',
    '100': '#D1E9FF',
    '200': '#b2ddff',
    '300': '#84CAFF',
    '400': '#53B1FD',
    '500': '#2E90FA',
    '600': '#1570EF',
    '700': '#175cd3',
    '800': '#1849A9',
    '900': '#194185',
    '950': '#102A56',
  },
  'blue-light': {
    '25': '#F5FBFF',
    '50': '#F0F9FF',
    '100': '#E0F2FE',
    '200': '#B9E6FE',
    '300': '#7CD4FD',
    '400': '#36BFFA',
    '500': '#0BA5EC',
    '600': '#0086C9',
    '700': '#026AA2',
    '800': '#065986',
    '900': '#0B4A6F',
    '950': '#062C41',
  },
};

export default colorPalette;
