import { useEffect, FC, memo } from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import UntitledAlert, { UntitledAlertProps } from '../../components/UntitledAlert/UntitledAlert';

const SlideTransition: FC<SlideProps> = memo(function SlideTransition(props: SlideProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction="left" />;
});

interface AlertContextSnackbarProps extends Omit<SnackbarProps, 'id'> {
  handleClose: (id?: string | number) => void;
  autoCloseIn?: number;
  alertProps: UntitledAlertProps;
  id?: string | number;
}

const AlertContextSnackbar: FC<AlertContextSnackbarProps> = memo(function TransitionsSnackbar(
  props: AlertContextSnackbarProps,
) {
  const { open, id, handleClose, autoCloseIn, alertProps, ...rest } = props;

  useEffect(() => {
    let timeOut: number;
    if (open && autoCloseIn) {
      timeOut = setTimeout(() => {
        handleClose(id);
      }, autoCloseIn);
    }

    return () => {
      if (timeOut) clearTimeout(timeOut);
    };
  }, [autoCloseIn, handleClose, open, id]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      TransitionComponent={SlideTransition}
      {...rest}
    >
      <UntitledAlert
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...alertProps}
        onClose={handleClose}
      />
    </Snackbar>
  );
});

AlertContextSnackbar.defaultProps = {
  autoCloseIn: undefined,
};

export default AlertContextSnackbar;
