import { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Constants } from '../constants';
import { texts } from '../resources';
import utils from '../shared/utils';

import './NetworkStatusContext.scss';

const contextDefaultValues: {
  isOnline: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
} = {
  isOnline: true,
  open: false,
  setOpen: () => {},
};

export const NetworkDetectorContext = createContext(contextDefaultValues);

interface NetworkDetectorContextProps {
  children: React.ReactNode;
}

function NetworkDetectorProvider({ children }: NetworkDetectorContextProps) {
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [numberOfTimeConnectionChanged, setNumberOfTimeConnectionChanged] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [isMouseMoved, setIsMouseMoved] = useState(false);

  // set is mouse moved after connection is back to true
  const onMouseMove = useCallback(() => {
    if (isOnline && !isMouseMoved && open) setIsMouseMoved(true);
  }, [isMouseMoved, isOnline, open]);

  const handleInternetConnectionOff = useCallback(() => {
    if (isOnline) {
      document.body.dispatchEvent(
        new CustomEvent(Constants.CUSTOM_EVENT.INTERNET_CONNECTION_CHANGE, {
          detail: { isOnline: false, isOffline: true },
        }),
      );
      setOpen(true);
      setNumberOfTimeConnectionChanged((prev) => prev + 1);
      setIsOnline(false);
    }
  }, [isOnline]);

  const handleInternetConnectionOn = useCallback(() => {
    if (!isOnline) {
      document.body.dispatchEvent(
        new CustomEvent(Constants.CUSTOM_EVENT.INTERNET_CONNECTION_CHANGE, {
          detail: { isOnline: true, isOffline: false },
        }),
      );
      // when is online don't show the snackbar at first time since the default value(first state value) is offline
      setOpen(numberOfTimeConnectionChanged > 0);
      setNumberOfTimeConnectionChanged((prev) => prev + 1);
      setIsOnline(true);
    }
  }, [isOnline, numberOfTimeConnectionChanged]);

  // add event listener to mouse move
  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  // ping google to check connection
  useEffect(() => {
    const interval = setInterval(() => {
      utils.general
        .fetchWithTimeout('https://www.google.com/', {
          mode: 'no-cors',
          timeout: Constants.PING_GOOGLE_TIMEOUT,
        })
        .then(handleInternetConnectionOn)
        .catch(handleInternetConnectionOff);
    }, Constants.PING_GOOGLE_TIMEOUT);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [handleInternetConnectionOff, handleInternetConnectionOn]);

  useEffect(() => {
    if (isMouseMoved && !isOnline && open) {
      setIsMouseMoved(false);
    }
  }, [isMouseMoved, isOnline, open]);

  const handleCloseSnackBar = useCallback(
    (_: Event | React.SyntheticEvent<unknown, Event>, reason: SnackbarCloseReason) => {
      if (reason === 'timeout') setOpen(false);
    },
    [],
  );

  const providerValue = useMemo(() => ({ isOnline, open, setOpen }), [isOnline, open, setOpen]);

  return (
    <NetworkDetectorContext.Provider value={providerValue}>
      <Snackbar
        className="internet-connection-snackbar"
        message={
          isOnline
            ? texts.INTERNET_SNACKBAR_MESSAGES.ONLINE
            : texts.INTERNET_SNACKBAR_MESSAGES.OFFLINE
        }
        open={open}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={isOnline && isMouseMoved && open ? 10000 : undefined}
        onClick={(e) => {
          if (e?.preventDefault) {
            e.preventDefault(); // prevented to not closing update shift form when user clicks on this component
          }
        }}
        ClickAwayListenerProps={{
          onClickAway: () => {},
        }}
        onClose={(e, reason) => {
          if (e?.preventDefault) {
            e.preventDefault(); // prevented to not closing update shift form when user clicks on this component
          }
          handleCloseSnackBar(e, reason);
        }}
        action={
          <>
            <div className={`snackbar-icon internet-connection-status connected-${isOnline}`}>
              {isOnline ? <WifiIcon /> : <WifiOffIcon />}
            </div>
            <IconButton className="snackbar-icon close-button" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </>
        }
      />
      {children}
    </NetworkDetectorContext.Provider>
  );
}

export default NetworkDetectorProvider;
