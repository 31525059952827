/* eslint-disable react/jsx-props-no-spreading */
import { FC, useCallback, ReactNode } from 'react';
import DialogContainer, { DialogProps } from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import { closeDialog } from '../../store/slices/dialogSlice';
import { DialogName } from '../../enums';

import './Dialog.scss';

export interface UntitledDialogProps extends Omit<DialogProps, 'title'> {
  dialogId: DialogName;
  open: boolean;
  children: ReactNode;
  TitleProps?: DialogTitleProps;
  onExit?: () => void;
}
const UntitledDialog: FC<UntitledDialogProps> = function UntitledDailog(
  props: UntitledDialogProps,
) {
  const { dialogId, open, children, TitleProps, onExit, ...restProps } = props;
  const dispatch = useDispatch();

  const onHide = useCallback(() => {
    if (onExit) onExit();
    dispatch(closeDialog(dialogId));
    return undefined;
  }, [dialogId, dispatch, onExit]);

  return (
    <DialogContainer
      {...restProps}
      id={dialogId}
      open={open}
      aria-labelledby={dialogId}
      onClose={onHide}
      TransitionProps={{ exit: false }}
    >
      {open && children}
    </DialogContainer>
  );
};

UntitledDialog.defaultProps = {
  TitleProps: {},
  onExit: () => {},
};

export default UntitledDialog;
