/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Notification } from '../../types/Notifications';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import utils from '../../shared/utils';
import { State } from '../../types/Store';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: Req;
}

export type InitialState = {
  fetchNotifications: ActionType<Notification[], ResponseSuccess<Notification[]>>;
  updateNotifiation: ActionType<undefined, ResponseSuccess<boolean>>;
  updateNotifiationMulti: ActionType<undefined, ResponseSuccess<boolean>[]>;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
};

const initialState: InitialState = {
  fetchNotifications: { ...ACTIONS_INITIAL_STATE, data: [] },
  updateNotifiation: { ...ACTIONS_INITIAL_STATE, data: undefined },
  updateNotifiationMulti: { ...ACTIONS_INITIAL_STATE, data: undefined },
};

const NotificationsSlice = createSlice({
  name: 'noficationss',
  initialState,
  reducers: {
    fetchNotificationsStarted: (state) => {
      state.fetchNotifications = {
        ...initialState.fetchNotifications,
        isLoading: true,
      };
    },
    fetchNotificationsError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchNotifications.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchNotifications.error = action.payload;
      } else {
        state.fetchNotifications.response = action.payload;
      }
      state.fetchNotifications.data = [];
    },
    fetchNotificationsSuccess: (state, action: PayloadAction<ResponseSuccess<Notification[]>>) => {
      state.fetchNotifications.isLoading = false;
      state.fetchNotifications.data = action.payload.result;
    },

    updateNotifiationStarted: (state) => {
      state.updateNotifiation = { ...initialState.updateNotifiation, isLoading: true };
    },
    updateNotificationError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateNotifiation.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateNotifiation.error = action.payload;
      } else {
        state.updateNotifiation.response = action.payload;
      }
    },
    updateNotificationSuccess: (
      state,
      action: PayloadAction<{ response: ResponseSuccess<boolean>; id: number }>,
    ) => {
      state.updateNotifiation.isLoading = false;
      state.fetchNotifications.data = state.fetchNotifications.data.map((p) =>
        p.id === action.payload.id ? { ...p, read: +!p.read } : p,
      );
    },

    updateNotifiationMultiStarted: (state) => {
      state.updateNotifiationMulti = { ...initialState.updateNotifiationMulti, isLoading: true };
    },
    updateNotificationMultiError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateNotifiationMulti.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateNotifiation.error = action.payload;
      } else {
        state.updateNotifiation.response = action.payload;
      }
    },
    updateNotificationMultiSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>[];
        ids: number[];
        status: number[];
      }>,
    ) => {
      const { ids, status } = action.payload;
      const idsToStatusMap: { [key: string]: number } = {};
      const wasIdModified: { [key: string]: boolean } = {};
      ids.forEach((e, index) => {
        wasIdModified[e] = true;
        idsToStatusMap[e] = status[index];
      });
      state.fetchNotifications.data = state.fetchNotifications.data.map((e) => {
        if (wasIdModified[e.id]) return { ...e, read: idsToStatusMap[e.id] };
        return e;
      });
      state.updateNotifiationMulti.isLoading = false;
    },
  },
});

export default NotificationsSlice.reducer;

export const {
  updateNotifiationStarted,
  updateNotificationError,
  updateNotificationSuccess,
  fetchNotificationsError,
  fetchNotificationsStarted,
  fetchNotificationsSuccess,
  updateNotifiationMultiStarted,
  updateNotificationMultiError,
  updateNotificationMultiSuccess,
} = NotificationsSlice.actions;

export const selectGetNotificationsState = (state: State<InitialState>) =>
  state.notificationsSlice.fetchNotifications;

export const selectUpdateNotificationState = (state: State<InitialState>) =>
  state.notificationsSlice.updateNotifiation;

export const selectUpdateNotificationMultiState = (state: State<InitialState>) =>
  state.notificationsSlice.updateNotifiationMulti;
