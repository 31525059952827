export default function IconMask() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        fill="none"
        className="mask mask-1"
      >
        <circle r="47.5" stroke="#EAECF0" cx={48} cy={48} />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="144"
        height="144"
        fill="none"
        className="mask mask-2"
      >
        <circle r="72" stroke="#EAECF0" cx={72} cy={72} />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="192"
        fill="none"
        className="mask mask-3"
      >
        <circle r="96" stroke="#EAECF0" cx={96} cy={96} />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="240"
        height="240"
        fill="none"
        className="mask mask-4"
      >
        <circle r="120" stroke="#EAECF0" cx={120} cy={120} />
      </svg>
    </>
  );
}
