// weekly schedule actions
export const GET_WEEKLY_SCHEDULE = 'weekly/schedule/get';
export const GET_PREVIOUS_WEEKLY_SCHEDULE = 'weekly/previous/schedule/get';
export const PUBLISH_WEEKLY_SCHEDULE = 'weekly/schedule/publish';
export const GET_WEEKLY_SHIFTS = 'weekly/shifts/get';

export const QUICK_ADD_SET_SELECTED_DEFAULT_SHIFT = 'weekly/schedule/select_quick_add_shift';
export const QUICK_ADD_SHIFT = 'weekly/schedule/quick_add_shift';

export const ADD_SHIFT_BY_PREFERENCES = 'weekly/schedule/add_shift_by_preferences';

// selecting weekly schedule actions
export const UPDATE_SELECTED_WEEKLY_SCHEDULES = 'weekly/schedule/selected/update';
export const DELETE_SELECTED_WEEKLY_SCHEDULES = 'weekly/schedule/selected/delete';

// copy from actions
export const GET_COPY_FROM_WEELY_SCHEDULE = 'weekly/schedule/copy_from/get';
export const ADD_COPY_FROM_WEELY_SCHEDULE = 'weekly/schedule/copy_from/add';
export const UPDATE_COPY_FROM_WEELY_SCHEDULE = 'weekly/schedule/copy_from/update';
export const DELETE_COPY_FROM_WEELY_SCHEDULE = 'weekly/schedule/copy_from/delete';
export const FILTER_COPY_FROM_WEELY_SCHEDULE = 'weekly/schedule/copy_from/filter';
export const CLEAR_COPY_FROM_WEELY_SCHEDULE = 'weekly/schedule/copy_from/clear';

export const GET_COMPANY_EMPLOYEES = 'company_employees/get';

export const ADD_TEMPORARY_EMPLOYEE = 'temporary_employee/add';

// sales actions
export const GET_WEEKLY_SALES = 'weekly/sales/get';
export const UPDATE_WEEKLY_SALES = 'weekly/sales/update';

export const ACCEPT_OFF_REQUEST = 'weekly/schedule/off_request/accept';
export const REJECT_OFF_REQUEST = 'weekly/schedule/off_request/reject';

export default {
  // weekly schedule actions
  GET_WEEKLY_SCHEDULE,
  GET_PREVIOUS_WEEKLY_SCHEDULE,
  PUBLISH_WEEKLY_SCHEDULE,
  GET_WEEKLY_SHIFTS,

  QUICK_ADD_SET_SELECTED_DEFAULT_SHIFT,
  QUICK_ADD_SHIFT,

  ADD_SHIFT_BY_PREFERENCES,

  // selected weekly schedule actions
  UPDATE_SELECTED_WEEKLY_SCHEDULES,
  DELETE_SELECTED_WEEKLY_SCHEDULES,

  // copy from actions
  GET_COPY_FROM_WEELY_SCHEDULE,
  ADD_COPY_FROM_WEELY_SCHEDULE,
  UPDATE_COPY_FROM_WEELY_SCHEDULE,
  DELETE_COPY_FROM_WEELY_SCHEDULE,
  FILTER_COPY_FROM_WEELY_SCHEDULE,
  CLEAR_COPY_FROM_WEELY_SCHEDULE,

  // sales actoins
  GET_WEEKLY_SALES,
  UPDATE_WEEKLY_SALES,

  GET_COMPANY_EMPLOYEES,
  ADD_TEMPORARY_EMPLOYEE,

  // off request
  ACCEPT_OFF_REQUEST,
  REJECT_OFF_REQUEST,
};
