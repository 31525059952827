import { Components, Theme } from '@mui/material';
import MuiOutlinedInput from './outlinedinput';
import MuiInputBase from './inputbase';
import MuiSelect from './select';
import MuiFormHelperText from './helpertext';
import MuiInputLabel from './inputlabel';
import MuiButton from './button';
import MuiTooltip from './tooltip';
import MuiIconButton from './iconbutton';
import MuiAutocomplete from './autocomplete';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '12px',
        padding: '24px',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      size: 'small',
      fullWidth: true,
    },
  },
  MuiSelect,
  MuiButton,
  MuiIconButton,
  MuiAutocomplete,

  MuiFormControl: {
    defaultProps: {
      variant: 'outlined',
      fullWidth: true,
    },

    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline legend': {
          width: '0px',
        },
      },
    },
  },

  MuiOutlinedInput,

  MuiInputBase,
  MuiInputLabel,

  MuiTooltip,
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '.9em',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFormHelperText,
};

export default components;
