import React from 'react';
import IconMask from './IconMask';
import PlusSquare from '../../../icons/PlusSquare';
import { SvgUntitledIconProps } from '../../../types/Shared';

type DialogIconProps = {
  Icon: React.ElementType<SvgUntitledIconProps> | null;
  theme: 'modern' | 'light-color-outline';
  color?: 'success' | 'warning' | 'error';
};

export default function DialogIcon(props: DialogIconProps) {
  const { Icon, theme, color } = props;

  return (
    <div className={`dialog-icon dialog-icon-${theme} dialog-icon-${color}`}>
      {Icon && <Icon />}
      {!Icon && <PlusSquare />}
      <IconMask />
    </div>
  );
}
