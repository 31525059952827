import { FC } from 'react';
import Button from '@mui/material/Button';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import UntitledCheckbox, { UntitledCheckboxProps } from '../../Checkbox/Checkbox';

import './index.scss';

type UntitledDialogFooterProps = {
  type: 'vertical' | 'horizontal' | 'horizontal-checkbox';
  color: 'primary' | 'warning' | 'error' | 'inherit' | 'secondary' | 'info' | 'success';
  leftButtonText?: string;
  rightButtonText?: string;
  leftButtonProps?: LoadingButtonProps;
  rightButtonProps?: LoadingButtonProps;
  checkboxProps?: UntitledCheckboxProps;
  hideLeftButton?: boolean;
  hideRightButton?: boolean;
};

const UntitledDialogFooter: FC<UntitledDialogFooterProps> = function UntitledDialogFooter(
  props: UntitledDialogFooterProps,
) {
  const {
    type,
    color,
    leftButtonText,
    rightButtonText,
    leftButtonProps,
    rightButtonProps,
    checkboxProps,
    hideLeftButton,
    hideRightButton,
  } = props;
  return (
    <div className={`dialog-footer dialog-footer-${type}`}>
      <div className="footer-content">
        {type === 'horizontal-checkbox' && (
          <UntitledCheckbox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(checkboxProps ?? {})}
          />
        )}
        <div className="dialog-footer-buttons">
          {!!leftButtonText && !hideLeftButton && (
            <LoadingButton
              variant="outlined"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...leftButtonProps}
            >
              {leftButtonText}
            </LoadingButton>
          )}

          {(hideLeftButton || hideRightButton) && (
            <Button type="button" style={{ visibility: 'hidden', pointerEvents: 'none' }} />
          )}

          {!!rightButtonText && !hideRightButton && (
            <LoadingButton
              variant="contained"
              color={color}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rightButtonProps}
            >
              {rightButtonText}
            </LoadingButton>
          )}
        </div>
      </div>
    </div>
  );
};

UntitledDialogFooter.defaultProps = {
  rightButtonProps: {},
  leftButtonProps: {},
  checkboxProps: {},
  hideLeftButton: false,
  hideRightButton: false,
  leftButtonText: '',
  rightButtonText: '',
};

export default UntitledDialogFooter;
