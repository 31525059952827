import { UserData } from '../types/Authentications';
import { UserRoleSettings } from '../types/Contexts';
import storageKeys from './storageKeys';

const sharedStorageAddItem = (key: string, data: unknown) =>
  localStorage.setItem(key, JSON.stringify(data));

const sharedStorageGetItem = (key: string) => {
  const data = localStorage.getItem(key);
  if (data === null) return null;
  return JSON.parse(data);
};

const sessionStorageAddItem = (key: string, data: unknown) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

const sessionStorageGetItem = (key: string) => {
  const data = sessionStorage.getItem(key);
  if (data === null) return data;
  return JSON.parse(data);
};

const setUserId = (userId: number) =>
  sessionStorageAddItem(storageKeys.USER_DATA.SCHEDEX_USER_ID, userId);
const getUserId = () => sessionStorageGetItem(storageKeys.USER_DATA.SCHEDEX_USER_ID);
const removeUserId = () => sessionStorage.removeItem(storageKeys.USER_DATA.SCHEDEX_USER_ID);

const getUserData = () => sharedStorageGetItem(storageKeys.USER_DATA.SCHEDEX_USER_DATA) as UserData;
const setUserData = (data: UserData) => {
  sharedStorageAddItem(storageKeys.USER_DATA.SCHEDEX_USER_DATA, data);
  setUserId(Number(data?.id));
};

const getEmail = () => sharedStorageGetItem(storageKeys.USER_DATA.SCHEDEX_USER_EMAIL) as string;
const setEmail = (data: string) =>
  sharedStorageAddItem(storageKeys.USER_DATA.SCHEDEX_USER_EMAIL, data);

const getSettings = () => sharedStorageGetItem(storageKeys.USER_DATA.SETTINGS) as UserRoleSettings;
const setSettings = (data: UserRoleSettings) =>
  sharedStorageAddItem(storageKeys.USER_DATA.SETTINGS, data);

const getShowExcelReportTip = () =>
  sharedStorageGetItem(storageKeys.TIPS.SHOW_REPORT_EXCEL_TIP) !== false;
const setShowExcelReportTip = (show: boolean) =>
  sharedStorageAddItem(storageKeys.TIPS.SHOW_REPORT_EXCEL_TIP, show);

const getShowCopyFromTip = () =>
  sharedStorageGetItem(storageKeys.TIPS.SHOW_COPY_FROM_TIP) !== false;
const setShowCopyFromTip = (show: boolean) =>
  sharedStorageAddItem(storageKeys.TIPS.SHOW_COPY_FROM_TIP, show);

const getShowRemoveMultiShiftTip = () =>
  sharedStorageGetItem(storageKeys.TIPS.SHOW_REMOVE_MULTI_SHIFTS_TIP) !== false;
const setShowRemoveMultiShiftTip = (show: boolean) =>
  sharedStorageAddItem(storageKeys.TIPS.SHOW_REMOVE_MULTI_SHIFTS_TIP, show);

const getShowEditMultiShiftTime = () =>
  sharedStorageGetItem(storageKeys.TIPS.SHOW_EDIT_MULTI_SHIFTS_TIME_TIP) !== false;
const setShowEditMultiShiftTime = (show: boolean) =>
  sharedStorageAddItem(storageKeys.TIPS.SHOW_EDIT_MULTI_SHIFTS_TIME_TIP, show);

const getShowShiftRequirements = () =>
  Boolean(sharedStorageGetItem(storageKeys.SHOW_HIDE_SETTINGS.SHOW_SHIFT_REQUIREMENTS));
const setShowShiftRequirements = (show: boolean) =>
  sharedStorageAddItem(storageKeys.SHOW_HIDE_SETTINGS.SHOW_SHIFT_REQUIREMENTS, show);

const getSelectedBranch: () => number = () =>
  sessionStorageGetItem(storageKeys.SELECTED_BRANCH.SCHEDEX_SELECTED_BRANCH_ID) ||
  sharedStorageGetItem(storageKeys.SELECTED_BRANCH.SCHEDEX_SELECTED_BRANCH_ID);

const setSelectedBranch = (branchId: number) => {
  sharedStorageAddItem(storageKeys.SELECTED_BRANCH.SCHEDEX_SELECTED_BRANCH_ID, branchId);
  sessionStorageAddItem(storageKeys.SELECTED_BRANCH.SCHEDEX_SELECTED_BRANCH_ID, branchId);
};

const getUserSelectedBranch = (userId: number) =>
  (sessionStorageGetItem(storageKeys.SELECTED_BRANCH.getUserSelectedBranch(userId)) ||
    sharedStorageGetItem(storageKeys.SELECTED_BRANCH.getUserSelectedBranch(userId))) as
    | number
    | null;

const setUserSelectedBranch = (userId: number, branchId: number) => {
  sharedStorageAddItem(storageKeys.SELECTED_BRANCH.getUserSelectedBranch(userId), branchId);
  sessionStorageAddItem(storageKeys.SELECTED_BRANCH.getUserSelectedBranch(userId), branchId);
};

const getReportDateRange = () =>
  sessionStorageGetItem(storageKeys.ATTENDANCE_REPORT.REPORT_DATE_RANGE) as {
    start_date: string;
    end_date: string;
  } | null;
const setReportDateRange = (data: { start_date: string; end_date: string }) =>
  sessionStorageAddItem(storageKeys.ATTENDANCE_REPORT.REPORT_DATE_RANGE, data);

const getReportSelectedBranches = () =>
  sessionStorageGetItem(storageKeys.ATTENDANCE_REPORT.REPORT_SELECTED_BRANCHES) as number[];
const setReportSelectedBranches = (data: number[]) =>
  sessionStorageAddItem(storageKeys.ATTENDANCE_REPORT.REPORT_SELECTED_BRANCHES, data);

const getReportPunchFilter = () =>
  sessionStorageGetItem(storageKeys.ATTENDANCE_REPORT.REPORT_PUNCH_FILTER) as number[];
const setReportPunchFilter = (data: number[]) =>
  sessionStorageAddItem(storageKeys.ATTENDANCE_REPORT.REPORT_PUNCH_FILTER, data);

const getReportSelectedColumns = () =>
  sessionStorageGetItem(storageKeys.ATTENDANCE_REPORT.REPORT_SELECTED_COLUMNS);
const setReportSelectedColumns = (data: string[]) =>
  sessionStorageAddItem(storageKeys.ATTENDANCE_REPORT.REPORT_SELECTED_COLUMNS, data);

const logout = () => {
  localStorage.removeItem(storageKeys.USER_DATA.SCHEDEX_USER_DATA);
  localStorage.removeItem(storageKeys.USER_DATA.SETTINGS);
  localStorage.removeItem(storageKeys.USER_DATA.SCHEDEX_USER_EMAIL);
  sessionStorage.clear();
};

const setAppRefreshedWhenConnectionIsBack = () =>
  sessionStorageAddItem(storageKeys.APP_REFRESHED, true);
const isAppRefreshedWhenConnectionIsBack = () =>
  sessionStorageGetItem(storageKeys.APP_REFRESHED) !== false;

const getShowAddMultiPunchTip = () =>
  sharedStorageGetItem(storageKeys.TIPS.SHOW_ADD_MULTI_PUNCH_TIP) !== false;
const setShowAddMultiPunchTip = (show: boolean) =>
  sharedStorageAddItem(storageKeys.TIPS.SHOW_ADD_MULTI_PUNCH_TIP, show);

export default {
  getUserData,
  setUserData,

  getEmail,
  setEmail,

  getSettings,
  setSettings,

  getShowExcelReportTip,
  setShowExcelReportTip,

  getShowCopyFromTip,
  setShowCopyFromTip,

  getShowRemoveMultiShiftTip,
  setShowRemoveMultiShiftTip,

  getShowAddMultiPunchTip,
  setShowAddMultiPunchTip,

  getShowEditMultiShiftTime,
  setShowEditMultiShiftTime,

  getShowShiftRequirements,
  setShowShiftRequirements,

  getSelectedBranch,
  setSelectedBranch,

  getUserSelectedBranch,
  setUserSelectedBranch,

  getUserId,
  setUserId,
  removeUserId,

  getReportDateRange,
  setReportDateRange,
  getReportSelectedBranches,
  setReportSelectedBranches,
  getReportPunchFilter,
  setReportPunchFilter,
  getReportSelectedColumns,
  setReportSelectedColumns,

  logout,

  setAppRefreshedWhenConnectionIsBack,
  isAppRefreshedWhenConnectionIsBack,
};
