export enum ActionStatus {
  ADD = 'ADD',
  APPROVE = 'APPROVE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  IMPORT = 'IMPORT',
  NOTIFY = 'NOTIFY',
  EDIT_REQUIREMENTS = 'EDIT_REQUIREMENTS',
  EDIT_TIME = 'EDIT_TIME',
  EDIT_NOTE = 'EDIT_NOTE',
}

export enum DialogName {
  WEEKLY_TABLE_FILTERS_DIALOG = 'WEEKLY_TABLE_FILTERS_DIALOG',
  SCHEDULE_DIALOG = 'NAME_SCHEDULE_DIALOG',
  REMOVE_EMPLOYEE_DIALOG = 'REMOVE_EMPLOYEE_DIALOG',
  IMPORT_EMPLOYEE_DIALOG = 'IMPORT_EMPLOYEE_DIALOG',
  ADD_SHIFT_DIALOG = 'ADD_SHIFT_DIALOG',
  EDIT_SHIFT_DIALOG = 'EDIT_SHIFT_DIALOG',
  REMOVE_SHIFT_DIALOG = 'REMOVE_SHIFT_DIALOG',
  REQUEST_DIALOG = 'NAME_REQUEST_DIALOG',
  ERROR_DIALOG = 'ERROR_DIALOG',
  SELECTED_SHIFTS_DIALOG = 'SELECTED_SHIFTS_DIALOG',
  SELECTED_DEFAULT_SHIFTS_DIALOG = 'SELECTED_DEFAULT_SHIFTS_DIALOG',
  SALES_INPUT_TYPES_DIALOG = 'SALES_INPUT_TYPES_DIALOG',
  SALES_PERCENTAGE_CAP = 'SALES_PERCENTAGE_CAP',
  MANAGE_SALES_INPUT_TYPES_DIALOG = 'MANAGE_SALES_INPUT_TYPES_DIALOG',
  PROJECTED_SALES_COMPUTATION_DIALOG = 'PROJECTED_SALES_COMPUTATION_DIALOG',
  ADD_TEMPORARY_EMPLOYEE_DIALOG = 'ADD_TEMPORARY_EMPLOYEE_DIALOG',
  TEMPORARY_EMPLOYEE_LIST_DIALOG = 'TEMPORARY_EMPLOYEE_LIST_DIALOG',
  CREATE_PASSWORD_DIALOG = 'CREATE_PASSWORD_DIALOG',
  RESET_PASSWORD_DIALOG = 'RESET_PASSWORD_DIALOG',
  MANAGE_POSITIONS_DIALOG = 'MANAGE_POSITIONS_DIALOG',
  NOTIFY_OPEN_SHIFT_EMPLOYEES = 'NOTIFY_OPEN_SHIFT_EMPLOYEES',
  OPEN_SHIFT_ACCEPTED_EMPLOYEES = 'OPEN_SHIFT_ACCEPTED_EMPLOYEES',
  OPEN_SHIFT_CONFIRM_EMPLOYEES = 'OPEN_SHIFT_CONFIRM_EMPLOYEES',
  MANAGE_OPEN_SHIFT_DIALOG = 'MANAGE_OPEN_SHIFT_DIALOG',
  OPEN_SHIFT_SELECTED_EMPLOYEES_DIALOG = 'OPEN_SHIFT_SELECTED_EMPLOYEES_DIALOG',
  REMOVE_OPEN_SHIFT_DIALOG = 'REMOVE_OPEN_SHIFT_DIALOG ',
  SELECTED_SCHEDULE_DIALOG = 'SELECTED_SCHEDULE_DIALOG',
  COPY_FROM_DEMO_DIALOG = 'COPY_FROM_DEMO_DIALOG',
  MULTI_SELECTED_SCHEDULES_ACTION_DEMO = 'MULTI_SELECTED_SCHEDULES_ACTION_DEMO',
  PRINT_EXCEL_FILE_DEMO = 'PRINT_EXCEL_FILE_DEMO',
  ADD_MULTI_PUNCH_TIP_DIALOG = 'ADD_MULTI_PUNCH_TIP_DIALOG',
  PUNCH_DIALOG = 'PUNCH_DIALOG',
  REJECT_OFF_REQUEST_DIALOG = 'REJECT_OFF_REQUEST_DIALOG',
  ROUTER_PROMPT = 'ROUTER_PROMPT',
}

export enum Permissions {
  FINANCIAL = 'Financial',
  MANAGE_FINANCIALS = 'MANAGE_FINANCIALS',
  VIEW_FINANCIALS = 'VIEW_FINANCIALS',
  MANAGE_EMPLOYEES = 'MANAGE_EMPLOYEES',
  MANAGE_SHIFTS = 'MANAGE_SHIFTS',
  MANAGE_PUNCHES = 'MANAGE_PUNCHES',
  VIEW_SALES = 'VIEW_SALES',
}

export enum PaymentType {
  NONE = 0,
  HOURLY = 1,
  PER_SHIFT = 2,
  MONTHLY = 3,
  PER_DAY = 4,
}

export enum KeyboardEventKeys {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}

export enum Weekday {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum ResponseStatus {
  Success = 'success',
  Fail = 'failure',
}

export enum TimeFormats {
  FULL_24_HOURS_FORMAT = 'HH:mm:ss',
}

export enum RequestErrorCode {
  ERR_FR_TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS',
  ERR_BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE',
  ERR_BAD_OPTION = 'ERR_BAD_OPTION',
  ERR_NETWORK = 'ERR_NETWORK',
  ERR_DEPRECATED = 'ERR_DEPRECATED',
  ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE',
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  ERR_NOT_SUPPORT = 'ERR_NOT_SUPPORT',
  ERR_INVALID_URL = 'ERR_INVALID_URL',
  ERR_CANCELED = 'ERR_CANCELED',
  ECONNABORTED = 'ECONNABORTED',
  ETIMEDOUT = 'ETIMEDOUT',
}

export enum SalesProjectionMethod {
  COPY_FROM_LAST_WEEK = 1,
  COMPUTE_TOTAL_SALES_OF_LAST_WEEK = 2,
}

export enum SalesProjectionMethodLabel {
  COPY_FROM_LAST_WEEK = 'Copy from last week',
  COMPUTE_TOTAL_SALES_OF_LAST_WEEK = 'Compute the total sales of the last week',
}

export enum CurrencyId {
  USD_DOLLAR = 1,
  LBP_LEBANESE_LIRA = 2,
  AED = 3,
  EURO = 5,
  EGP = 6,
  SGD = 7,
}

export enum EmployeeColumns {
  EMPLOYEE_CODE = 'ID',
  NAME = 'Name',
  EMAIL = 'Email',
  PHONE = 'Phone',
  ROLE = 'Role',
  PAYMENT_TYPE = 'Payment type',
  PAYMENT_RATE = 'Payment rate',
  ACTIONS = 'Actions',
  ANNUAL_LEAVE = 'A.L',
}

export enum CompanySettings {
  ANNUAL_LEAVE_ENABLED = 'annual_leave_enabled',
  EMPLOYEE_SUMMARY_ENABLED = 'employee_summary_enabled',
}

export default {
  ActionStatus,
  DialogName,
  Permissions,
};
