import { useEffect } from 'react';
import { analytics } from '../../resources';
import SchedexLogo from '../SchedexLogo/SchedexLogo';
import './MobileScreen.scss';

function MobileScreen() {
  useEffect(() => {
    analytics.trackPageView(analytics.pageViews.MOBILE_SCREEN);
  }, []);

  return (
    <div className="mobile-screen-container">
      <SchedexLogo />
      <div className="mobile-screen-body">
        <h2>Hey you!</h2>
        <p>
          It appears you are using a mobile device. Please open the web app from a desktop browser
          or download the mobile app &#128071;
        </p>
        <a
          href="http://bit.ly/2mQhAff"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            analytics.sendAnalyticEvent(
              analytics.categories.DOWNLOAD_ON_MOBILE,
              'Manager Mobile app tutorial link clicked',
            )
          }
        >
          Download
        </a>
      </div>
    </div>
  );
}

export default MobileScreen;
