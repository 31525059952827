import { PaymentType } from '../../enums';
import { WeeklyScheduleData, WeeklyScheduleTableFilter } from '../../types/WeeklySchedule';

const isFiltersEnabled = (filters: WeeklyScheduleTableFilter) =>
  !!filters.employees.length ||
  filters.has_break ||
  filters.has_comments ||
  filters.has_exchange ||
  filters.has_leave_requested ||
  !!filters.payment_type.length ||
  !!filters.positions.length ||
  !!filters.shifts.length;

const shouldFilterShifts = (filters: WeeklyScheduleTableFilter) =>
  filters.has_break ||
  filters.has_comments ||
  filters.has_exchange ||
  filters.has_leave_requested ||
  !!filters.payment_type.length ||
  !!filters.shifts.length;

const sanitizePositions = (data: WeeklyScheduleData) => {
  return data.filter((elem) => !!elem.data.length);
};

const sanitizeEmployees = (data: WeeklyScheduleData, filterShifts: boolean) => {
  return data.map((position) => ({
    ...position,
    data: position.data.filter((employee) =>
      filterShifts
        ? !!employee.schedules.length && !!employee.schedules.some((elem) => elem.schedule.length)
        : true,
    ),
  }));
};

const PaymentTypeFilter = (data: WeeklyScheduleData, paymentTypes: PaymentType[]) => {
  return data.map((position) => ({
    ...position,
    data: position.data.filter((employee) => {
      const employeeHasPaymentTypeIncluded = Object.keys(employee.roles_per_day).some((day) =>
        employee.roles_per_day[day].roles.some((role) => paymentTypes.includes(role.payment_type)),
      );

      if (!employeeHasPaymentTypeIncluded) {
        return (
          employee.schedules.some((daySched) =>
            daySched.schedule.some(
              (schedule) =>
                !!schedule.custom_payment?.payment_type_id &&
                paymentTypes.includes(+schedule.custom_payment.payment_type_id),
            ),
          ) || employee.schedules.some((day) => !!day.schedule.length)
        );
      }

      return true;
    }),
  }));
};

const shiftFilters = (data: WeeklyScheduleData, filter: WeeklyScheduleTableFilter) => {
  return data.map((position) => ({
    ...position,
    data: position.data.map((employee) => ({
      ...employee,
      schedules: employee.schedules.map((daySchedule) => ({
        ...daySchedule,
        schedule: daySchedule.schedule.filter(
          (schedule) =>
            (filter.has_break && !!schedule.break_time) ||
            (filter.has_exchange && !!schedule.is_exchange) ||
            (filter.has_comments && !!schedule.note) ||
            (filter.shifts.length && filter.shifts.includes(schedule.shift.name)) ||
            (filter.payment_type.length &&
              ((!!schedule.custom_payment?.payment_type_id &&
                filter.payment_type.includes(+schedule.custom_payment.payment_type_id)) ||
                employee.roles_per_day?.[daySchedule.date]?.roles?.some((role) =>
                  filter.payment_type.includes(role.payment_type),
                ))) ||
            (filter.has_leave_requested && schedule.start_time === schedule.end_time),
        ),
      })),
    })),
  }));
};

const offRequestFilter = (data: WeeklyScheduleData) =>
  data.map((position) => ({
    ...position,
    data: position.data.filter((employee) => {
      return employee.schedules.some(
        (schedule) =>
          !!schedule.off_request?.length ||
          schedule.schedule.some((sched) => sched.start_time === sched.end_time) ||
          schedule.schedule.length,
      );
    }),
  }));

const filterEmployees = (data: WeeklyScheduleData, employees: number[]) =>
  data.map((position) => ({
    ...position,
    data: position.data.filter((emp) => employees.includes(emp.id)),
  }));

const filterPositions = (data: WeeklyScheduleData, positions: number[]) =>
  data.filter((pos) => positions.includes(pos.id));

export function filterWeeklyTableData(
  data: WeeklyScheduleData,
  filters: WeeklyScheduleTableFilter,
) {
  if (!isFiltersEnabled(filters)) return data;

  const filterShifts = shouldFilterShifts(filters);
  let newData: WeeklyScheduleData = JSON.parse(JSON.stringify(data));
  if (filters.positions.length) newData = filterPositions(newData, filters.positions);
  if (filters.employees.length) newData = filterEmployees(newData, filters.employees);
  if (filterShifts) newData = shiftFilters(newData, filters);
  if (filters.has_leave_requested) newData = offRequestFilter(newData);
  if (filters.payment_type.length) newData = PaymentTypeFilter(newData, filters.payment_type);

  newData = sanitizeEmployees(newData, filterShifts);
  newData = sanitizePositions(newData);

  return newData;
}

export default {
  isFiltersEnabled,
  filterWeeklyTableData,
};
