import { PaletteOptions } from '@mui/material';
import colors from './themeColorPalette';

const palette: PaletteOptions = {
  primary: {
    main: colors.primary[600],
    '50': colors.primary[50],
    '100': colors.primary[100],
    '200': colors.primary[200],
    '300': colors.primary[300],
    '400': colors.primary[400],
    '500': colors.primary[500],
    '600': colors.primary[600],
    '700': colors.primary[700],
    '800': colors.primary[800],
    '900': colors.primary[900],
  },
  text: {
    primary: '#344054',
  },
  error: {
    main: colors.error[600],
    '50': colors.error[50],
    '100': colors.error[100],
    '200': colors.error[200],
    '300': colors.error[300],
    '400': colors.error[400],
    '500': colors.error[500],
    '600': colors.error[600],
    '700': colors.error[700],
    '800': colors.error[800],
    '900': colors.error[900],
  },
  warning: {
    main: colors.warning[600],
    '50': colors.warning[50],
    '100': colors.warning[100],
    '200': colors.warning[200],
    '300': colors.warning[300],
    '400': colors.warning[400],
    '500': colors.warning[500],
    '600': colors.warning[600],
    '700': colors.warning[700],
    '800': colors.warning[800],
    '900': colors.warning[900],
  },
  grey: {
    '50': colors.grey[50],
    '100': colors.grey[100],
    '200': colors.grey[200],
    '300': colors.grey[300],
    '400': colors.grey[400],
    '500': colors.grey[500],
    '600': colors.grey[600],
    '700': colors.grey[700],
    '800': colors.grey[800],
    '900': colors.grey[900],
  },
  success: {
    main: colors.success[600],
    '50': colors.success[50],
    '100': colors.success[100],
    '200': colors.success[200],
    '300': colors.success[300],
    '400': colors.success[400],
    '500': colors.success[500],
    '600': colors.success[600],
    '700': colors.success[700],
    '800': colors.success[800],
    '900': colors.success[900],
  },
};

export default palette;
