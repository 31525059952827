const shadowSM = `0px 1px 3px 0px rgba(16, 24, 40, 0.1),
0px 1px 2px 0px rgba(16, 24, 40, 0.06);`;

const shadowXS = `0px 1px 2px 0px rgba(16, 24, 40, 0.05);`;

const shadowXL = `0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);`;

const shadowLG = `0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);`;

const shadowMD = `0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);`;

const shadow2XL = `0px 24px 48px -12px rgba(16, 24, 40, 0.18);`;
const shadow3XL = `0px 32px 64px -12px rgba(16, 24, 40, 0.14);`;

export default {
  sm: shadowSM,
  xs: shadowXS,
  lg: shadowLG,
  xl: shadowXL,
  md: shadowMD,
  xxl: shadow2XL,
  xxxl: shadow3XL,
};
