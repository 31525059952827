import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Links from '../../Root/Routes/Links';

import './ErrorBoundaryView.scss';

const ErrorBoundaryView = function ErrorBoundaryView() {
  return (
    <div className="error-page">
      <h1>Oh no, something didn&apos;t go as planned!</h1>
      <p>It seems we&apos;ve hit a little snag, Don&apos;t worry, we&apos;re on it!.</p>
      <Button color="primary" onClick={() => window.location.reload()} type="button">
        <NavLink to={Links.WEEKLY_SCHEDULE_LINK}> Take me home </NavLink>
      </Button>
    </div>
  );
};

export default ErrorBoundaryView;
