import { call, takeLatest, put, all } from 'redux-saga/effects';
import _omit from 'lodash/omit';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import actionTypes from '../actionTypes/employees';
import { employeeActions } from '../slices/employeesSlice';
import { Response } from '../../types/Response';
import utils from '../../shared/utils';
import {
  AddEmployeeParams,
  AddEmployeesResponseDataResult,
  CheckAccountResponseDataResult,
  Country,
  Employee,
  ImportEmployeeParams,
  ImportEmployeesResponseDataResult,
  ResetEmployeePasswordParams,
  UpdateEmployeeAnnualLeaveParams,
  UpdateEmployeeParams,
  UpdateEmployeeRoleParams,
} from '../../types/Employees';

function* createEmployee({
  payload,
}: PayloadAction<{ branchId: number; data: AddEmployeeParams }>) {
  try {
    yield put(employeeActions.createEmployeeStarted());
    const response: Response<AddEmployeesResponseDataResult> = yield call(() =>
      api.employee.postEmployee(payload.branchId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.createEmployeeSuccess({ empData: payload.data, response: response.data }),
      );
    } else {
      yield put(employeeActions.createEmployeeError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.createEmployeeError(utils.general.convertAxiosError(error)));
  }
}

function* updateEmployee({
  payload,
}: PayloadAction<{ accountId: number; data: UpdateEmployeeParams }>) {
  try {
    yield put(employeeActions.updateEmployeeStarted(payload.data));
    const response: Response<boolean> = yield call(() =>
      api.employee.putEmployee(payload.accountId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.updateEmployeeSuccess({
          response: response.data,
          account_id: payload.accountId,
          empData: payload.data,
        }),
      );
    } else {
      yield put(
        employeeActions.updateEmployeeError({ error: response.data, id: payload.accountId }),
      );
    }
  } catch (error) {
    yield put(
      employeeActions.updateEmployeeError({
        error: utils.general.convertAxiosError(error),
        id: payload.accountId,
      }),
    );
  }
}

function* deleteEmployee({ payload }: PayloadAction<{ branchId: number; accountId: number }>) {
  try {
    yield put(employeeActions.deleteEmployeeStarted({ account_id: payload.accountId }));
    const response: Response<boolean> = yield call(() =>
      api.employee.deleteEmployee(payload.branchId, payload.accountId),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.deleteEmployeeSuccess({
          account_id: payload.accountId,
          response: response.data,
        }),
      );
    } else {
      yield put(employeeActions.deleteEmployeeError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.deleteEmployeeError(utils.general.convertAxiosError(error)));
  }
}

function* updateEmployeeRole({
  payload,
}: PayloadAction<{
  branchId: number;
  accountId: number;
  data: UpdateEmployeeRoleParams;
}>) {
  try {
    yield put(
      employeeActions.updateEmployeeRoleStarted({ id: payload.accountId, data: payload.data }),
    );
    const response: Response<boolean> = yield call(() =>
      api.employee.putEmployeeRole(
        payload.branchId,
        payload.accountId,
        _omit(payload.data, 'role_name'),
      ),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.updateEmployeeRoleSuccess({
          response: response.data,
          account_id: payload.accountId,
          empData: payload.data,
        }),
      );
    } else {
      yield put(
        employeeActions.updateEmployeeRoleError({ error: response.data, id: payload.accountId }),
      );
    }
  } catch (error) {
    yield put(
      employeeActions.updateEmployeeRoleError({
        error: utils.general.convertAxiosError(error),
        id: payload.accountId,
      }),
    );
  }
}

function* fetchEmployees({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(employeeActions.fetchEmployeesStarted());
    const response: Response<Employee[]> = yield call(() =>
      api.employee.getEmployees(payload.branchId, payload.date),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(employeeActions.fetchEmployeesSuccess(response.data));
    } else {
      yield put(employeeActions.fetchEmployeesError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.fetchEmployeesError(utils.general.convertAxiosError(error)));
  }
}

function* importEmployee({
  payload,
}: PayloadAction<{
  branchId: number;
  accountId: number;
  data: ImportEmployeeParams;
}>) {
  try {
    yield put(employeeActions.importEmployeeStarted());
    const response: Response<ImportEmployeesResponseDataResult> = yield call(() =>
      api.employee.putEmployeeRole(payload.branchId, payload.accountId, {
        hourly_wage: payload.data.hourly_wage,
        currency_id: payload.data.currency_id,
        role_id: payload.data.role_id,
        payment_type: payload.data.payment_type,
        employee_code: payload.data.employee_code,
      }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.importEmployeeSuccess({
          response: response.data,
          account_id: payload.accountId,
          empData: payload.data,
        }),
      );
    } else {
      yield put(employeeActions.importEmployeeError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.importEmployeeError(utils.general.convertAxiosError(error)));
  }
}

function* getCountries() {
  try {
    yield put(employeeActions.fetchCountriesStarted());
    const response: Response<Country[]> = yield call(() => api.general.getCountries());
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(employeeActions.fetchCountriesSuccess(response.data));
    } else {
      yield put(employeeActions.fetchCountriesError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.fetchCountriesError(utils.general.convertAxiosError(error)));
  }
}

function* checkAccount({
  payload,
}: PayloadAction<{ branchId: number; email: string; action: unknown }>) {
  try {
    yield put(employeeActions.checkEmployeeAccountStarted({ email: payload.email }));
    const response: Response<CheckAccountResponseDataResult> = yield call(() =>
      api.employee.getEmployee(payload.branchId, { email: payload.email }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.checkEmployeeAccountSuccess({
          response: response.data,
          email: payload.email,
        }),
      );
    } else {
      yield put(employeeActions.checkEmployeeAccountError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.checkEmployeeAccountError(utils.general.convertAxiosError(error)));
  }
}

function* updateEmployeeAnnualLeave({ payload }: PayloadAction<UpdateEmployeeAnnualLeaveParams>) {
  try {
    yield put(employeeActions.updateAnnualLeaveStarted(payload));
    const response: Response<boolean> = yield call(() =>
      api.employee.putEmployeeAnnualLeaveCredit(payload.companyId, payload.id, {
        annual_leave_credit: payload.annual_leave_credit,
      }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        employeeActions.updateAnnualLeaveSuccess({
          response: response.data,
          data: payload,
        }),
      );
    } else {
      yield put(employeeActions.updateAnnualLeaveError({ id: payload.id, error: response.data }));
    }
  } catch (error) {
    yield put(
      employeeActions.updateAnnualLeaveError({
        id: payload.id,
        error: utils.general.convertAxiosError(error),
      }),
    );
  }
}

function* resetEmployeePassword({ payload }: PayloadAction<ResetEmployeePasswordParams>) {
  try {
    yield put(employeeActions.resetEmployeePasswordStarted(payload));
    const response: Response<boolean> = yield call(() =>
      api.employee.resetEmployeePassword(payload.account_id, payload.password),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(employeeActions.resetEmployeePasswordSuccess(response.data));
    } else {
      yield put(employeeActions.resetEmployeePasswordError(response.data));
    }
  } catch (error) {
    yield put(employeeActions.resetEmployeePasswordError(utils.general.convertAxiosError(error)));
  }
}

function* watchManageEmployees() {
  yield all([
    takeLatest(actionTypes.ADD_EMPLOYEE, createEmployee),
    takeLatest(actionTypes.GET_EMPLOYEES, fetchEmployees),
    takeLatest(actionTypes.DELETE_EMPLOYEE, deleteEmployee),
    takeLatest(actionTypes.UPDATE_EMPLOYEE, updateEmployee),
    takeLatest(actionTypes.UPDATE_EMPLOYEE_ROLE, updateEmployeeRole),
    takeLatest(actionTypes.IMPORT_EMPLOYEE, importEmployee),
    takeLatest(actionTypes.CHECK_EMPLOYEE_ACCOUNT, checkAccount),
    takeLatest(actionTypes.GET_COUNTRIES, getCountries),
    takeLatest(actionTypes.UPDATE_EMPLOYEE_ANNUAL_LEAVE, updateEmployeeAnnualLeave),
    takeLatest(actionTypes.RESET_EMPLOYEE_PASSWORD, resetEmployeePassword),
  ]);
}

export default watchManageEmployees;
