import { Components, Theme } from '@mui/material';
import untitledTypography from '../themeTypography';
import colors from '../themeColorPalette';
import themeShadows from '../themeShadows';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        textTransform: 'capitalize',
        borderRadius: '8px',
        ...untitledTypography.text.sm.semibold,

        svg: {
          width: '20px',
          height: '20px',
          color: colors.grey[500],
        },

        ...(ownerState.size === 'small' && {
          padding: '8px',
          height: '36px',
        }),

        ...(ownerState.size === 'medium' && {
          padding: '10px',
          height: '40px',
        }),

        ...(ownerState.size === 'large' && {
          padding: '12px',
          height: '44px',
          '.MuiSvgIcon-root': {
            width: '24px',
            height: '24px',
          },
        }),

        ...(ownerState.className?.includes('contained') && {
          border: `1px solid ${colors.primary[600]}`,
          backgroundColor: colors.primary[600],
          boxShadow: themeShadows.xs,

          '.MuiSvgIcon-root': {
            color: '#FFF',
          },

          ':hover': {
            borderColor: colors.primary[700],
            backgroundColor: colors.primary[700],
          },

          ':focus': {
            borderColor: colors.primary[600],
            backgroundColor: colors.primary[600],
          },

          ':disabled': {
            borderColor: colors.primary[300],
            backgroundColor: colors.primary[300],
            color: '#FFF',
          },
        }),

        ...(ownerState.className?.includes('outlined') && {
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: themeShadows.xs,

          ':hover': {
            backgroundColor: colors.grey[50],
            borderColor: colors.grey[300],
          },

          ':focus': {
            borderColor: colors.grey[300],
          },

          ':disabled': {
            borderColor: colors.grey[200],
            backgroundColor: '#FFF',
            '.MuiSvgIcon-root': {
              color: colors.grey[300],
            },
          },
        }),

        ...(ownerState.className?.includes('text') && {
          ':hover': {
            backgroundColor: colors.grey[50],
          },

          ':disabled': {
            color: colors.grey[300],
          },
        }),
        ...(ownerState.className?.includes('rounded') && {
          borderRadius: '50%',
        }),
      }),
    },
  },
};

export default components.MuiIconButton;
