import api from './axios';
import { Response } from '../types/Response';
import {
  DailyRequestsResponse,
  FetchExchangeEmployeesResponse,
  MonthlyRequestsResponse,
  RequestsCount,
} from '../types/Requests';

const getRequestsDay = (branchId: number, date: string) =>
  api.get(`/branch/${branchId}/requests/day${date ? `?date=${date}` : ''}`) as Promise<
    Response<DailyRequestsResponse>
  >;

const getRequestsMonth = (branchId: number, date: string) =>
  api.get(`/branch/${branchId}/requests/month${date ? `?start_date=${date}` : ''}`) as Promise<
    Response<MonthlyRequestsResponse>
  >;

const getRequestsCount = (branchId: number) =>
  api.get(`/branch/${branchId}/requests/total_pending`) as Promise<Response<RequestsCount>>;

const putOffApprove = (branchId: number, requestId: number, data: { manager_id: number }) =>
  api.put(`/branch/${branchId}/off/${requestId}/approve`, data) as Promise<Response<boolean>>;

const putOffReject = (
  branchId: number,
  requestId: number,
  data: { manager_id: number; note?: string | null },
) => api.put(`/branch/${branchId}/off/${requestId}/reject`, data) as Promise<Response<boolean>>;

const putExchangeApprove = (branchId: number, requestId: number, data: { manager_id: number }) =>
  api.put(`/branch/${branchId}/exchange/${requestId}/approve`, data) as Promise<Response<boolean>>;
const putExchangeReject = (
  branchId: number,
  requestId: number,
  data: { manager_id: number; note: string | null },
) =>
  api.put(`/branch/${branchId}/exchange/${requestId}/reject`, data) as Promise<Response<boolean>>;

const getExchangeEmployee = (branchId: number, requestId: number) =>
  api.get(`/branch/${branchId}/exchange/${requestId}/accepted_responses`) as Promise<
    Response<FetchExchangeEmployeesResponse>
  >;

const putExchangeEmployee = (
  branchId: number,
  requestId: number,
  data: {
    responder_id: number;
    manager_id: number;
  },
) =>
  api.put(`/branch/${branchId}/exchange/${requestId}/accept_response_approve`, data) as Promise<
    Response<boolean>
  >;

export default {
  getExchangeEmployee,
  getRequestsCount,
  getRequestsDay,
  getRequestsMonth,
  putExchangeApprove,
  putExchangeEmployee,
  putExchangeReject,
  putOffApprove,
  putOffReject,
};
