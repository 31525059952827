import createSagaMiddleware from 'redux-saga';
import watchManageSchedule from './manageScheduleSagas';
import watchManageShift from './shiftsSaga';
import watchManagePositions from './positionsSaga';
import watchManageNotifications from './notificationsSaga';
import watchManageDailySchedule from './dailyScheduleSaga';
import watchSettings from './settingsSaga';
import watchEmployees from './employeeSaga';
import watchManageRequests from './requestsSaga';
import watchManagerequestsDay from './requestsDaySaga';
import watchWeeklySchedule from './weeklySchedule';
import watchOpenShifts from './openShifts';
import watchManageAttendaceReport from './reporting';
import watchManagePunch from './punchSaga';
import manageOffRequestsInSchedule from './manageOffRequestsInScheduleSaga';

const sagaMiddleWare = createSagaMiddleware();

export const runSagas = () => {
  sagaMiddleWare.run(watchManageSchedule);
  sagaMiddleWare.run(watchManageShift);
  sagaMiddleWare.run(watchManagePositions);
  sagaMiddleWare.run(watchManageNotifications);
  sagaMiddleWare.run(watchManageDailySchedule);
  sagaMiddleWare.run(watchSettings);
  sagaMiddleWare.run(watchEmployees);
  sagaMiddleWare.run(watchManageRequests);
  sagaMiddleWare.run(watchManagerequestsDay);
  sagaMiddleWare.run(watchWeeklySchedule);
  sagaMiddleWare.run(watchOpenShifts);
  sagaMiddleWare.run(watchManageAttendaceReport);
  sagaMiddleWare.run(watchManagePunch);
  sagaMiddleWare.run(manageOffRequestsInSchedule);
};

export default sagaMiddleWare;
