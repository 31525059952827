import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import dateUtils from './date';
import generalUtils from './general';
import shiftUtils from './shift';
import positionUtils from './position';
import { AccountRole } from '../../types/Positions';
import { Shift, WeekShifts } from '../../types/Shift';
import {
  RoleShiftsRequirement,
  RolesPerDay,
  WageFactor,
  WeeklyScheduleData,
  WeeklyScheduleResponse,
  WeeklyScheduleRole,
  EmployeeWeekDaysPayroll,
  WeeklyScheduleInitialState,
  PreviouseWeeklyScheduleData,
  WeeklyScheduleEmployee,
  WeeklyScheduleAccountResponseData,
  ScheduleResponseData,
  DaySchedule,
  CompanyEmployeesResponse,
  CompanyEmployees,
  TemporaryEmployeeRole,
  UpdateSelectedSchedulesData,
  WeeklyScheduleFilters,
  RoleShiftRequirement,
  UpdateOffRequest,
} from '../../types/WeeklySchedule';
import { PaymentType, Weekday } from '../../enums';
import storage from '../../storage';
import { AddScheduleRequestData, Schedule, UpdateScheduleRequestData } from '../../types/Schedule';
import { SaleValue, UpdateWeeklySalesInputTypeData } from '../../types/Sales';

const getLatestRole = (roles: AccountRole[]) => {
  let lastRole = roles[0];
  let lastRoleDate = lastRole.start_date;
  roles.forEach((role) => {
    if (role.start_date > lastRoleDate) {
      lastRoleDate = role.start_date;
      lastRole = role;
    }
  });
  return { ...lastRole };
};

const filterAccountRoles: (account: WeeklyScheduleAccountResponseData) => {
  roles: AccountRole[];
  rolesPerDay: RolesPerDay;
} = (account) => {
  const addedRolesSet = new Set();
  const rolesPerDay = generalUtils.cloneData(account.roles_per_day);
  let accountRoles = generalUtils.cloneData(account.roles);

  Object.keys(rolesPerDay).forEach((dateKey) => {
    let { roles } = rolesPerDay[dateKey];
    if (roles?.length > 1) {
      const lastRole = getLatestRole(roles);
      addedRolesSet.add(lastRole.id);
      roles = [lastRole];
    } else if (roles?.length === 1) {
      addedRolesSet.add(roles[0]?.id);
    }
    rolesPerDay[dateKey].roles = roles;
  });

  if (addedRolesSet.size) {
    accountRoles = accountRoles.filter((role) => addedRolesSet.has(role.id));
  }

  return {
    roles: accountRoles,
    rolesPerDay,
  };
};

const convertAccountData: (
  accounts: WeeklyScheduleAccountResponseData[],
) => WeeklyScheduleEmployee[] = (accounts: WeeklyScheduleAccountResponseData[]) => {
  return accounts.map((account) => {
    const clonedAccount = generalUtils.cloneData(account);
    // set the latest role in each day when employee has multiple roles in one day
    const { roles, rolesPerDay } = filterAccountRoles(clonedAccount);
    clonedAccount.roles = roles;
    clonedAccount.roles_per_day = rolesPerDay;
    const schedules: DaySchedule[] = clonedAccount.schedules.map((sched) => {
      const newScheduleData = generalUtils.cloneData(sched);
      // set the schedule role to the latest active role in each day
      if (newScheduleData.roles.length > 1)
        newScheduleData.roles = [getLatestRole(newScheduleData.roles)];
      return {
        ...newScheduleData,
        weekday: dateUtils.getWeekDay(sched.date),
        schedule:
          newScheduleData.schedule?.map((shift) => ({
            ...shift,
            end_time: shift.end_time,
            start_time: shift.start_time,
            isCopyFrom: false,
            isConflict: false,
            show: true,
          })) || [],
      };
    });

    return {
      ...clonedAccount,
      total_working_days: 0,
      total_working_hours: 0,
      total_working_shifts: 0,
      payroll: {},
      total_fixed_wage: 0,
      weekly_wage: 0,
      schedules,
    };
  });
};

const getScheduleWagesByPaymentType = (
  schedule: ScheduleResponseData[],
  rolePaymentType: PaymentType,
  roleWage: string,
  branchId: number,
) => {
  const result = {
    [PaymentType.HOURLY]: { hours: 0, wage: 0 },
    [PaymentType.PER_SHIFT]: { shiftsCount: 0, wage: 0 },
    [PaymentType.PER_DAY]: { wage: 0, numOfDays: 0 },
  };

  schedule.forEach((shift) => {
    if (shift?.start_time !== shift?.end_time && shift?.branch_id === branchId && shift.show) {
      // only working shifts
      const isCustomPayment = !!shift?.custom_payment?.payment_type_id;
      const paymentType = +(shift?.custom_payment?.payment_type_id || rolePaymentType);
      const wage = isCustomPayment ? +(shift?.custom_payment?.wage || 0) : +roleWage;
      if (paymentType === PaymentType.HOURLY) {
        // Hourly
        const workingHours = shiftUtils.getShiftWorkingHours(shift);
        result[PaymentType.HOURLY].hours += workingHours;
        result[PaymentType.HOURLY].wage += workingHours * wage;
      } else if (paymentType === PaymentType.PER_SHIFT) {
        // Per shift
        result[PaymentType.PER_SHIFT].shiftsCount += 1;
        result[PaymentType.PER_SHIFT].wage += wage;
      }
    }
  });
  return result;
};

const getAccountWages = (
  roleId: number,
  schedules: DaySchedule[],
  rolesPerDay: RolesPerDay,
  wageFactors: WageFactor[],
) => {
  let totalWage = 0;
  let totalFixedWage = 0;
  let nbOfShifts = 0;
  let nbOfDays = 0;
  let nbOfHours = 0;
  const branchId = storage.getSelectedBranch();
  const payroll: EmployeeWeekDaysPayroll = dateUtils
    .getWeekDays(1)
    .reduce((accr, day) => ({ ...accr, [day.weekday]: { other_wages: 0, fixed_wages: 0 } }), {});

  schedules
    .filter((schedule) => schedule.roles.filter((_role) => _role.id === roleId).length === 1)
    .forEach((schedule) => {
      // For each day
      const role = rolesPerDay[schedule.date].roles[0];
      const wageFactor = wageFactors.find((factor) => factor.date === schedule.date);
      const totalMultiplicationFactor = wageFactor?.total_multiplication_factor || 1;
      const { weekday } = schedule;
      const scheduleWages = getScheduleWagesByPaymentType(
        schedule?.schedule || [],
        role.payment_type,
        role.hourly_wage,
        branchId,
      );

      const shiftsPerDay = (schedule?.schedule || []).find(
        (shift) => +(shift?.custom_payment?.payment_type_id || 0) === PaymentType.PER_DAY,
      );
      if (shiftsPerDay) {
        scheduleWages[PaymentType.PER_DAY] = {
          numOfDays: 1,
          wage: +(shiftsPerDay.custom_payment?.wage || 0),
        };
      }

      if (totalMultiplicationFactor > 1) {
        scheduleWages[PaymentType.HOURLY].wage *= totalMultiplicationFactor;
        scheduleWages[PaymentType.PER_SHIFT].wage *= totalMultiplicationFactor;
        scheduleWages[PaymentType.PER_DAY].wage *= totalMultiplicationFactor;
      }

      const otherWages =
        scheduleWages[PaymentType.HOURLY].wage +
        scheduleWages[PaymentType.PER_SHIFT].wage +
        scheduleWages[PaymentType.PER_DAY].wage;
      totalWage += otherWages;
      payroll[weekday.toString()].other_wages += otherWages;
      nbOfShifts += scheduleWages[PaymentType.PER_SHIFT].shiftsCount;
      nbOfHours += scheduleWages[PaymentType.HOURLY].hours;

      if (role.payment_type === PaymentType.MONTHLY) {
        // MONTHLY
        const dayWage = +(role.hourly_wage || 0) / 4 / 7;
        totalWage += dayWage;
        nbOfDays += 1;
        totalFixedWage += dayWage;
        payroll[weekday.toString()].fixed_wages = dayWage;
      } else if (
        role.payment_type === PaymentType.PER_DAY &&
        (schedule?.schedule || []).some((shift) => !shift.custom_payment?.payment_type_id)
        // employee payment type is per-day and has at least one shift with no custom payment
        // we get the wage from employee role
      ) {
        // PER DAY
        totalWage += +role.hourly_wage;
        nbOfDays += 1;
        totalFixedWage += +role.hourly_wage;
        payroll[weekday.toString()].fixed_wages = +role.hourly_wage; // per-day payment here is fixed wages
      }
    });

  const countDisplay = [];
  if (nbOfHours !== 0) countDisplay.push(`${generalUtils.toFixedDecimals(nbOfHours, 2)}hr`);
  if (nbOfDays !== 0) countDisplay.push(`${nbOfDays}day(s)`);
  if (nbOfShifts !== 0) countDisplay.push(`${nbOfShifts}shift(s)`);

  const weeklyWage = generalUtils.toFixedDecimals(totalWage, 2);

  return {
    total_working_hours: nbOfHours,
    total_working_days: nbOfDays,
    total_working_shifts: nbOfShifts,
    weekly_wage: weeklyWage,
    total_fixed_wage: totalFixedWage,
    payroll,
  };
};

export type AccountRoles = {
  [key: string]: {
    name: string;
    id: number;
    index: number;
    shiftRequirements: RoleShiftsRequirement[];
    account: WeeklyScheduleEmployee;
  };
};

type RoleObj = {
  [key: string]: WeeklyScheduleRole;
};

const groupByRoles: (
  accounts: WeeklyScheduleEmployee[],
  wageFactor: WageFactor[],
) => WeeklyScheduleData = (accounts, wageFactor) => {
  const result: RoleObj = accounts.reduce((rootAccr, account) => {
    const accountRoles: AccountRoles = account.roles.reduce((acc, role) => {
      // return previous value of accr when role exists, this prevent repetitive computation
      // since on each loop we will compute the schedules of the role using all start and end dates of the same role
      if (acc[role.name]) return acc;

      const employeeWages = getAccountWages(
        role.id,
        account.schedules,
        account.roles_per_day,
        wageFactor,
      );

      // get all start and end dates of the role to filter schedules
      const accountRoleRanges = account.roles
        .filter((r) => r.id === role.id)
        .map((r) => ({
          start_date: dateUtils.formatDate(r.start_date),
          end_date: dateUtils.formatDate(r.end_date),
          is_temporary_position: !!r.is_temporary_position,
        }));

      const newAccountData: WeeklyScheduleEmployee = {
        ...account,
        total_working_days: employeeWages.total_working_days,
        total_working_hours: employeeWages.total_working_hours,
        total_working_shifts: employeeWages.total_working_shifts,
        weekly_wage: employeeWages.weekly_wage,
        payroll: employeeWages.payroll,
        total_fixed_wage: employeeWages.total_fixed_wage,
        // move schedules to the current active position
        schedules: account.schedules.map((schedules) => {
          const addSchedulesToThisDay = accountRoleRanges.some(
            (range) =>
              schedules.date >= range.start_date &&
              ((schedules.date < range.end_date && !range.is_temporary_position) ||
                (schedules.date <= range.end_date && range.is_temporary_position)),
          );
          return {
            ...schedules,
            schedule: addSchedulesToThisDay ? schedules.schedule : [],
            off_request: addSchedulesToThisDay ? schedules.off_request : [],
          };
        }),
      };

      const accountRole = {
        account: newAccountData,
        id: role.id,
        index: +role.role_index,
        name: role.name,
        shiftRequirements: [],
      };

      return { ...acc, [role.name]: accountRole };
    }, {} as AccountRoles);

    const rootAccrModified: RoleObj = { ...rootAccr };
    Object.keys(accountRoles).forEach((roleName) => {
      const accountRole = accountRoles[roleName];
      if (!rootAccrModified[roleName]) {
        rootAccrModified[roleName] = {
          data: [accountRole.account],
          id: accountRole.id,
          index: +accountRole.index,
          name: accountRole.name,
          shiftRequirements: [],
        };
      } else if (!rootAccrModified[roleName].data.find((acct) => acct.id === account.id)) {
        rootAccrModified[roleName].data.push(accountRole.account);
      }
    });

    return rootAccrModified;
  }, {} as RoleObj);

  return Object.values(result);
};

const groupShiftRequirementsByRoles = (weekShifts: WeekShifts[]) => {
  const result: { [roleId: string]: RoleShiftsRequirement[] } = {};
  const shiftsPerRole: { [roleId: string]: { shift: Shift; weekday: Weekday; reqNum: number }[] } =
    {};

  weekShifts.forEach((day) => {
    day.shifts
      .filter((shift) => shift.start_hour !== shift.end_hour)
      .forEach((shift) => {
        shift.role_requirements.forEach((roleReq) => {
          if (!shiftsPerRole[roleReq.role_id]) shiftsPerRole[roleReq.role_id] = [];
          shiftsPerRole[roleReq.role_id].push({
            shift: { ...shift },
            reqNum: roleReq.num,
            weekday: day.weekday,
          });
        });
      });
  });

  Object.keys(shiftsPerRole).forEach((roleId) => {
    const reqArray = shiftsPerRole[roleId];
    reqArray.forEach((day) => {
      const { reqNum, shift, weekday } = day;
      const newItem = {
        end_hour: shift.end_hour,
        start_hour: shift.start_hour,
        num: 0,
        reqNum,
        weekday,
      };
      if (!result[roleId]) result[roleId] = [{ name: shift.name, required: [newItem] }];
      else {
        const addedShift = result[roleId].find((shiftData) => shiftData.name === shift.name);
        if (addedShift) {
          addedShift.required.push(newItem);
          addedShift.required = _orderBy(addedShift.required, 'weekday');
        } else {
          result[roleId].push({
            name: shift.name,
            required: [newItem],
          });
        }
      }
    });
  });

  return result;
};

const addShiftCountData = (role: WeeklyScheduleRole) => {
  const branchId = storage.getSelectedBranch();

  role.shiftRequirements.forEach((req) => {
    req.required.forEach((shift) => {
      // eslint-disable-next-line no-param-reassign
      shift.num = 0;
    });
  });

  role.data.forEach((account) => {
    account.schedules.forEach((schedule) => {
      schedule?.schedule
        ?.filter((sched) => sched.branch_id === branchId && sched.show)
        .forEach((sched) => {
          const shiftReq = role.shiftRequirements.find(
            (shiftR) => shiftR.name === sched.shift.name,
          );
          const reqData = shiftReq
            ? shiftReq.required.find((req) => req.weekday === schedule.weekday)
            : null;

          if (reqData?.num !== undefined) {
            reqData.num += 1;
          }
        });
    });
  });
  return role;
};

export const getUnPublishedShiftsCount = (schedulesData: WeeklyScheduleData) => {
  const branchId = storage.getSelectedBranch();
  let count = 0;
  schedulesData.forEach(({ data }) => {
    data.forEach(({ schedules }) => {
      schedules.forEach((sched) => {
        sched.schedule.forEach((_sche) => {
          if (_sche.is_published === 0 && _sche.branch_id === branchId) {
            count += 1;
          }
        });
      });
    });
  });
  return count;
};

export const convertResponseData: (
  data: WeeklyScheduleResponse,
  shifts: WeekShifts[],
) => {
  schedule: WeeklyScheduleData;
  shifts: WeekShifts[];
  wage_factors: WageFactor[];
} = (data, shifts) => {
  const newAccountsData = convertAccountData(data.accounts);
  const groupedShiftRequirements = groupShiftRequirementsByRoles(shifts);
  const rolesData = groupByRoles(newAccountsData, data.wage_factors).map((role) => ({
    ...addShiftCountData({
      ...role,
      shiftRequirements: groupedShiftRequirements[role.id] || [],
    }),
  }));

  const sortedRoles = positionUtils.sortPositions(rolesData);

  sortedRoles.forEach((role) => {
    // eslint-disable-next-line no-param-reassign
    role.data = positionUtils.sortEmployees(role.data, ['first_name', 'last_name']);
  });

  return {
    schedule: sortedRoles,
    shifts,
    wage_factors: data.wage_factors,
  };
};

const scheduleHasConflicts = (schedule: ScheduleConflict, daySchedules: DaySchedulesConflicts) => {
  let isConflict = false;
  const { date } = daySchedules;
  const scheduleDate = schedule.date;
  const { startTime, endTime } = shiftUtils.getShiftIntervalDateTime(
    scheduleDate,
    schedule.start_time,
    schedule.end_time,
  );

  daySchedules.schedule.every((sched) => {
    if (sched.id === schedule.id) return true;
    const daySchedule = shiftUtils.getShiftIntervalDateTime(date, sched.start_time, sched.end_time);
    const overlap = dateUtils.getDateTimeRangeOverlap(
      { start: daySchedule.startTime, end: daySchedule.endTime },
      { start: startTime, end: endTime },
    );
    isConflict = overlap > 0;
    return !isConflict;
  });
  return isConflict;
};

// return the conflicting schedules ids of a in b
const checkForSchedulesConflicts = (a: DaySchedulesConflicts[], b: DaySchedulesConflicts[]) => {
  const conflicts: number[] = [];
  b.forEach((bSchedule) => {
    const { weekday, date } = bSchedule;
    bSchedule.schedule.forEach((schedule) => {
      const scheduleData = {
        date: bSchedule.date,
        end_time: schedule.end_time,
        start_time: schedule.start_time,
        id: schedule.id,
      };
      let hasConflict = false;
      const currentDaySchedules = a.find((schedules) => schedules.weekday === weekday);
      // remove schedules that already added to conflicts to avoid having two shifts as conflicts with each other
      if (currentDaySchedules?.schedule)
        currentDaySchedules.schedule = currentDaySchedules?.schedule.filter(
          (sched) => !conflicts.includes(sched.id),
        );

      const nextDay = weekday + 1 <= 6 ? weekday + 1 : 0;
      const previousDay = weekday - 1 >= 0 ? weekday - 1 : 6;

      const nextDaySchedule = a.find(
        (schedules) => schedules.weekday === nextDay && schedules.date > date,
      );
      // remove schedules that already added to conflicts to avoid having two shifts as conflicts with each other
      if (nextDaySchedule?.schedule)
        nextDaySchedule.schedule = nextDaySchedule?.schedule.filter(
          (sched) => !conflicts.includes(sched.id),
        );

      const prevDaySchedule = a.find(
        (schedules) => schedules.weekday === previousDay && schedules.date < date,
      );
      // remove schedules that already added to conflicts to avoid having two shifts as conflicts with each other
      if (prevDaySchedule?.schedule)
        prevDaySchedule.schedule = prevDaySchedule?.schedule.filter(
          (sched) => !conflicts.includes(sched.id),
        );

      if (currentDaySchedules?.schedule.length) {
        hasConflict = scheduleHasConflicts(scheduleData, currentDaySchedules);
      }

      if (!hasConflict && nextDaySchedule?.schedule.length) {
        hasConflict = scheduleHasConflicts(scheduleData, nextDaySchedule);
      }

      if (!hasConflict && prevDaySchedule?.schedule.length) {
        hasConflict = scheduleHasConflicts(scheduleData, prevDaySchedule);
      }

      if (hasConflict) {
        conflicts.push(schedule.id);
      }
    });
  });
  return conflicts;
};

/**
 * Set isConflict to true in b schedules when conflict occurs with a schedules
 * @param {DaySchedule[]} a
 * @param {DaySchedule[]} b
 * @returns {number} number of conflicts
 */

const setConflictingSchedules = (a?: DaySchedule[], b?: DaySchedule[]) => {
  const aSchedules: DaySchedulesConflicts[] = (a || []).map((schedule) => ({
    ...schedule,
    schedule: schedule.schedule.map((sched) => ({ ...sched, date: schedule.date })),
  }));

  const bSchedules = (b || []).map((schedule) => ({
    ...schedule,
    schedule: schedule.schedule.map((sched) => ({
      ...sched,
      date: schedule.date,
    })),
  }));

  b?.forEach((day) => {
    day.schedule.forEach((sched) => {
      // eslint-disable-next-line no-param-reassign
      sched.isConflict = false;
    });
  });

  const conflicts = checkForSchedulesConflicts(aSchedules, bSchedules);
  conflicts.forEach((id) => {
    const conflictingSchedule = b?.reduce(
      (prev, day) => day.schedule.find((sched) => sched.id === id) || prev,
      undefined as unknown as Schedule,
    );
    if (conflictingSchedule) {
      conflictingSchedule.isConflict = true;
    }
  });

  // check for conflicts inside the bSchedules - filtered out the schedules that has been set as conflicts
  checkForSchedulesConflicts(bSchedules, bSchedules).forEach((id) => {
    const conflictingSchedule = b?.reduce(
      (prev, day) => day.schedule.find((sched) => sched.id === id) || prev,
      undefined as unknown as Schedule,
    );
    if (conflictingSchedule) {
      conflictingSchedule.isConflict = true;
    }
  });

  return conflicts;
};

const triggerUpdateAccountData = (
  state: WeeklyScheduleInitialState,
  roleId: number,
  accountId: number,
) => {
  const copyFromAccount = state.copyFrom.data
    .find((role) => role.id === +roleId)
    ?.data.find((account) => account.id === +accountId);
  const copyFromSchedules = copyFromAccount?.schedules || [];

  const weeklyAccount = state.weeklySchedule.data.schedule
    .find((role) => role.id === +roleId)
    ?.data.find((account) => account.id === +accountId);
  const weeklySchedules = weeklyAccount?.schedules || [];

  if (copyFromSchedules.length && weeklySchedules.length)
    setConflictingSchedules(weeklySchedules, copyFromSchedules);

  if (weeklyAccount) {
    const {
      total_working_days: totalWorkingDays,
      total_working_hours: totalWorkingHours,
      total_working_shifts: totalWorkingShifts,
      payroll,
      total_fixed_wage: totalFixedWages,
      weekly_wage: weeklyWage,
    } = getAccountWages(
      +roleId,
      weeklySchedules,
      weeklyAccount.roles_per_day,
      state.weeklySchedule.data.wage_factors,
    );

    weeklyAccount.total_working_days = totalWorkingDays;
    weeklyAccount.total_working_hours = totalWorkingHours;
    weeklyAccount.total_working_shifts = totalWorkingShifts;
    weeklyAccount.payroll = payroll;
    weeklyAccount.total_fixed_wage = totalFixedWages;
    weeklyAccount.weekly_wage = weeklyWage;
  }

  if (copyFromAccount) {
    const {
      total_working_days: totalWorkingDays,
      total_working_hours: totalWorkingHours,
      total_working_shifts: totalWorkingShifts,
      payroll,
      total_fixed_wage: totalFixedWages,
      weekly_wage: weeklyWage,
    } = getAccountWages(
      +roleId,
      copyFromSchedules,
      copyFromAccount.roles_per_day,
      state.weeklySchedule.data.wage_factors,
    );
    copyFromAccount.total_working_days = totalWorkingDays;
    copyFromAccount.total_working_hours = totalWorkingHours;
    copyFromAccount.total_working_shifts = totalWorkingShifts;
    copyFromAccount.payroll = payroll;
    copyFromAccount.total_fixed_wage = totalFixedWages;
    copyFromAccount.weekly_wage = weeklyWage;
  }
};

const clearSelectedCopyFromData = (state: WeeklyScheduleInitialState) => {
  // eslint-disable-next-line no-param-reassign
  state.selectedSchedules = state.selectedSchedules.filter((schedule) => !schedule.isCopyFrom);
};

const clearSelectedWeeklySchedules = (state: WeeklyScheduleInitialState) => {
  // eslint-disable-next-line no-param-reassign
  state.selectedSchedules = state.selectedSchedules.filter((schedule) => schedule.isCopyFrom);
};

const filterSchedules = (
  state: WeeklyScheduleInitialState,
  data: WeeklyScheduleData,
  filters: WeeklyScheduleFilters,
) => {
  data.forEach((role) => {
    role.data.forEach((account) => {
      account.schedules.forEach((day) => {
        day.schedule.forEach((schedule) => {
          // eslint-disable-next-line no-param-reassign
          schedule.isIgnored = false;
          if (
            ('accounts' in filters && !filters.accounts?.includes(account.id)) ||
            ('weekdays' in filters && !filters.weekdays?.includes(day.weekday)) ||
            ('conflicts' in filters && filters.conflicts && schedule.isConflict) ||
            ('roles' in filters && !filters.roles?.includes(role.id))
          ) {
            // eslint-disable-next-line no-param-reassign
            schedule.show = false;
            // eslint-disable-next-line no-param-reassign
            schedule.isIgnored = filters.conflicts;
            // eslint-disable-next-line no-param-reassign
          } else schedule.show = true;
        });
      });
      triggerUpdateAccountData(state, role.id, account.id);
    });
    addShiftCountData(role);
  });
};

export const getCopyFromData = (
  state: WeeklyScheduleInitialState,
  data: WeeklyScheduleResponse,
) => {
  clearSelectedCopyFromData(state);
  const selectedBranchId = storage.getSelectedBranch();
  let result: WeeklyScheduleData = [];
  data.accounts.forEach((copyFromAccount) => {
    const roles: WeeklyScheduleData = state.weeklySchedule.data.schedule
      .filter((role) => role.data.find((emp) => emp.id === copyFromAccount.id))
      .map((role) => ({
        ...role,
        data: role.data
          .filter((emp) => emp.id === copyFromAccount.id)
          .map((weeklyAccount) => ({
            ...weeklyAccount,
            count_display: '',
            payroll: {},
            total_fixed_wage: 0,
            weekly_wage: 0,
            schedules: weeklyAccount.schedules.map((schedules) => ({
              ...schedules,
              // remove schedules in case off days
              schedule: schedules.off_request?.some((request) => request.is_approved === 1)
                ? []
                : // filter the current selected branch & published schedules
                  copyFromAccount.schedules
                    .find((sched) => schedules.weekday === dateUtils.getWeekDay(sched.date))
                    ?.schedule?.filter((schedule) => {
                      const accountRoleInThisDay = weeklyAccount.roles_per_day[
                        schedules.date
                      ].roles.find((r) => r.id === role.id);
                      return (
                        schedule.branch_id === selectedBranchId &&
                        !!schedule.is_published &&
                        !!accountRoleInThisDay
                      );
                    })
                    .map((schedule) => ({
                      ...schedule,
                      id: schedule.id * 10 + new Date().getTime(), // set new Id to copy from schedules
                      isCopyFrom: true,
                      show: true,
                      isConflict: false,
                    })) || [],
            })),
          }))
          .filter((emp) => emp.schedules.some((schedules) => schedules.schedule.length > 0))
          .map((emp) => ({
            ...emp,
            ...getAccountWages(
              role.id,
              emp.schedules,
              emp.roles_per_day,
              state.weeklySchedule.data.wage_factors,
            ),
          })),
      }))
      .filter((role) => role.data.length > 0);
    roles.forEach((role) => {
      const roleInResult = result.find((r) => r.id === role.id);
      if (roleInResult) {
        roleInResult.data.push(...role.data);
      } else {
        result.push(role);
      }
    });
  });

  state.weeklySchedule.data.schedule.forEach((role) => {
    role.data.forEach((account) => {
      const copyFromAccount = result
        .find((r) => r.id === role.id)
        ?.data.find((acc) => acc.id === account.id);

      setConflictingSchedules(account.schedules, copyFromAccount?.schedules);
    });
  });

  result = result.map((role) => ({
    ...addShiftCountData({
      ...role,
      shiftRequirements: role.shiftRequirements.map((req) => ({
        ...req,
        required: req.required.map((shift) => ({
          ...shift,
          num: 0,
          start_hour: shift.start_hour || '',
          end_hour: shift.end_hour || '',
          reqNum: shift.reqNum || 0,
        })),
      })),
    }),
  }));

  filterSchedules(state, result, state.copyFromFilters);

  return result;
};

const publishSchedules: (state: WeeklyScheduleInitialState, branchId: number) => number = (
  state,
  branchId,
) => {
  let numberOfEmployeesNotified = 0;
  state.weeklySchedule.data.schedule.forEach((role) => {
    role.data.forEach((account) => {
      let accountHasUnPublishedShifts = false;
      account.schedules.forEach((weekSchedule) => {
        weekSchedule.schedule.forEach((schedule) => {
          if (schedule.branch_id === branchId && !schedule.is_published) {
            accountHasUnPublishedShifts = true;
            // eslint-disable-next-line no-param-reassign
            schedule.is_published = 1;
          }
        });
      });
      if (accountHasUnPublishedShifts) numberOfEmployeesNotified += 1;
    });
  });
  return numberOfEmployeesNotified;
};

const getPrevScheduleData: (
  accounts: WeeklyScheduleAccountResponseData[],
  branchId: number,
) => PreviouseWeeklyScheduleData[] = (accounts, branchId) => {
  const data: PreviouseWeeklyScheduleData[] = [];
  accounts.forEach((emp) => {
    emp.schedules.forEach((day) => {
      const { schedule } = day;
      if (schedule && schedule.length) {
        schedule.forEach((sched) => {
          if (sched.is_published && sched.branch_id === branchId) {
            const weekday = dateUtils.getWeekDay(day.date);
            const schedClone: PreviouseWeeklyScheduleData = {
              schedule: { ...sched, isConflict: false, isCopyFrom: false, show: true },
              account_id: emp.id,
              weekday,
            };
            data.push(schedClone);
          }
        });
      }
    });
  });
  return data;
};

const isScheduleOccuredInPreviousWeek = (
  state: WeeklyScheduleInitialState,
  shiftData: AddScheduleRequestData,
  branchId: number,
) =>
  !!state.previousWeekSchedule.data.schedule.find(
    (schedule) =>
      schedule.account_id === shiftData.account_id &&
      schedule.weekday === dateUtils.getWeekDay(shiftData.date) &&
      schedule.schedule.branch_id === branchId &&
      schedule.schedule.start_time === shiftData.start_time &&
      schedule.schedule.end_time === shiftData.end_time &&
      schedule.schedule.shift.id === shiftData.shift_id &&
      schedule.schedule.shift.name.toLowerCase() ===
        (shiftData.shift_name || shiftData.custom_shift_name)?.toLowerCase() &&
      schedule.schedule.custom_payment?.payment_type_id === shiftData.payment_type &&
      +(schedule.schedule.custom_payment?.wage || 0) === +(shiftData.wage || 0) &&
      schedule.schedule.is_close === shiftData.is_close,
  );

const addSchedule: (
  state: WeeklyScheduleInitialState,
  branchId: number,
  roleId: number,
  schedule: AddScheduleRequestData,
  scheduleId: number,
) => void = (state, branchId, roleId, schedule, scheduleId) => {
  const role = state.weeklySchedule.data.schedule.find((r) => r.id === roleId);
  const account = role?.data.find((employee) => employee.id === schedule.account_id);
  if (account) {
    const daySchedule = account.schedules.find((sched) => sched.date === schedule.date);
    if (daySchedule) {
      daySchedule.schedule.push({
        id: scheduleId,
        start_time: schedule.start_time,
        end_time: schedule.end_time,
        is_close: schedule.is_close,
        break_time: schedule.break_time,
        note: schedule.note,
        ...(schedule.payment_type && schedule.wage
          ? { custom_payment: { payment_type_id: schedule.payment_type, wage: schedule.wage } }
          : {}),
        shift: {
          id: schedule.shift_id || 0,
          is_custom: schedule.custom_shift_name ? 1 : 0,
          name: schedule.shift_name || schedule.custom_shift_name || '',
        },
        is_published: 0,
        is_exchange: 0,
        exchange: null,
        branch_id: branchId,
        branch_name: '',
        isConflict: false,
        isCopyFrom: false,
        show: true,
      });

      triggerUpdateAccountData(state, roleId, schedule.account_id);
      if (role) addShiftCountData(role);

      if (isScheduleOccuredInPreviousWeek(state, schedule, branchId))
        // eslint-disable-next-line no-param-reassign
        state.numberOfSchedulesAddedFromPreviousSchedule += 1;
    }
  }
};

const deleteSchedule: (
  state: WeeklyScheduleInitialState,
  scheduleId: number,
  accountId: number,
  roleId: number,
  date: string,
  isCopyFrom?: boolean,
) => void = (state, scheduleId, accountId, roleId, date, isCopyFrom) => {
  const role = (!isCopyFrom ? state.weeklySchedule.data.schedule : state.copyFrom.data).find(
    (r) => r.id === roleId,
  );
  const account = role?.data.find((ac) => ac.id === accountId);
  const schedule = account?.schedules.find((sched) => sched.date === date);
  if (schedule?.schedule) {
    schedule.schedule = schedule.schedule.filter((sched) => sched.id !== scheduleId);
    // eslint-disable-next-line no-param-reassign
    state.numberOfSchedulesRemovedUsingForm += 1;
    triggerUpdateAccountData(state, roleId, accountId);
    if (role) addShiftCountData(role);
  }
};

const updateSchedule: (
  state: WeeklyScheduleInitialState,
  schedule: UpdateScheduleRequestData,
  roleId: number,
  isCopyFrom?: boolean,
) => void = (state, schedule, roleId, isCopyFrom) => {
  const role = (!isCopyFrom ? state.weeklySchedule.data.schedule : state.copyFrom.data).find(
    (r) => r.id === roleId,
  );
  const account = role?.data.find((employee) => employee.id === schedule.account_id);
  if (account) {
    const daySchedule = account.schedules.find((sched) => sched.date === schedule.date);
    if (daySchedule) {
      daySchedule.schedule = daySchedule.schedule.map((sched) => {
        if (schedule.id === sched.id) {
          return {
            ...sched,
            start_time: schedule.start_time,
            end_time: schedule.end_time,
            is_close: schedule.is_close,
            note: schedule.note,
            break_time: schedule.break_time,
            ...(schedule.payment_type && schedule.wage
              ? { custom_payment: { payment_type_id: schedule.payment_type, wage: schedule.wage } }
              : {}),
          };
        }
        return sched;
      });
    }
    // show update multi selected schedules demo when updating two schedules with same values
    if (
      schedule.start_time === state.latestUpdatedScheduleValues.start_time &&
      schedule.end_time === state.latestUpdatedScheduleValues.end_time &&
      schedule.is_close === state.latestUpdatedScheduleValues.is_close
    ) {
      // eslint-disable-next-line no-param-reassign
      state.showMultiEditSchedulesDemo = true;
    }
    // eslint-disable-next-line no-param-reassign
    state.latestUpdatedScheduleValues = {
      start_time: schedule.start_time,
      end_time: schedule.end_time,
      is_close: schedule.is_close,
    };
    triggerUpdateAccountData(state, roleId, account.id);
    if (role) addShiftCountData(role);
  }
};

const updateWeeklySales = (
  state: WeeklyScheduleInitialState,
  newData: UpdateWeeklySalesInputTypeData,
) => {
  // eslint-disable-next-line no-param-reassign
  state.sales.data.current_week = state.sales.data.current_week.map((day) => {
    if (day.date === newData.date) {
      // eslint-disable-next-line no-param-reassign
      day.sales_values = newData.sales.reduce((prev, sale) => {
        const salesType = day.sales_input_types.find((type) => type.id === sale.input_type_id);
        if (salesType) prev.push({ ...sale, input_type_name: salesType.name });
        else {
          const deactivatedSale = day.sales_values.find(
            (type) => type.input_type_id === sale.input_type_id,
          );
          if (deactivatedSale) {
            deactivatedSale.value = sale.value;
            prev.push(deactivatedSale);
          }
        }
        return prev;
      }, [] as SaleValue[]);
    }
    return day;
  });
};

export const groupCompanyEmployeesByRoles: (data: CompanyEmployeesResponse) => CompanyEmployees = (
  data: CompanyEmployeesResponse,
) =>
  data
    .sort((a, b) => a.branch_name.localeCompare(b.branch_name))
    .map((branch) => ({
      ...branch,
      employees: undefined,
      roles: _orderBy(branch.roles, ['role_index', 'role_name']).map((role) => ({
        ...role,
        employees: _orderBy(
          branch.employees.filter((account) => account.role.id === role.role_id),
          ['first_name', 'last_name'],
        ),
      })),
    }));

const addTemporaryEmployee = (
  state: WeeklyScheduleInitialState,
  employeeBranchId: number,
  employeeRoleId: number,
  newBranchId: number,
  newRole: TemporaryEmployeeRole,
  employeeId: number,
) => {
  const DATE_FORMAT = 'YYYY-MM-DD';
  const weeklyScheduleRole = state.weeklySchedule.data.schedule.find(
    (r) => r.id === newRole.role_id,
  );

  const week = dateUtils.getWeek(state.weeklySchedule.data.date[newBranchId]);
  const accountRole: AccountRole = {
    ...newRole,
    id: newRole.role_id,
    start_date: `${newRole.start_date} 00:00:00`,
    end_date: `${newRole.end_date} 23:59:59`,
  };
  const accountRoleStartDate = newRole.start_date;
  const accountRoleEndDate = newRole.end_date;

  const employee = state.companyEmployees.data.branches
    .find((branch) => branch.branch_id === employeeBranchId)
    ?.roles.find((r) => r.role_id === employeeRoleId)
    ?.employees.find((emp) => emp.id === employeeId);

  const account: WeeklyScheduleEmployee = {
    id: employeeId,
    first_name: employee?.first_name || '',
    last_name: employee?.last_name || '',
    roles_per_day: week.reduce((accr: RolesPerDay, dateObj) => {
      const date = dateObj.format(DATE_FORMAT);

      // eslint-disable-next-line no-param-reassign
      accr[date] = {
        date,
        roles: date >= accountRoleStartDate && date <= accountRoleEndDate ? [accountRole] : [],
      };
      return accr;
    }, {} as RolesPerDay),
    schedules: week.map((dateObj) => {
      const date = dateObj.format(DATE_FORMAT);
      const weekday = dateUtils.getWeekDay(date);
      return {
        date,
        schedule: [],
        weekday,
        roles: date >= accountRoleStartDate && date <= accountRoleEndDate ? [accountRole] : [],
        off_request: null,
        preferences: null,
        unavailabilities: null,
      };
    }),
    total_working_days: 0,
    total_working_hours: 0,
    total_working_shifts: 0,
    total_fixed_wage: 0,
    weekly_wage: 0,
    payroll: {},
    roles: [accountRole],
  };

  const accountWages = getAccountWages(
    accountRole.id,
    account.schedules,
    account.roles_per_day,
    state.weeklySchedule.data.wage_factors,
  );

  account.total_working_days = accountWages.total_working_days;
  account.total_working_hours = accountWages.total_working_hours;
  account.total_working_shifts = accountWages.total_working_shifts;
  account.weekly_wage = accountWages.weekly_wage;
  account.payroll = accountWages.payroll;
  account.total_fixed_wage = accountWages.total_fixed_wage;

  if (weeklyScheduleRole?.data) {
    const employeeInRole = weeklyScheduleRole.data.find((emp) => emp.id === employeeId);
    if (employeeInRole) {
      // employee exist in the schedule
      const rolesPerDay = Object.keys(employeeInRole?.roles_per_day || {});
      // add new role to employee roles per day
      rolesPerDay?.forEach((dayKey) => {
        const day = employeeInRole.roles_per_day[dayKey];
        if (day.date >= accountRoleStartDate && day.date < accountRoleEndDate) {
          day?.roles.push(accountRole);
        }
      });
      // add new role to employee schedules
      employeeInRole?.schedules?.forEach(({ roles, date }) => {
        if (date >= accountRoleStartDate && date < accountRoleEndDate) {
          roles.push(accountRole);
        }
      });
    } else {
      weeklyScheduleRole.data.push(account); // employee not in the schedule add new employee
      // sort role employees
      weeklyScheduleRole.data = positionUtils.sortEmployees(weeklyScheduleRole.data, [
        'first_name',
        'last_name',
      ]);
    }
  } else {
    const groupedShiftRequirements = groupShiftRequirementsByRoles(
      state.weeklySchedule.data.shifts,
    );

    // role not in the schedule add new role with the added employee
    state.weeklySchedule.data.schedule.push({
      id: accountRole.id,
      name: accountRole.name,
      data: [account],
      index: +accountRole.role_index,
      shiftRequirements: groupedShiftRequirements[accountRole.id] || [],
    });
    // eslint-disable-next-line no-param-reassign
    state.weeklySchedule.data.schedule = positionUtils.sortPositions(
      state.weeklySchedule.data.schedule,
    );
  }

  if (employee) {
    // add employee to branch employees
    // eslint-disable-next-line no-param-reassign
    state.companyEmployees.data.branches = state.companyEmployees.data.branches.map((branch) => {
      if (branch.branch_id === newBranchId) {
        let branchRole = branch.roles.find((rl) => rl.role_id === accountRole.id);
        if (!branchRole) {
          branchRole = {
            role_id: accountRole.id,
            role_index: +newRole.role_index,
            role_name: newRole.name,
            employees: [],
          };
          branch.roles.push(branchRole);
        }
        branchRole.employees?.unshift({
          ...employee,
          role: {
            ...accountRole,
            is_temporary_position: 1,
          },
        });
      }
      return branch;
    });
  }
};

const getTemporaryEmployeeDateRanges = (date: string) => {
  const NUM_OF_DAYS_IN_WEEK = 7;
  const oneWeek = dateUtils.setDate(date, { addDays: NUM_OF_DAYS_IN_WEEK - 1 }, 'YYYY-MM-DD');
  const twoWeeks = dateUtils.setDate(date, { addDays: NUM_OF_DAYS_IN_WEEK * 2 - 1 }, 'YYYY-MM-DD');
  const threeWeeks = dateUtils.setDate(
    date,
    { addDays: NUM_OF_DAYS_IN_WEEK * 3 - 1 },
    'YYYY-MM-DD',
  );
  const fourWeeks = dateUtils.setDate(date, { addDays: NUM_OF_DAYS_IN_WEEK * 4 - 1 }, 'YYYY-MM-DD');
  return [
    {
      name: 'This week',
      value: `${date} ${oneWeek}`,
      label: `${dateUtils.formatDate(date, 'DD/MM/YYYY')}-${dateUtils.formatDate(
        oneWeek,
        'DD/MM/YYYY',
      )}`,
    },
    {
      name: '2 weeks',
      value: `${date} ${twoWeeks}`,
      label: `${dateUtils.formatDate(date, 'DD/MM/YYYY')}-${dateUtils.formatDate(
        twoWeeks,
        'DD/MM/YYYY',
      )}`,
    },
    {
      name: '3 weeks',
      value: `${date} ${threeWeeks}`,
      label: `${dateUtils.formatDate(date, 'DD/MM/YYYY')}-${dateUtils.formatDate(
        threeWeeks,
        'DD/MM/YYYY',
      )}`,
    },
    {
      name: '1 month',
      value: `${date} ${fourWeeks}`,
      label: `${dateUtils.formatDate(date, 'DD/MM/YYYY')}-${dateUtils.formatDate(
        fourWeeks,
        'DD/MM/YYYY',
      )}`,
    },
  ];
};

const deleteMultipleSchedules = (
  data: WeeklyScheduleData,
  schedules: { id: number; roleId: number; accountId: number }[],
) => {
  data.forEach((role) => {
    const roleSchedules = schedules.filter((schedule) => schedule.roleId === role.id);
    if (roleSchedules.length) {
      role.data.forEach((account) => {
        const accountSchedules = roleSchedules.filter(
          (schedule) => schedule.accountId === account.id,
        );
        if (accountSchedules.length) {
          account.schedules.forEach((sched) => {
            // eslint-disable-next-line no-param-reassign
            sched.schedule = sched.schedule.filter(
              (_sched) => !accountSchedules.find((item) => item.id === _sched.id),
            );
          });

          addShiftCountData(role);
        }
      });
    }
  });
};

const deleteSelectedSchedules = (state: WeeklyScheduleInitialState) => {
  const copyFromSelectedSchedules = state.selectedSchedules.filter(
    (schedule) => schedule.isCopyFrom && schedule.show,
  );
  const selectedWeeklySchedules = state.selectedSchedules.filter(
    (schedule) => !schedule.isCopyFrom && schedule.show,
  );

  if (selectedWeeklySchedules.length) {
    deleteMultipleSchedules(state.weeklySchedule.data.schedule, selectedWeeklySchedules);
    // eslint-disable-next-line no-param-reassign
    state.publishSchedule.data.count = getUnPublishedShiftsCount(
      state.weeklySchedule.data.schedule,
    );
  }

  if (copyFromSelectedSchedules.length) {
    deleteMultipleSchedules(state.copyFrom.data, copyFromSelectedSchedules);
  }

  Object.keys(_groupBy(state.selectedSchedules, (item) => [item.roleId, item.accountId])).forEach(
    (key) => {
      const [roleId = 0, accountId = 0] = key.split(',') || [0, 0];
      triggerUpdateAccountData(state, +roleId, +accountId);
    },
  );
};

const updateMultipleSchedules = (
  data: WeeklyScheduleData,
  schedules: {
    id: number;
    roleId: number;
    accountId: number;
    start_time?: string;
    end_time?: string;
    note?: string;
    is_close?: 1 | 0;
  }[],
) => {
  data.forEach((role) => {
    const roleSchedules = schedules.filter((schedule) => schedule.roleId === role.id);
    if (roleSchedules.length) {
      role.data.forEach((account) => {
        const accountSchedules = roleSchedules.filter(
          (schedule) => schedule.accountId === account.id,
        );
        if (accountSchedules.length) {
          account.schedules.forEach((sched) => {
            // eslint-disable-next-line no-param-reassign
            sched.schedule = sched.schedule.map((schedule) => {
              const updatedData = accountSchedules.find(
                (newSchedData) => newSchedData.id === schedule.id,
              );
              if (updatedData) {
                return {
                  ...schedule,
                  ...('note' in updatedData ? { note: updatedData.note } : {}),
                  ...('start_time' in updatedData ? { start_time: updatedData.start_time } : {}),
                  ...('is_close' in updatedData ? { is_close: updatedData.is_close } : {}),
                  ...('end_time' in updatedData ? { end_time: updatedData.end_time } : {}),
                };
              }
              return schedule;
            });
          });
        }
      });
    }
  });
};

const updateSelectedSchedules = (
  state: WeeklyScheduleInitialState,
  data: UpdateSelectedSchedulesData,
) => {
  const copyFromSelectedSchedules = state.selectedSchedules
    .filter((schedule) => schedule.isCopyFrom && schedule.show)
    .map((schedule) => ({
      id: schedule.id,
      roleId: schedule.roleId,
      accountId: schedule.accountId,
      ...('note' in data ? { note: data.note } : {}),
      ...('is_close' in data ? { is_close: data.is_close } : {}),
      ...('start_time' in data ? { start_time: data.start_time } : {}),
      ...('end_time' in data ? { end_time: data.end_time } : {}),
      ...('end_times' in data
        ? { end_time: state.weeklySchedule.data.closingTime[schedule.weekday] }
        : {}),
    }));

  const selectedWeeklySchedules = state.selectedSchedules
    .filter((schedule) => !schedule.isCopyFrom && schedule.show)
    .map((schedule) => ({
      id: schedule.id,
      roleId: schedule.roleId,
      accountId: schedule.accountId,
      ...('note' in data ? { note: data.note } : {}),
      ...('is_close' in data ? { is_close: data.is_close } : {}),
      ...('start_time' in data ? { start_time: data.start_time } : {}),
      ...('end_time' in data ? { end_time: data.end_time } : {}),
      ...('end_times' in data
        ? { end_time: state.weeklySchedule.data.closingTime[schedule.weekday] }
        : {}),
    }));

  if (selectedWeeklySchedules.length) {
    updateMultipleSchedules(state.weeklySchedule.data.schedule, selectedWeeklySchedules);
  }

  if (copyFromSelectedSchedules.length) {
    updateMultipleSchedules(state.copyFrom.data, copyFromSelectedSchedules);
  }

  Object.keys(_groupBy(state.selectedSchedules, (item) => [item.roleId, item.accountId])).forEach(
    (key) => {
      const [roleId = 0, accountId = 0] = key.split(',') || [0, 0];
      triggerUpdateAccountData(state, +roleId, +accountId);
    },
  );
};

type ScheduleConflict = {
  id: number;
  start_time: string;
  end_time: string;
  date: string;
};

type DaySchedulesConflicts = {
  date: string;
  weekday: Weekday;
  schedule: Omit<ScheduleConflict, 'date'>[];
};

const checkForTimeConflictsInSelectedSchedules = (
  state: WeeklyScheduleInitialState,
  data: UpdateSelectedSchedulesData,
) => {
  state.weeklySchedule.data.schedule.forEach((role) => {
    const roleSelectedSchedules = state.selectedSchedules
      .filter((schedule) => schedule.roleId === role.id && schedule.show)
      .map((schedule) => ({
        ...schedule,
        start_time: data.start_time || schedule.start_time,
        end_time: data.is_close
          ? state.weeklySchedule.data.closingTime[schedule.weekday]
          : data.end_time || schedule.end_time,
      }));

    if (roleSelectedSchedules.length) {
      role.data.forEach((account) => {
        const copyFromSchedules =
          state.copyFrom.data
            .find((r) => r.id === role.id)
            ?.data.find((emp) => emp.id === account.id)?.schedules || [];

        const accountSelectedSchedules = roleSelectedSchedules.filter(
          (schedule) => schedule.accountId === account.id,
        );

        if (accountSelectedSchedules.length) {
          const selectedScheduleData = dateUtils
            .getWeekDays(account.schedules[0].weekday || 1)
            .map((weekday) => ({
              weekday: weekday.weekday,
              date:
                account.schedules.find((schedule) => schedule.weekday === weekday.weekday)?.date ||
                '',
              schedule: accountSelectedSchedules.filter(
                (schedule) => schedule.weekday === weekday.weekday,
              ),
            }))
            .filter((schedules) => schedules.schedule.length);

          const mergedAccountSchedules = account.schedules
            .map((schedules) => ({
              ...schedules,
              schedule: [
                ...schedules.schedule,
                ...(copyFromSchedules.find((sched) => sched.weekday === schedules.weekday)
                  ?.schedule || []),
              ].map((schedule) => ({
                ...schedule,
                date: schedules.date,
              })),
            }))
            .filter((schedules) => schedules.schedule.length);

          const conflicts = checkForSchedulesConflicts(
            mergedAccountSchedules,
            selectedScheduleData,
          );

          accountSelectedSchedules.forEach(({ id }) => {
            const schedule = state.selectedSchedules.find((sched) => sched.id === id);
            if (schedule) {
              // eslint-disable-next-line no-param-reassign
              if (conflicts.includes(schedule.id)) schedule.isConflict = true;
              // eslint-disable-next-line no-param-reassign
              else schedule.isConflict = false;
            }
          });
        }
      });
    }
  });
};

export interface CopyFromSchedulesCount {
  /**
   *   number of shown conflicting shifts
   */
  numOfConflicts: number;
  /**
   *   number of shown shifts
   */
  numOfShifts: number;
  /**
   *    total number of shifts
   */
  totalNumberOfShifts: number;
  /**
   *    total number of ignored conflicting shifts
   */
  numOfIgnoredShifts: number;
}

/**
 * @param {WeeklyScheduleData} data
 * @returns {CopyFromSchedulesCount}
 */

const getCopyFromScheduleCounts: (data: WeeklyScheduleData) => CopyFromSchedulesCount = (
  data: WeeklyScheduleData,
) =>
  data.reduce(
    (prevRole, role) => {
      const roleResult = role.data.reduce(
        (prevAccount, account) => {
          const accountResult: CopyFromSchedulesCount = {
            numOfConflicts: 0,
            numOfShifts: 0,
            numOfIgnoredShifts: 0,
            totalNumberOfShifts: 0,
          };
          account.schedules.forEach((day) => {
            day.schedule.forEach((schedule) => {
              accountResult.totalNumberOfShifts += 1;
              if (schedule.show) accountResult.numOfShifts += 1;
              if (schedule.isConflict) {
                if (schedule.show) accountResult.numOfConflicts += 1;
                else if (schedule.isIgnored) accountResult.numOfIgnoredShifts += 1;
              }
            });
          });

          return {
            numOfConflicts: prevAccount.numOfConflicts + accountResult.numOfConflicts,
            numOfShifts: prevAccount.numOfShifts + accountResult.numOfShifts,
            numOfIgnoredShifts: prevAccount.numOfIgnoredShifts + accountResult.numOfIgnoredShifts,
            totalNumberOfShifts:
              prevAccount.totalNumberOfShifts + accountResult.totalNumberOfShifts,
          };
        },
        {
          numOfConflicts: 0,
          numOfShifts: 0,
          numOfIgnoredShifts: 0,
          totalNumberOfShifts: 0,
        },
      );

      return {
        numOfConflicts: prevRole.numOfConflicts + roleResult.numOfConflicts,
        numOfShifts: prevRole.numOfShifts + roleResult.numOfShifts,
        numOfIgnoredShifts: prevRole.numOfIgnoredShifts + roleResult.numOfIgnoredShifts,
        totalNumberOfShifts: prevRole.totalNumberOfShifts + roleResult.totalNumberOfShifts,
      };
    },
    {
      numOfConflicts: 0,
      numOfShifts: 0,
      numOfIgnoredShifts: 0,
      totalNumberOfShifts: 0,
    },
  );

const addMultiSchedules = (
  state: WeeklyScheduleInitialState,
  branchId: number,
  branchName: string,
  requestData: AddScheduleRequestData[],
  ids: number[],
) => {
  const newData = requestData.map((sched, index) => ({
    id: ids[index],
    ...sched,
  }));
  const updatedAccounts: { id: number; roleId: number }[] = [];
  const updatedRoles = new Set();

  state.weeklySchedule.data.schedule.forEach((role) => {
    role.data.forEach((account) => {
      const accountNewSchedules = newData.filter((sched) => sched.account_id === account.id);
      accountNewSchedules.forEach((schedule) => {
        const accountDaySchedules = account.schedules.find((day) => day.date === schedule.date);
        if (accountDaySchedules?.schedule) {
          // eslint-disable-next-line no-param-reassign
          state.publishSchedule.data.count += 1;
          accountDaySchedules.schedule.push({
            id: schedule.id,
            start_time: schedule.start_time,
            end_time: schedule.end_time,
            is_close: schedule.is_close,
            break_time: schedule.break_time,
            note: schedule.note,
            ...(schedule.payment_type && schedule.wage
              ? { custom_payment: { payment_type_id: schedule.payment_type, wage: schedule.wage } }
              : {}),
            shift: {
              id: schedule.shift_id || 0,
              is_custom: schedule.custom_shift_name ? 1 : 0,
              name: schedule.shift_name || schedule.custom_shift_name || '',
            },
            is_published: 0,
            is_exchange: 0,
            exchange: null,
            branch_id: branchId,
            branch_name: branchName,
            isConflict: false,
            isCopyFrom: false,
            show: true,
          });
        }
      });

      if (accountNewSchedules.length) {
        updatedAccounts.push({ id: account.id, roleId: role.id });
        updatedRoles.add(role.id);
      }
    });
  });

  updatedAccounts.forEach((account) => {
    triggerUpdateAccountData(state, account.roleId, account.id);
  });

  updatedRoles.forEach((roleId) => {
    const role = state.weeklySchedule.data.schedule.find((r) => r.id === roleId);
    if (role) addShiftCountData(role);
  });
};

const fillRequirementsEmptyDays = (data: RoleShiftRequirement[], firstWeekday: Weekday) => {
  let { weekday } = data[0];
  const newData: RoleShiftRequirement[] = dateUtils.getWeekDays(firstWeekday).map((day) => ({
    ...(data.find((dayData) => day.weekday === dayData.weekday) || {
      weekday: day.weekday,
      end_hour: undefined,
      num: 0,
      reqNum: undefined,
      start_hour: undefined,
    }),
  }));

  // order by first week day
  for (let index = 0; index < newData.length; index += 1) {
    if (weekday === firstWeekday) return newData;
    const firstdayData = newData.shift();
    if (firstdayData) newData.push(firstdayData);
    weekday = newData[0].weekday;
  }
  return newData;
};

export const getAccountWorkingSummary = (
  totalWorkingHours: number,
  totalWorkingDays: number,
  totalWorkingShifts: number,
) => {
  const result = [];
  if (totalWorkingHours !== 0)
    result.push(`${generalUtils.toFixedDecimals(totalWorkingHours, 2)} hr`);
  if (totalWorkingDays !== 0)
    result.push(`${totalWorkingDays} day${totalWorkingDays !== 1 ? 's' : ''}`.trimEnd());
  if (totalWorkingShifts !== 0)
    result.push(`${totalWorkingShifts} shift${totalWorkingShifts !== 1 ? 's' : ''}`.trimEnd());
  return result.join(', ');
};

const updateOffRequest = (state: WeeklyScheduleInitialState, data: UpdateOffRequest) => {
  const role = state.weeklySchedule.data.schedule.find((r) => r.id === data.roleId);
  const employee = role?.data.find((emp) => emp.id === data.accountId);
  const schedule = employee?.schedules.find((sch) => sch.date === data.date);
  const request = schedule?.off_request?.find((req) => req.id === data.requestId);

  if (request?.id) {
    request.is_approved = data.isApproved;
  }
};

const compareUpdateOffRequestsData = (data1: UpdateOffRequest, data2?: UpdateOffRequest) =>
  data1.accountId === data2?.accountId &&
  data1.branchId === data2?.branchId &&
  data1.date === data2?.date &&
  data1.requestId === data2?.requestId &&
  data1.roleId === data2?.roleId;

function getDefaultShiftsByWeekday(defaultShifts: WeekShifts[]) {
  const result: { [key: number]: Shift[] } = {};
  defaultShifts.forEach((day) => {
    day.shifts.forEach((shift) => {
      if (!result[day.weekday]) result[day.weekday] = [shift];
      else result[day.weekday].push(shift);
    });
  });
  return result;
}

export default {
  getAccountWorkingSummary,
  getDefaultShiftsByWeekday,
  convertResponseData,
  getUnPublishedShiftsCount,
  publishSchedules,
  getPrevScheduleData,
  addSchedule,
  deleteSchedule,
  updateSchedule,
  updateWeeklySales,
  addTemporaryEmployee,
  groupCompanyEmployeesByRoles,
  getTemporaryEmployeeDateRanges,
  deleteSelectedSchedules,
  updateSelectedSchedules,
  checkForTimeConflictsInSelectedSchedules,
  getCopyFromData,
  getCopyFromScheduleCounts,
  filterSchedules,
  clearSelectedCopyFromData,
  clearSelectedWeeklySchedules,
  addMultiSchedules,
  fillRequirementsEmptyDays,
  updateOffRequest,
  compareUpdateOffRequestsData,
  scheduleHasConflicts,
};
