import { useEffect, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import AppDrawer from './AppDrawer';
import Main from './AppMain';
import { MobileScreen } from '../../components';

import './style.scss';

const MOBILE_SCREEN_WIDTH = 767;

function Layout() {
  const [open, setOpen] = useState(false);
  const [showMobileScreen, setShowMobileScreen] = useState(
    window.innerWidth <= MOBILE_SCREEN_WIDTH,
  );

  const toggleDrawer = useCallback(() => setOpen((prev) => !prev), []);

  const handleWindowSizeChange = useCallback(() => {
    const isMobile = window.innerWidth <= MOBILE_SCREEN_WIDTH;
    setShowMobileScreen(isMobile);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  if (showMobileScreen) return <MobileScreen />;

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <AppDrawer open={open} toggleDrawer={toggleDrawer} />
      <CssBaseline />
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
}

export default Layout;
