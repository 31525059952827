/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import { UpdateOffRequest } from '../../types/WeeklySchedule';
import utils from '../../shared/utils';

interface ActionType<T, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data?: T;
}

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
};

export interface InitialState {
  acceptOffRequest: ActionType<UpdateOffRequest, ResponseSuccess<boolean>>;
  rejectOffRequest: ActionType<UpdateOffRequest, ResponseSuccess<boolean>>;
}

const initialState: InitialState = {
  acceptOffRequest: {
    ...ACTIONS_INITIAL_STATE,
  },
  rejectOffRequest: {
    ...ACTIONS_INITIAL_STATE,
  },
};

export const manageOffRequestsInSchedule = createSlice({
  name: 'weekly/schedule/off_request',
  initialState,
  reducers: {
    acceptOffRequestStarted: (state, action: PayloadAction<UpdateOffRequest>) => {
      state.acceptOffRequest = {
        ...initialState.acceptOffRequest,
        isLoading: true,
        data: action.payload,
      };
    },
    acceptOffRequestSuccess: (state, action: PayloadAction<ResponseSuccess<boolean>>) => {
      state.acceptOffRequest.isLoading = false;
      state.acceptOffRequest.response = action.payload;
    },
    acceptOffRequestFail: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.acceptOffRequest.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.acceptOffRequest.error = action.payload;
      } else {
        state.acceptOffRequest.response = action.payload;
      }
    },

    rejectOffRequestStarted: (state, action: PayloadAction<UpdateOffRequest>) => {
      state.rejectOffRequest = {
        ...initialState.rejectOffRequest,
        isLoading: true,
        data: action.payload,
      };
    },
    rejectOffRequestSuccess: (state, action: PayloadAction<ResponseSuccess<boolean>>) => {
      state.rejectOffRequest.isLoading = false;
      state.rejectOffRequest.response = action.payload;
    },
    rejectOffRequestFail: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.rejectOffRequest.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.rejectOffRequest.error = action.payload;
      } else {
        state.rejectOffRequest.response = action.payload;
      }
    },
    resetSlices: (state) => {
      state.acceptOffRequest = { ...initialState.acceptOffRequest };
      state.rejectOffRequest = { ...initialState.rejectOffRequest };
    },
  },
});

export default manageOffRequestsInSchedule.reducer;

export const {
  acceptOffRequestFail,
  acceptOffRequestStarted,
  acceptOffRequestSuccess,

  rejectOffRequestFail,
  rejectOffRequestStarted,
  rejectOffRequestSuccess,

  resetSlices,
} = manageOffRequestsInSchedule.actions;

export const selectAcceptOffRequestState = (state: State<InitialState>) =>
  state.manageOffRequestsInSchedulesSlice.acceptOffRequest;

export const selectRejectOffRequestState = (state: State<InitialState>) =>
  state.manageOffRequestsInSchedulesSlice.rejectOffRequest;
