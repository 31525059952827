/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { State } from '../../types/Store';

export type InitialState = {
  showRequirements: boolean;
  showPreferences: boolean;
  showOffRequests: boolean;
};

const initialState: InitialState = {
  showRequirements: false,
  showPreferences: false,
  showOffRequests: false,
};

export const weeklyScheduleFilters = createSlice({
  name: 'weekly/filters',
  initialState,
  reducers: {
    toggleShowRequirements: (state) => {
      state.showRequirements = !state.showRequirements;
    },
    toggleShowPreferences: (state) => {
      state.showPreferences = !state.showPreferences;
    },
    toggleShowOffRequests: (state) => {
      state.showOffRequests = !state.showOffRequests;
    },
  },
});

export default weeklyScheduleFilters.reducer;

export const { actions } = weeklyScheduleFilters;

export const selectShowPreferences = (state: State<InitialState>) =>
  state.weeklyScheduleFiltersSlice.showPreferences;
export const selectShowRequirements = (state: State<InitialState>) =>
  state.weeklyScheduleFiltersSlice.showRequirements;
export const selectShowOffRequests = (state: State<InitialState>) =>
  state.weeklyScheduleFiltersSlice.showOffRequests;
