import { useCallback, useMemo, useState } from 'react';
import { NavLinkType } from './NavLinksConfig';
import NavLinkItem from './NavLinkItem';
import TouchTooltip from '../../components/TouchTooltip/TouchTooltip';

import './NavItem.scss';

type NavItemProps = {
  open: boolean;
  link: NavLinkType;
  pathname: string;
  onClick?: () => void;
};

export default function NavItem(props: NavItemProps) {
  const { open, link, pathname, onClick } = props;
  const [showSubLinks, setSubShowLinks] = useState(true);

  const buttonClickHandler = useCallback(() => {
    setSubShowLinks((prev) => !prev);
    onClick?.();
  }, [onClick]);

  const hasActiveChild = useMemo(
    () => !!link.navItems?.find((l) => l.to === pathname),
    [link.navItems, pathname],
  );

  return (
    <div
      className={`${link.navItems?.length ? 'nav-item-dropdown' : 'nav-item-single'} nav-item`}
      key={link.id}
    >
      <TouchTooltip
        key={`tooltip-${link.id}`}
        placement="right"
        classes={{
          tooltip: `sub-nav ${
            link.navItems?.length ? 'sub-nav-tooltip-multi' : 'sub-nav-tooltip-singular'
          }`,
        }}
        disabled={open}
        disableFocusListener
        title={
          !open ? (
            <div className="sub-nav-container nav-item">
              {link.navItems?.length ? null : (
                <NavLinkItem
                  open
                  link={{ ...link, navItems: undefined }}
                  key={link.to}
                  className="main-link"
                  onClick={onClick}
                />
              )}
              {link.navItems?.length ? (
                <div className="sub-link-container">
                  {link.navItems?.map((item) => {
                    return <NavLinkItem open link={item} key={item.to} className="sub-link" />;
                  })}
                </div>
              ) : null}
            </div>
          ) : null
        }
      >
        <span className={`main-link-span ${hasActiveChild ? 'has-active-child' : ''}`}>
          <NavLinkItem
            className="main-link"
            key={link.id}
            link={link}
            open={open}
            onClick={buttonClickHandler}
            isDropDownOpened={showSubLinks}
          />
        </span>
      </TouchTooltip>

      {open && showSubLinks && !!link.navItems?.length && (
        <div className="sub-link-container">
          {link.navItems?.map((navItem) => (
            <NavLinkItem key={navItem.id} link={navItem} open={open} className="sub-link" />
          ))}{' '}
        </div>
      )}
    </div>
  );
}

NavItem.defaultProps = {
  onClick: () => {},
};
