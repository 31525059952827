import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { analytics } from './resources/index';
import theme from './styles/Theme/theme';
import AppRoutes from './Root/Routes/AppRoutes';
import { AuthProvider } from './context/AuthContext/AuthContext';
import { SettingsProvider } from './context/SettingsContext/SettingsContext';
import NetworkDetectorProvider from './context/NetworkStatusContext';
import { AlertProvider } from './context/AlertContext/AlertContext';
import { Constants } from './constants';
import store from './store/store';
import { ErrorBoundary, ErrorView } from './bugsnag';
import useServiceWorker from './hooks/useServiceWorker';

import './styles/global.scss';

if (import.meta.env.MODE === Constants.NODE_ENV.PRODUCTION) {
  hotjar.initialize(1174830, 6);
}

function App() {
  useServiceWorker();

  const handleVitePreloadError = useCallback((event: Event) => {
    event.preventDefault();
    window.location.reload();
  }, []);

  useEffect(() => {
    // prevent error and reload window on vite:preloadError
    // this event is dispatched when user requested the old build files
    // while the files are replaced in the new build when there are changes in the requested component
    window.addEventListener('vite:preloadError', handleVitePreloadError);

    return () => {
      window.removeEventListener('vite:preloadError', handleVitePreloadError);
    };
  }, [handleVitePreloadError]);

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <NetworkDetectorProvider>
              <AlertProvider>
                <AuthProvider>
                  <SettingsProvider>
                    <AppRoutes />
                  </SettingsProvider>
                </AuthProvider>
              </AlertProvider>
            </NetworkDetectorProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </ErrorBoundary>
  );
}

analytics.init();

export default App;
