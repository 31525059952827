import { SvgUntitledIconProps } from '../types/Shared';

interface ChevronRightDoubleProps extends SvgUntitledIconProps {}

export default function ChevronRightDouble(props: ChevronRightDoubleProps) {
  const {
    height = '24',
    width = '24',
    stroke = 'currentColor',
    strokeWidth = '2',
    fill = 'none',
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
    >
      <path
        d="M6 17L11 12L6 7M13 17L18 12L13 7"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
