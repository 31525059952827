import React, { createContext, useState, useMemo, useCallback, FC, useEffect } from 'react';
import AlertContextSnackbar from './AlertContextSnackbar';
import { UntitledAlertProps } from '../../components/UntitledAlert/UntitledAlert';
import utils from '../../shared/utils';

export interface SnackbarAlertType
  extends Omit<UntitledAlertProps, 'className' | 'open' | 'onActionClick'> {}

interface AlertsType extends SnackbarAlertType {
  id: string;
  open: boolean;
  openSnackbar: boolean;
}

type ContextType = {
  appendAlert: (alert: SnackbarAlertType) => void;
};

const contextDefaultState: ContextType = {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars */
  appendAlert: (alert: SnackbarAlertType) => {},
};

const AlertContext = createContext({
  ...contextDefaultState,
});

interface AlertProviderProps {
  children: React.ReactNode;
}

export const AlertProvider: FC<AlertProviderProps> = function AlertProvider(
  props: AlertProviderProps,
) {
  const [alerts, setAlerts] = useState<AlertsType[]>([]);

  const { children } = props;

  const handleCloseAlert = useCallback((id?: string | number) => {
    setAlerts((prev) => prev.map((alert) => ({ ...alert, openSnackbar: alert.id !== id })));
  }, []);

  const appendAlert = useCallback(
    (alert: SnackbarAlertType) =>
      setAlerts((prev) => [
        {
          ...alert,
          id: `${utils.date.now(true, 'YYYY-MM-DD HH:mm:ss')}-${Math.floor(
            Math.random() * 99 + 1,
          )}-${prev.length}`,
          open: true,
          openSnackbar: true,
        },
        ...prev,
      ]),
    [],
  );

  const providerValue = useMemo(
    () => ({
      appendAlert,
    }),
    [appendAlert],
  );

  useEffect(() => {
    let timeOut1: number;
    let timeOut2: number;

    if (alerts.some((alert) => !alert.openSnackbar && alert.open)) {
      timeOut1 = setTimeout(() => {
        setAlerts((prev) =>
          prev.map((alert) => ({
            ...alert,
            open: alert.open && !alert.openSnackbar ? false : alert.open,
          })),
        );
      }, 200);
    }

    if (alerts.some((alert) => !alert.open && !alert.openSnackbar)) {
      timeOut2 = setTimeout(() => {
        setAlerts((prev) => prev.filter((alert) => !(!alert.open && !alert.openSnackbar)));
      }, 200);
    }

    return () => {
      if (timeOut1) clearTimeout(timeOut1);
      if (timeOut2) clearTimeout(timeOut2);
    };
  }, [alerts]);

  const snackbarTranslateTop = useMemo(() => {
    const result: { [key: string]: number } = {};
    let top = 24;
    alerts.forEach((alert) => {
      if (alert.open || alert.openSnackbar) {
        result[alert.id] = top;
        if (alert.color === 'error') top += 81;
        else top += 60;
      }
    });
    return result;
  }, [alerts]);

  return (
    <AlertContext.Provider value={providerValue}>
      {alerts.map((alert) => (
        <AlertContextSnackbar
          key={alert.id}
          message={alert.text}
          open={alert.openSnackbar}
          handleClose={handleCloseAlert}
          id={alert.id}
          autoCloseIn={5000}
          style={{
            ...(snackbarTranslateTop?.[alert.id]
              ? { top: `${snackbarTranslateTop[alert.id]}px` }
              : {}),
          }}
          alertProps={{
            ...alert,
            title: alert.color === 'error' && !alert.title ? 'Error' : alert.title || undefined,
            onClose: handleCloseAlert,
            open: alert.open,
          }}
        />
      ))}
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
