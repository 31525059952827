import { Components, Theme } from '@mui/material';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        '.MuiOutlinedInput-root': {
          padding: '0 30px 0 0!important',
        },

        ...(ownerState.size === 'small' && {
          '.MuiAutocomplete-input': {
            padding: '8px 12px !important',
          },
        }),

        ...(ownerState.size === 'medium' && {
          '.MuiAutocomplete-input': {
            padding: '10px 14px !important',
          },
        }),
      }),
    },
  },
};

export default components.MuiAutocomplete;
