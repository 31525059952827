import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { actions } from '../slices/weeklyScheduleSlice';
import { actions as openShiftActions } from '../slices/openShifts';
import { WeekShifts } from '../../types/Shift';
import actionTypes from '../actionTypes/weeklySchedule';
import {
  CompanyEmployeesResponse,
  TemporaryEmployeeRole,
  UpdateSelectedSchedulesData,
  WeeklyScheduleResponse,
} from '../../types/WeeklySchedule';
import utils from '../../shared/utils';
import { Response } from '../../types/Response';
import { isResponseSuccess } from '../../shared/utils/general';
import { Sales, UpdateWeeklySalesInputTypeData } from '../../types/Sales';
import { AddScheduleRequestData } from '../../types/Schedule';

function* fetchWeeklySchedule({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(
      actions.fetchWeeklyScheduleStarted({ branchId: payload.branchId, date: payload.date }),
    );
    yield put(openShiftActions.setOpenShiftsState([]));

    const arrayResponse: [Response<WeeklyScheduleResponse>, Response<WeekShifts[]>] = yield all([
      call(() =>
        api.schedule.getSchedule(payload.branchId, payload.date, { getAllOffRequests: true }),
      ),
      call(() => api.shifts.getShifts(payload.branchId, payload.date)),
    ]);

    if (isResponseSuccess(arrayResponse[0].data) && isResponseSuccess(arrayResponse[1].data)) {
      yield put(
        actions.fetchWeeklyScheduleSuccess({
          data: arrayResponse[0].data.result,
          shifts: arrayResponse[1].data.result,
        }),
      );
      yield put(openShiftActions.setOpenShiftsState(arrayResponse[0].data.result.open_shifts));
    } else {
      if (!isResponseSuccess(arrayResponse[0].data)) {
        yield put(actions.fetchWeeklyScheduleError(arrayResponse[0].data));
      }
      if (!isResponseSuccess(arrayResponse[1].data)) {
        yield put(actions.fetchWeeklyScheduleError(arrayResponse[1].data));
      }
    }
  } catch (error) {
    yield put(actions.fetchWeeklyScheduleError(utils.general.convertAxiosError(error)));
  }
}

function* publishSchedules({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(actions.publishScheduleStarted());
    const response: Response<boolean> = yield api.schedule.postPublishSchedule(payload.branchId, {
      date: payload.date,
    });
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.publishScheduleSuccess({ branchId: payload.branchId, response: response.data }),
      );
    } else {
      yield put(actions.publishScheduleError(response.data));
    }
  } catch (error) {
    yield put(actions.publishScheduleError(utils.general.convertAxiosError(error)));
  }
}

function* fetchPreviousWeeklySchedule({
  payload,
}: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(
      actions.fetchPreviousWeeklyScheduleStarted({
        branchId: payload.branchId,
        date: payload.date,
      }),
    );
    const response: Response<WeeklyScheduleResponse> = yield call(() =>
      api.schedule.getSchedule(payload.branchId, payload.date),
    );

    if (isResponseSuccess(response.data)) {
      yield put(actions.fetchPreviousWeeklyScheduleSucces(response.data.result));
    } else {
      yield put(actions.fetchPreviousWeeklyScheduleError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchPreviousWeeklyScheduleError(utils.general.convertAxiosError(error)));
  }
}

function* fetchWeeklySales({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(actions.fetchWeeklySalesStarted());
    const response: Response<Sales> = yield call(() =>
      api.sales.getWeekSales(payload.branchId, payload.date),
    );

    if (isResponseSuccess(response.data)) {
      yield put(actions.fetchWeeklySalesSuccess(response.data));
    } else {
      yield put(actions.fetchWeeklySalesError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchWeeklySalesError(utils.general.convertAxiosError(error)));
  }
}

function* updateWeeklySales({
  payload,
}: PayloadAction<{ branchId: number; data: UpdateWeeklySalesInputTypeData }>) {
  try {
    yield put(actions.updateWeeklySalesStarted(payload.data));
    const response: Response<boolean> = yield call(() =>
      api.sales.postSales(payload.branchId, payload.data),
    );

    if (isResponseSuccess(response.data)) {
      yield put(
        actions.updateWeeklySalesSuccess({
          data: payload.data,
          response: response.data,
        }),
      );
    } else {
      yield put(actions.updateWeeklySalesError(response.data));
    }
  } catch (error) {
    yield put(actions.updateWeeklySalesError(utils.general.convertAxiosError(error)));
  }
}

function* addTemporaryEmployee({
  payload,
}: PayloadAction<{
  newRole: TemporaryEmployeeRole;
  employeeId: number;
  newBranchId: number;
  employeeBranchId: number;
  employeeRoleId: number;
}>) {
  try {
    yield put(
      actions.addTemporaryEmployeeStarted({
        accountId: payload.employeeId,
        roleId: payload.newRole.role_id,
      }),
    );
    const response: Response<boolean> = yield call(() =>
      api.employee.putEmployeeRole(payload.newBranchId, payload.employeeId, payload.newRole),
    );

    if (isResponseSuccess(response.data)) {
      yield put(
        actions.addTemporaryEmployeeSuccess({
          response: response.data,
          employeeBranchId: payload.employeeBranchId,
          employeeId: payload.employeeId,
          employeeRoleId: payload.employeeRoleId,
          newBranchId: payload.newBranchId,
          newRole: payload.newRole,
        }),
      );
    } else {
      yield put(actions.addTemporaryEmployeeError(response.data));
    }
  } catch (error) {
    yield put(actions.addTemporaryEmployeeError(utils.general.convertAxiosError(error)));
  }
}

function* fetchCompanyEmployees({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(actions.fetchCompanyEmployeesStarted());
    const response: Response<CompanyEmployeesResponse> = yield call(() =>
      api.employee.getCompanyEmployees(payload.branchId, payload.date),
    );

    if (isResponseSuccess(response.data)) {
      yield put(
        actions.fetchCompanyEmployeesSuccess({
          date: payload.date,
          data: response.data.result,
        }),
      );
    } else {
      yield put(actions.fetchCompanyEmployeesError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchCompanyEmployeesError(utils.general.convertAxiosError(error)));
  }
}

function* updateSelectedWeeklySchedules({
  payload,
}: PayloadAction<{
  branchId: number;
  data: UpdateSelectedSchedulesData;
}>) {
  try {
    yield put(actions.updateSelectedSchedulesStarted());

    if (payload.data.schedule_ids.length) {
      const response: Response<boolean> = yield call(() =>
        api.schedule.editMultipleSchedules(payload.branchId, payload.data),
      );

      if (isResponseSuccess(response.data)) {
        yield put(
          actions.updateSelectedSchedulesSuccess({
            data: payload.data,
            response: response.data,
          }),
        );
      } else {
        yield put(actions.updateSelectedSchedulesError(response.data));
      }
    } else {
      yield put(
        actions.updateSelectedSchedulesSuccess({
          data: payload.data,
          response: utils.general.getEmptySuccessResponse(true),
        }),
      );
    }
  } catch (error) {
    yield put(actions.updateSelectedSchedulesError(utils.general.convertAxiosError(error)));
  }
}

function* deleteSelectedWeeklySchedules({
  payload,
}: PayloadAction<{ branchId: number; schedule_ids: number[] }>) {
  try {
    yield put(actions.deleteSelectedSchedulesStarted());

    if (payload.schedule_ids.length) {
      const response: Response<boolean> = yield call(() =>
        api.schedule.deleteMultipleSchedules(payload.branchId, {
          schedule_ids: payload.schedule_ids,
        }),
      );

      if (isResponseSuccess(response.data)) {
        yield put(actions.deleteSelectedSchedulesSuccess(response.data));
      } else {
        yield put(actions.deleteSelectedSchedulesError(response.data));
      }
    } else
      yield put(
        actions.deleteSelectedSchedulesSuccess(utils.general.getEmptySuccessResponse(true)),
      );
  } catch (error) {
    yield put(actions.deleteSelectedSchedulesError(utils.general.convertAxiosError(error)));
  }
}

function* fetchCopyFromSchedule({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(actions.fetchCopyFromScheduleStarted());
    const response: Response<WeeklyScheduleResponse> = yield call(() =>
      api.schedule.getSchedule(payload.branchId, payload.date),
    );

    if (isResponseSuccess(response.data)) {
      yield put(actions.fetchCopyFromScheduleSucces(response.data.result));
    } else {
      yield put(actions.fetchCopyFromScheduleError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchCopyFromScheduleError(utils.general.convertAxiosError(error)));
  }
}

function* addCopyFromSchedules({
  payload,
}: PayloadAction<{
  branchId: number;
  branchName: string;
  managerId: number;
  schedules: AddScheduleRequestData[];
}>) {
  try {
    yield put(actions.addCopyFromScheduleStarted(payload.schedules));

    const response: Response<{ schedule_ids: number[] }> = yield call(() =>
      api.schedule.postMultipleSchedules(payload.branchId, {
        manager_id: payload.managerId,
        schedules: payload.schedules,
      }),
    );

    if (isResponseSuccess(response.data)) {
      yield put(
        actions.addCopyFromScheduleSucces({
          branchId: payload.branchId,
          branchName: payload.branchName,
          data: payload.schedules,
          response: response.data,
        }),
      );
    } else {
      yield put(actions.addCopyFromScheduleError(response.data));
    }
  } catch (error) {
    yield put(actions.addCopyFromScheduleError(utils.general.convertAxiosError(error)));
  }
}

function* watchWeeklySchedule() {
  yield all([
    takeLatest(actionTypes.GET_WEEKLY_SCHEDULE, fetchWeeklySchedule),
    takeLatest(actionTypes.GET_PREVIOUS_WEEKLY_SCHEDULE, fetchPreviousWeeklySchedule),
    takeLatest(actionTypes.PUBLISH_WEEKLY_SCHEDULE, publishSchedules),
    takeLatest(actionTypes.GET_WEEKLY_SALES, fetchWeeklySales),
    takeLatest(actionTypes.UPDATE_WEEKLY_SALES, updateWeeklySales),
    takeLatest(actionTypes.ADD_TEMPORARY_EMPLOYEE, addTemporaryEmployee),
    takeLatest(actionTypes.GET_COMPANY_EMPLOYEES, fetchCompanyEmployees),
    takeLatest(actionTypes.DELETE_SELECTED_WEEKLY_SCHEDULES, deleteSelectedWeeklySchedules),
    takeLatest(actionTypes.UPDATE_SELECTED_WEEKLY_SCHEDULES, updateSelectedWeeklySchedules),
    takeLatest(actionTypes.GET_COPY_FROM_WEELY_SCHEDULE, fetchCopyFromSchedule),
    takeLatest(actionTypes.ADD_COPY_FROM_WEELY_SCHEDULE, addCopyFromSchedules),
  ]);
}

export default watchWeeklySchedule;
