import { useRef } from 'react';
import Cb, { CheckboxProps } from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Check from '../../icons/Check';

import './Checkbox.scss';

export interface UntitledCheckboxProps {
  label?: string | React.ReactNode;
  supportingText?: string;
  size?: 'small' | 'medium';
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  checkBoxProps?: CheckboxProps;
  className?: string;
  disabled?: boolean;
}

const UntitledCheckbox = function UntitledCheckbox(props: UntitledCheckboxProps) {
  const { className, label, supportingText, size, formControlLabelProps, checkBoxProps, disabled } =
    props;
  const checkboxRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Box
      className={`untitled-checkbox-container  ${className || ''} size-${size} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <FormControlLabel
        label={label}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formControlLabelProps}
        disabled={disabled}
        control={
          <Cb
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...checkBoxProps}
            size={size}
            ref={checkboxRef}
            className="untitled-checkbox"
            checkedIcon={<Check />}
            disabled={disabled}
          />
        }
      />
      {supportingText && <div className="supporting-text">{supportingText}</div>}
    </Box>
  );
};

UntitledCheckbox.defaultProps = {
  label: '',
  supportingText: '',
  formControlLabelProps: undefined,
  checkBoxProps: undefined,
  className: '',
  size: 'small',
  disabled: false,
};

export default UntitledCheckbox;
