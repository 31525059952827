const configs = {
  FEATURES_ID: {
    OPEN_SHIFT: 1,
    EXCHANGE_REQUEST: 2,
    OFF_DAY: 3,
    DAILY_SCHEDULE: 5,
    EXPORT_EXCEL: 7,
    REPORTING: 8,
    PUNCHING: 10,
    SALES: 11,
    TEMPORARY_EMPLOYEE: 12,
  },
  EXCEL: {
    MANAGER_DASHBOARD_EXPORT: { FILE_NAME: 'Schedule' },
    ATTENDANCE_REPORT_EXPORT: { FILE_NAME: 'Attendance Report' },
    PUNCHING_REPORT_EXPORT: { FILE_NAME: 'Punching Report' },
    SUMMARY_REPORT_EXPORT: { FILE_NAME: 'Summary Report' },
  },
  DRAWER_WIDTH: {
    OPEN: 312,
    CLOSED: 82,
  },
  DIALOG_NAME: {
    SCHEDULE_DIALOG: 'NAME_SCHEDULE_DIALOG',
    REMOVE_EMPLOYEE_DIALOG: 'REMOVE_EMPLOYEE_DIALOG',
    IMPORT_EMPLOYEE_DIALOG: 'IMPORT_EMPLOYEE_DIALOG',
    REQUEST_DIALOG: 'NAME_REQUEST_DIALOG',
    SELECTED_SHIFTS_DIALOG: 'SELECTED_SHIFTS_DIALOG',
    SELECTED_DEFAULT_SHIFTS_DIALOG: 'SELECTED_DEFAULT_SHIFTS_DIALOG',
    SALES_INPUT_TYPES_DIALOG: 'SALES_INPUT_TYPES_DIALOG',
    PROJECTED_SALES_COMPUTATION_DIALOG: 'PROJECTED_SALES_COMPUTATION_DIALOG',
    ADD_TEMPORARY_EMPLOYEE_DIALOG: 'ADD_TEMPORARY_EMPLOYEE_DIALOG',
    TEMPORARY_EMPLOYEE_LIST_DIALOG: 'TEMPORARY_EMPLOYEE_LIST_DIALOG',
    CUSTOM_TRANSFER_DURATION_DIALOG: 'CUSTOM_TRANSFER_DURATION_DIALOG',
    CREATE_PASSWORD_DIALOG: 'CREATE_PASSWORD_DIALOG',
  },
};

export default configs;
