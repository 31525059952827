import { useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton/IconButton';
import useAuth from '../../hooks/useAuth';
import LogOut01 from '../../icons/LogOut01';

import './User.scss';

type UserProps = {
  open: boolean;
};

export default function User(props: UserProps) {
  const { open } = props;
  const { auth, logout } = useAuth();

  const avatarLetters = useMemo(
    () =>
      `${auth.userData.name.split(' ')[0]?.[0] || ''}${
        auth.userData.name.split(' ')[1]?.[0] || ''
      }`.trim(),
    [auth.userData.name],
  );

  return (
    <div className={`user-summary ${open ? 'show-details' : ''}`}>
      <Avatar sx={{ width: 40, height: 40 }}>{avatarLetters}</Avatar>
      <div className="user-info">
        <p className="user-name">{auth.userData.name}</p>
        <p className="user-email">{auth.email}</p>
      </div>
      <IconButton className="log-out-button" onClick={logout}>
        <LogOut01 strokeWidth="1.66667" />
      </IconButton>
    </div>
  );
}
