/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from '../../types/Store';
import utils from '../../shared/utils';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import {
  CompanyEmployeesResponse,
  SelectedDefaultShiftRequirment,
  SelectedSchedule,
  TemporaryEmployeeRole,
  UpdateOffRequest,
  UpdateSelectedSchedulesData,
  WeeklyScheduleFilters,
  WeeklyScheduleInitialState,
  WeeklyScheduleResponse,
  WeeklyScheduleTableFilter,
} from '../../types/WeeklySchedule';
import { WeekShifts } from '../../types/Shift';
import { AddScheduleRequestData, UpdateScheduleRequestData } from '../../types/Schedule';
import { Sales, UpdateWeeklySalesInputTypeData } from '../../types/Sales';

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
  data: undefined,
};

export const INITIAL_TABLE_FILTERS = {
  shifts: [],
  positions: [],
  employees: [],
  has_break: false,
  has_comments: false,
  has_exchange: false,
  has_leave_requested: false,
  payment_type: [],
};

const initialState: WeeklyScheduleInitialState = {
  weeklySchedule: {
    ...ACTIONS_INITIAL_STATE,
    data: { schedule: [], shifts: [], date: {}, wage_factors: [], closingTime: {} },
  },
  previousWeekSchedule: {
    ...ACTIONS_INITIAL_STATE,
    data: { branchId: 0, date: '', schedule: [] },
  },
  copyFrom: { ...ACTIONS_INITIAL_STATE, data: [] },
  copyFromFilters: {},
  publishSchedule: { ...ACTIONS_INITIAL_STATE, data: { count: 0, numberOfEmployeesNotified: 0 } },
  sales: { ...ACTIONS_INITIAL_STATE, data: { current_week: [], previous_week: [] } },
  updateSales: { ...ACTIONS_INITIAL_STATE, data: { date: '', sales: [] } },
  companyEmployees: { ...ACTIONS_INITIAL_STATE, data: { date: '', branches: [] } },
  addTemporaryEmployee: {
    ...ACTIONS_INITIAL_STATE,
    data: { accountId: 0, roleId: 0 },
  },
  selectedSchedules: [],
  deleteSelectedSchedules: { ...ACTIONS_INITIAL_STATE },
  updateSelectedSchedules: { ...ACTIONS_INITIAL_STATE },
  selectedDefaultShifRequirement: undefined,
  quickAddShiftName: '',
  addCopyFromSchedules: { ...ACTIONS_INITIAL_STATE, data: [] },
  numberOfSchedulesAddedFromPreviousSchedule: 0,
  numberOfSchedulesRemovedUsingForm: 0,
  latestUpdatedScheduleValues: {
    end_time: '',
    is_close: 0,
    start_time: '',
  },
  showMultiEditSchedulesDemo: false,
  filter: INITIAL_TABLE_FILTERS,
};

export const weeklySchedule = createSlice({
  name: 'weekly/schedule',
  initialState,
  reducers: {
    // fetch data
    fetchWeeklyScheduleStarted: (
      state,
      action: PayloadAction<{ branchId: number; date: string }>,
    ) => {
      utils.weeklySchedule.clearSelectedWeeklySchedules(state);
      state.numberOfSchedulesAddedFromPreviousSchedule = 0;
      state.numberOfSchedulesRemovedUsingForm = 0;
      state.showMultiEditSchedulesDemo = false;
      state.publishSchedule = { ...initialState.publishSchedule };
      state.quickAddShiftName = '';
      state.latestUpdatedScheduleValues = { ...initialState.latestUpdatedScheduleValues };
      state.showMultiEditSchedulesDemo = false;
      state.weeklySchedule = {
        ...initialState.weeklySchedule,
        isLoading: true,
        data: {
          ...initialState.weeklySchedule.data,
          schedule: [],
          shifts: [],
          date: {
            ...state.weeklySchedule.data?.date,
            [action.payload.branchId.toString()]: action.payload.date,
          },
        },
      };
    },
    fetchWeeklyScheduleSuccess: (
      state,
      action: PayloadAction<{ data: WeeklyScheduleResponse; shifts: WeekShifts[] }>,
    ) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { schedule, shifts, wage_factors } = utils.weeklySchedule.convertResponseData(
        action.payload.data,
        utils.shift.sortShifts(action.payload.shifts),
      );
      state.weeklySchedule.data.shifts = shifts;
      state.weeklySchedule.data.schedule = schedule;
      state.weeklySchedule.data.wage_factors = wage_factors;
      state.publishSchedule.data.count = utils.weeklySchedule.getUnPublishedShiftsCount(schedule);
      state.weeklySchedule.isLoading = false;
      state.weeklySchedule.data.closingTime = shifts.reduce((accr, day) => {
        const time = utils.shift.getClosingTime(day.shifts);
        return { ...accr, [day.weekday]: time };
      }, {});
    },
    fetchWeeklyScheduleError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.weeklySchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.weeklySchedule.error = action.payload;
      } else {
        state.weeklySchedule.response = action.payload;
      }
    },

    setWeeklySchedulesDate: (state, action: PayloadAction<{ date: string; branchId: number }>) => {
      state.weeklySchedule.data.date[action.payload.branchId] = action.payload.date;
    },

    publishScheduleStarted: (state) => {
      state.publishSchedule = {
        ...initialState.publishSchedule,
        data: { numberOfEmployeesNotified: 0, count: state.publishSchedule.data.count },
        isLoading: true,
      };
    },
    publishScheduleError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.publishSchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.publishSchedule.error = action.payload;
      } else {
        state.publishSchedule.response = action.payload;
      }
    },
    publishScheduleSuccess: (
      state,
      action: PayloadAction<{ branchId: number; response: ResponseSuccess<boolean> }>,
    ) => {
      const numberOfEmployeesNotified = utils.weeklySchedule.publishSchedules(
        state,
        action.payload.branchId,
      );
      state.publishSchedule.data.numberOfEmployeesNotified = numberOfEmployeesNotified;
      state.publishSchedule.data.count = 0;
      state.publishSchedule.isLoading = false;
      state.publishSchedule.response = action.payload.response;
    },

    fetchPreviousWeeklyScheduleStarted: (
      state,
      action: PayloadAction<{ branchId: number; date: string }>,
    ) => {
      state.previousWeekSchedule = {
        ...initialState.previousWeekSchedule,
        isLoading: true,
        data: { branchId: action.payload.branchId, date: action.payload.date, schedule: [] },
      };
    },
    fetchPreviousWeeklyScheduleSucces: (state, action: PayloadAction<WeeklyScheduleResponse>) => {
      state.previousWeekSchedule.isLoading = false;
      state.previousWeekSchedule.data.schedule = utils.weeklySchedule.getPrevScheduleData(
        action.payload.accounts,
        state.previousWeekSchedule.data.branchId,
      );
    },
    fetchPreviousWeeklyScheduleError: (
      state,
      action: PayloadAction<ResponseError | RequestError>,
    ) => {
      state.previousWeekSchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.previousWeekSchedule.error = action.payload;
      } else {
        state.previousWeekSchedule.response = action.payload;
      }
    },

    fetchCopyFromScheduleStarted: (state) => {
      state.copyFrom = {
        ...initialState.copyFrom,
        isLoading: true,
        data: [],
      };
    },
    fetchCopyFromScheduleSucces: (state, action: PayloadAction<WeeklyScheduleResponse>) => {
      state.copyFrom.isLoading = false;
      state.copyFrom.data = utils.weeklySchedule.getCopyFromData(state, action.payload);
    },
    fetchCopyFromScheduleError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.copyFrom.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.copyFrom.error = action.payload;
      } else {
        state.copyFrom.response = action.payload;
      }
    },

    addCopyFromScheduleStarted: (state, action: PayloadAction<AddScheduleRequestData[]>) => {
      state.addCopyFromSchedules = {
        ...initialState.addCopyFromSchedules,
        isLoading: true,
        data: action.payload,
      };
    },
    addCopyFromScheduleSucces: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<{ schedule_ids: number[] }>;
        branchId: number;
        branchName: string;
        data: AddScheduleRequestData[];
      }>,
    ) => {
      state.addCopyFromSchedules.isLoading = false;
      state.addCopyFromSchedules.response = action.payload.response;
      utils.weeklySchedule.addMultiSchedules(
        state,
        action.payload.branchId,
        action.payload.branchName,
        action.payload.data,
        action.payload.response.result.schedule_ids,
      );
      state.copyFrom.data = [];
      utils.weeklySchedule.clearSelectedCopyFromData(state);
    },
    addCopyFromScheduleError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.addCopyFromSchedules.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addCopyFromSchedules.error = action.payload;
      } else {
        state.addCopyFromSchedules.response = action.payload;
      }
    },

    fetchWeeklySalesStarted: (state) => {
      state.sales = { ...initialState.sales, isLoading: true };
    },
    fetchWeeklySalesSuccess: (state, action: PayloadAction<ResponseSuccess<Sales>>) => {
      state.sales.isLoading = false;
      state.sales.data = action.payload.result;
    },
    fetchWeeklySalesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.sales.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.sales.error = action.payload;
      } else {
        state.sales.response = action.payload;
      }
    },

    updateWeeklySalesStarted: (state, action: PayloadAction<UpdateWeeklySalesInputTypeData>) => {
      state.updateSales = { ...initialState.updateSales, isLoading: true, data: action.payload };
    },
    updateWeeklySalesSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>;
        data: UpdateWeeklySalesInputTypeData;
      }>,
    ) => {
      state.updateSales.isLoading = false;
      utils.weeklySchedule.updateWeeklySales(state, action.payload.data);
      state.updateSales.response = action.payload.response;
    },
    updateWeeklySalesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.updateSales.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateSales.error = action.payload;
      } else {
        state.updateSales.response = action.payload;
      }
    },

    addSchedule: (
      state,
      action: PayloadAction<{
        branchId: number;
        schedule: AddScheduleRequestData;
        scheduleId: number;
        roleId: number;
      }>,
    ) => {
      utils.weeklySchedule.addSchedule(
        state,
        action.payload.branchId,
        action.payload.roleId,
        action.payload.schedule,
        action.payload.scheduleId,
      );
      state.publishSchedule.data.count += 1;
    },
    updateSchedule: (
      state,
      action: PayloadAction<{
        schedule: UpdateScheduleRequestData;
        roleId: number;
        isCopyFrom: boolean;
      }>,
    ) => {
      utils.weeklySchedule.updateSchedule(
        state,
        action.payload.schedule,
        action.payload.roleId,
        action.payload.isCopyFrom,
      );
    },
    deleteSchedule: (
      state,
      action: PayloadAction<{
        scheduleId: number;
        accountId: number;
        roleId: number;
        date: string;
        isPublished: 1 | 0;
        isCopyFrom: boolean;
      }>,
    ) => {
      utils.weeklySchedule.deleteSchedule(
        state,
        action.payload.scheduleId,
        action.payload.accountId,
        action.payload.roleId,
        action.payload.date,
        action.payload.isCopyFrom,
      );
      if (!action.payload.isPublished) state.publishSchedule.data.count -= 1;
    },

    fetchCompanyEmployeesStarted: (state) => {
      state.companyEmployees = { ...initialState.companyEmployees, isLoading: true };
    },
    fetchCompanyEmployeesSuccess: (
      state,
      action: PayloadAction<{ date: string; data: CompanyEmployeesResponse }>,
    ) => {
      state.companyEmployees.isLoading = false;
      state.companyEmployees.data.branches = utils.weeklySchedule.groupCompanyEmployeesByRoles(
        action.payload.data,
      );
      state.companyEmployees.data.date = action.payload.date;
    },
    fetchCompanyEmployeesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.companyEmployees.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.companyEmployees.error = action.payload;
      } else {
        state.companyEmployees.response = action.payload;
      }
    },

    addTemporaryEmployeeStarted: (
      state,
      action: PayloadAction<{ accountId: number; roleId: number }>,
    ) => {
      state.addTemporaryEmployee = {
        ...initialState.addTemporaryEmployee,
        isLoading: true,
        data: action.payload,
      };
    },
    addTemporaryEmployeeSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>;
        newRole: TemporaryEmployeeRole;
        employeeId: number;
        newBranchId: number;
        employeeBranchId: number;
        employeeRoleId: number;
      }>,
    ) => {
      state.addTemporaryEmployee.isLoading = false;
      utils.weeklySchedule.addTemporaryEmployee(
        state,
        action.payload.employeeBranchId,
        action.payload.employeeRoleId,
        action.payload.newBranchId,
        action.payload.newRole,
        action.payload.employeeId,
      );
      state.addTemporaryEmployee.response = action.payload.response;
    },
    addTemporaryEmployeeError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.addTemporaryEmployee.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addTemporaryEmployee.error = action.payload;
      } else {
        state.addTemporaryEmployee.response = action.payload;
      }
    },

    selectSchedule: (state, action: PayloadAction<SelectedSchedule>) => {
      if (!state.selectedSchedules.find((sched) => sched.id === action.payload.id)) {
        state.selectedSchedules = [...state.selectedSchedules, { ...action.payload }];
      }
    },

    deselectSchedule: (state, action: PayloadAction<number>) => {
      state.selectedSchedules = state.selectedSchedules.filter(
        (sched) => sched.id !== action.payload,
      );
    },

    clearSelectedSchedules: (state) => {
      state.selectedSchedules = [];
    },

    deleteSelectedSchedulesStarted: (state) => {
      state.deleteSelectedSchedules = {
        ...initialState.deleteSelectedSchedules,
        isLoading: true,
      };
    },
    deleteSelectedSchedulesSuccess: (state, action: PayloadAction<ResponseSuccess<boolean>>) => {
      state.deleteSelectedSchedules.isLoading = false;
      state.deleteSelectedSchedules.response = action.payload;
      utils.weeklySchedule.deleteSelectedSchedules(state);
    },
    deleteSelectedSchedulesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.deleteSelectedSchedules.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deleteSelectedSchedules.error = action.payload;
      } else {
        state.deleteSelectedSchedules.response = action.payload;
      }
    },

    updateSelectedSchedulesStarted: (state) => {
      state.updateSelectedSchedules = {
        ...initialState.updateSelectedSchedules,
        isLoading: true,
      };
    },
    updateSelectedSchedulesSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>;
        data: UpdateSelectedSchedulesData;
      }>,
    ) => {
      state.updateSelectedSchedules.isLoading = false;
      state.updateSelectedSchedules.response = action.payload.response;
      utils.weeklySchedule.updateSelectedSchedules(state, action.payload.data);
    },
    updateSelectedSchedulesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.updateSelectedSchedules.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateSelectedSchedules.error = action.payload;
      } else {
        state.updateSelectedSchedules.response = action.payload;
      }
    },

    checkForTimeConflictInSelectedSchedules: (
      state,
      action: PayloadAction<UpdateSelectedSchedulesData>,
    ) => {
      utils.weeklySchedule.checkForTimeConflictsInSelectedSchedules(state, action.payload);
    },

    hideSelectedConflictingSchedules: (state, action: PayloadAction<boolean>) => {
      state.selectedSchedules = state.selectedSchedules.map((schedule) => ({
        ...schedule,
        ...(schedule.isConflict && action.payload ? { show: false } : { show: true }),
      }));
    },

    resetSelectedConflictingSchedules: (state) => {
      state.selectedSchedules = state.selectedSchedules.map((schedule) => ({
        ...schedule,
        show: true,
        isConflict: false,
      }));
    },

    setQuickAddShift: (state, action: PayloadAction<string>) => {
      state.quickAddShiftName = action.payload;
    },

    setSelectedDefaultShiftRequirement: (
      state,
      action: PayloadAction<SelectedDefaultShiftRequirment | undefined>,
    ) => {
      state.selectedDefaultShifRequirement = action.payload;
    },

    resetUpdateSalesResponse: (state) => {
      state.updateSales = { ...initialState.updateSales };
    },

    resetAddTemporaryEmployee: (state) => {
      state.addTemporaryEmployee = { ...initialState.addTemporaryEmployee };
    },

    resetSelectedSchedulesActions: (state) => {
      state.updateSelectedSchedules = { ...initialState.updateSelectedSchedules };
      state.deleteSelectedSchedules = { ...initialState.deleteSelectedSchedules };
    },

    clearCopyFromSchedule: (state) => {
      state.copyFrom = { ...initialState.copyFrom };
      state.addCopyFromSchedules = { ...initialState.addCopyFromSchedules };
      utils.weeklySchedule.clearSelectedCopyFromData(state);
    },

    setCopyFromFilters: (state, action: PayloadAction<WeeklyScheduleFilters>) => {
      state.copyFromFilters = action.payload;
      utils.weeklySchedule.filterSchedules(state, state.copyFrom.data, action.payload);
    },

    setTableFilters: (state, action: PayloadAction<WeeklyScheduleTableFilter>) => {
      state.filter = action.payload;
    },

    initialiseTableFilters: (state) => {
      state.filter = INITIAL_TABLE_FILTERS;
    },

    updateOffRequest: (state, action: PayloadAction<UpdateOffRequest>) => {
      utils.weeklySchedule.updateOffRequest(state, action.payload);
    },

    clearSlices: (state) => {
      state.numberOfSchedulesAddedFromPreviousSchedule = 0;
      state.numberOfSchedulesRemovedUsingForm = 0;
      state.publishSchedule = { ...initialState.publishSchedule };
      state.quickAddShiftName = '';
      state.latestUpdatedScheduleValues = { ...initialState.latestUpdatedScheduleValues };
      state.showMultiEditSchedulesDemo = false;
      state.weeklySchedule = {
        ...initialState.weeklySchedule,
        isLoading: false,
        data: {
          closingTime: {},
          wage_factors: [],
          schedule: [],
          shifts: [],
          date: state.weeklySchedule.data?.date,
        },
      };
      state.addCopyFromSchedules = { ...initialState.addCopyFromSchedules };
      state.addTemporaryEmployee = { ...initialState.addTemporaryEmployee };
      state.companyEmployees = { ...initialState.companyEmployees };
      state.copyFrom = { ...initialState.copyFrom };
      state.copyFromFilters = { ...initialState.copyFromFilters };
      state.deleteSelectedSchedules = { ...initialState.deleteSelectedSchedules };
      state.previousWeekSchedule = { ...initialState.previousWeekSchedule };
      state.sales = { ...initialState.sales };
      state.selectedDefaultShifRequirement = initialState.selectedDefaultShifRequirement;
      state.selectedSchedules = [];
      state.updateSales = { ...initialState.updateSales };
      state.updateSelectedSchedules = { ...initialState.updateSelectedSchedules };
      state.filter = INITIAL_TABLE_FILTERS;
    },
  },
});

export default weeklySchedule.reducer;

export const { actions } = weeklySchedule;

export const selectFetchWeeklySchedule = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.weeklySchedule;

export const selectFetchPreviousWeeklySchedule = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.previousWeekSchedule;

export const selectFetchWeeklySales = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.sales;

export const selectUpdateWeeklySales = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.updateSales;

export const selectCompanyEmployees = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.companyEmployees;

export const selectAddTemporaryEmployee = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.addTemporaryEmployee;

export const selectSelectedSchedulesState = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.selectedSchedules;

export const selectUpdateSelectedSchedulesState = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.updateSelectedSchedules;

export const selectDeleteSelectedSchedulesState = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.deleteSelectedSchedules;

export const selectQuickAddShiftName = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.quickAddShiftName;

export const selectSelectedDefaultShiftRequirement = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.selectedDefaultShifRequirement;

export const selectCopyFromSchedule = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.copyFrom;

export const selectAddCopyFromSchedule = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.addCopyFromSchedules;

export const selectNumberOfSchedulesAddedFromPreviousSchedule = (
  state: State<WeeklyScheduleInitialState>,
) => state.weeklyScheduleSlice.numberOfSchedulesAddedFromPreviousSchedule;

export const selectNumberOfSchedulesRemovedUsingForm = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.numberOfSchedulesRemovedUsingForm;

export const selectShowMultiEditSchedulesDemo = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.showMultiEditSchedulesDemo;

export const selectPublishSchedulesState = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.publishSchedule;

export const selectWeeklyScheduleTableFiters = (state: State<WeeklyScheduleInitialState>) =>
  state.weeklyScheduleSlice.filter;
