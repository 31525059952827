import { SvgUntitledIconProps } from '../types/Shared';

interface CheckCircleProps extends SvgUntitledIconProps {}

export default function CheckCircle(props: CheckCircleProps) {
  const {
    height = '24',
    width = '24',
    stroke = 'currentColor',
    strokeWidth = '2',
    fill = 'none',
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
    >
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
