import { Components, Theme } from '@mui/material';
import themeTypography from '../themeTypography';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: 0,
        position: 'absolute',
        bottom: '-20px',
        width: '100%',
        left: '0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...themeTypography.text.sm.regular,
      },
    },
  },
};

export default components.MuiFormHelperText;
