import { Components, Theme } from '@mui/material';
import themeTypography from '../themeTypography';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiInputBase: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        '*::placeholder': {
          ...themeTypography.text.md.regular,
        },

        padding: 0,

        ...themeTypography.text.md.regular,

        '& textarea': {
          padding: 0,
        },

        '&.MuiInputBase-multiline': {
          padding: 0,
        },

        '& input, & textarea': {
          height: 'auto',
          border: 'none',

          ...(ownerState.size === 'small' && {
            padding: '8px 12px',
          }),

          ...(ownerState.size === 'medium' && {
            padding: '10px 14px',
          }),
        },
      }),
    },
  },
};

export default components.MuiInputBase;
