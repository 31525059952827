import { FC } from 'react';
import { SvgUntitledIconProps } from '../types/Shared';

interface XCloseProps extends SvgUntitledIconProps {
  pathProps?: {
    d?: string;
  };
}

const XClose: FC<XCloseProps> = function XClose(props: XCloseProps) {
  const {
    height = '24',
    width = '24',
    stroke = 'currentColor',
    strokeWidth = '2',
    fill = 'none',
    pathProps,
    className = '',
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      className={className}
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
    </svg>
  );
};

XClose.defaultProps = {
  pathProps: undefined,
};

export default XClose;
