import { FC, memo, useMemo } from 'react';
import utils from '../../shared/utils';
import './Time.scss';

interface TimeProps {
  time: string;
  hours12: boolean;
  shorten?: boolean;
  className?: string;
  endNextDay?: boolean;
}

const Time: FC<TimeProps> = memo(function Time(props: TimeProps) {
  const { time, hours12, shorten, className, endNextDay } = props;

  const convertedTime = useMemo(
    () => utils.date.getTime(time, hours12, !!shorten),
    [hours12, shorten, time],
  );

  return (
    <span className={`time-cont ${className || ''}`}>
      {convertedTime.time}
      {convertedTime.meridiem && <span className="meridiem">{convertedTime.meridiem}</span>}
      {endNextDay && <span className="end-next-day">{` `}+1</span>}
    </span>
  );
});

Time.defaultProps = {
  className: '',
  shorten: false,
  endNextDay: false,
};

export default Time;
