import { Components, Theme } from '@mui/material';
import colors from '../themeColorPalette';
import untitledTypography from '../themeTypography';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        transform: 'none',
        transition: 'none',
        textTransform: 'lowercase',
        '::first-letter': {
          textTransform: 'capitalize',
        },
        position: 'relative',
        wordWrap: 'break-word',
        ...untitledTypography.text.sm.medium,
        color: colors.grey[700],

        '&.Mui-error, &.Mui-focused': {
          color: colors.grey[700],
        },
      },
    },
  },
};

export default components.MuiInputLabel;
