import { createTheme } from '@mui/material';
import components from './Components';
import themePalette from './themePalette';
import breakpoints from './themeBreakPoinst';

const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightLight: 300,
  },
  palette: themePalette,
  spacing: 4,
  breakpoints,
  components,
});

export default theme;
