import { SvgUntitledIconProps } from '../types/Shared';

interface ChevronLeftDoubleProps extends SvgUntitledIconProps {}

export default function ChevronLeftDouble(props: ChevronLeftDoubleProps) {
  const {
    height = '24',
    width = '24',
    stroke = 'currentColor',
    strokeWidth = '2',
    fill = 'none',
    className,
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      className={className}
    >
      <path
        d="M18 17L13 12L18 7M11 17L6 12L11 7"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
