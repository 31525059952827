import { styled, Theme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { CSSObject } from '@emotion/react';
import IconButton from '@mui/material/IconButton/IconButton';
import MuiDrawer from '@mui/material/Drawer';
import { useMemo } from 'react';
import NavLinks from '../Routes/NavLinks';
import { Config } from '../../config';
import SchedexLogo from '../../components/SchedexLogo/SchedexLogo';
import User from '../../components/User/User';
import LINKS from '../Routes/NavLinksConfig';
import NavItem from '../Routes/NavItem';
import ChevronLeftDouble from '../../icons/ChevronLeftDouble';
import ChevronRightDouble from '../../icons/ChevronRightDouble';

import './AppDrawer.scss';
import TouchTooltip from '../../components/TouchTooltip/TouchTooltip';

type AppDrawerProps = {
  open: boolean;
  toggleDrawer: () => void;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: Config.DRAWER_WIDTH.OPEN,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: Config.DRAWER_WIDTH.CLOSED,
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: Config.DRAWER_WIDTH.OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function AppDrawer(props: AppDrawerProps) {
  const { open, toggleDrawer } = props;
  const location = useLocation();

  const COLLAPSE_BUTTON = useMemo(
    () => ({
      id: 'Collapse',
      label: 'Collapse',
      Icon: open ? ChevronLeftDouble : ChevronRightDouble,
      requiredFeatures: [],
    }),
    [open],
  );

  return (
    <Drawer
      variant="permanent"
      open={open}
      className={`untitled-drawer untitled-drawer-${open ? 'open' : 'closed'}`}
    >
      <div className="untitled-navbar-top">
        <div className="untitled-navbar-header">
          <IconButton onClick={toggleDrawer} className="schedex-button">
            <SchedexLogo showText={open} />
            <TouchTooltip title="Collapse" placement="right">
              <span className="collapse-icon-container">
                <ChevronLeftDouble className="collapse-icon" />
              </span>
            </TouchTooltip>
          </IconButton>
        </div>
        <NavLinks open={open} />
      </div>
      <div className="untitled-navbar-footer untitled-navigation">
        <div className="untitled-navbar-footer-navigation">
          <NavItem
            link={COLLAPSE_BUTTON}
            open={open}
            key="collapse"
            pathname={location.pathname}
            onClick={toggleDrawer}
          />
          {LINKS.lower.map((link) => (
            <NavItem link={link} open={open} pathname={location.pathname} key={link.id} />
          ))}
        </div>
        <User open={open} />
      </div>
    </Drawer>
  );
}

export default AppDrawer;
