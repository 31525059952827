import { FC, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import { SvgUntitledIconProps } from '../../../types/Shared';
import XClose from '../../../icons/XClose';
import DialogIcon from './DialogIcon';
import EllipsisText from '../../Controls/EllipsisText/EllipsisText';

import './index.scss';

interface UntitledDialogHeaderProps extends DialogTitleProps {
  children?: ReactNode;
  onClose: () => void;
  title?: string;
  subTitle?: string | ReactNode;
  subTitleRef?: React.MutableRefObject<HTMLDivElement | null>;
  hideIcon?: boolean;
  Icon?: React.ElementType<SvgUntitledIconProps> | null;
  theme: 'modern' | 'light-color-outline';
  color?: 'success' | 'warning' | 'error';
  type?: 'left-aligned' | 'center-aligned' | 'horizontal-left-aligned';
  ellipsis?: number;
}

const UntitledDialogHeader: FC<UntitledDialogHeaderProps> = function MuiDialogTitle(
  props: UntitledDialogHeaderProps,
) {
  const {
    onClose,
    title,
    subTitle,
    subTitleRef,
    hideIcon,
    Icon,
    theme: iconTheme,
    color,
    type,
    children,
    ellipsis,
    ...other
  } = props;

  return (
    <DialogTitle
      sx={{
        pl: 0,
        pb: 0,
        pr: 0,
        pt: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {' '}
      <div className={`dialog-header ${type}`}>
        <div className="content">
          {!hideIcon && <DialogIcon Icon={Icon ?? null} theme={iconTheme} color={color} />}
          <div className="dialog-content-container">
            <div className="dialog-title-container">
              <div className="dialog-title">
                <EllipsisText text={title ?? ''} length={ellipsis || 30} />
              </div>
              {(!!subTitle || !!subTitleRef) && (
                <div className="dialog-subTitle" ref={subTitleRef}>
                  {subTitle}
                </div>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className="untitled-dialog-title-close-button"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <XClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

UntitledDialogHeader.defaultProps = {
  children: null,
  subTitleRef: undefined,
  Icon: null,
  subTitle: '',
  title: '',
  color: undefined,
  type: 'left-aligned',
  hideIcon: false,
  ellipsis: 30,
};

export default UntitledDialogHeader;
