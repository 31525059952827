import { FC } from 'react';
import { SvgUntitledIconProps } from '../types/Shared';

interface ChevronDownProps extends SvgUntitledIconProps {
  pathProps?: {
    d?: string;
  };
}
const ChevronDown: FC<ChevronDownProps> = function ChevronDown(props: ChevronDownProps) {
  const {
    height = '24',
    width = '24',
    stroke = 'currentColor',
    strokeWidth = '2',
    fill = 'none',
    pathProps,
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
    </svg>
  );
};

ChevronDown.defaultProps = {
  pathProps: undefined,
};

export default ChevronDown;
