import { CurrencyId } from '../enums';

export default {
  DAY_FORMAT: 'YYYY-MM-DD',
  EXCEl_DATE_FORMAT: 'ddd DD/MM/YYYY',
  EXCEl_FILE_NAME_DATE_FORMAT: 'DD-MM-YYYY',
  UI_DAY_FORMAT: 'ddd DD/MM',
  PING_GOOGLE_TIMEOUT: 5000,
  RES_STATUS: {
    SUCCESS: 'success',
    FAIL: 'failure',
  },
  NODE_ENV: {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
  },
  SHIFT: {
    DAY: 'DAY',
    NIGHT: 'NIGHT',
  },
  ACTION_STATUS: {
    DELETE: 'DELETE',
    ADD: 'ADD',
    EDIT: 'SAVE',
    IMPORT: 'IMPORT',
    NOTIFY: 'Notify',
    APPROVE: 'Approve',
  },
  SELECTED_SHIFTS_ACTION_STATUS: {
    REMOVE: 'REMOVE',
    EDIT_TIME: 'EDIT_TIME',
    EDIT_NOTE: 'EDIT_NOTE',
  },
  SELECTED_DEFAULT_SHIFTS_ACTION_STATUS: {
    REMOVE: 'REMOVE',
    EDIT_TIME: 'EDIT_TIME',
    EDIT_REQUIREMENT: 'EDIT_REQUIREMENT',
  },
  WEEK_NAMES: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  WEEK_DAYS: { SUN: 0, MON: 1, TUE: 2, WED: 3, THU: 4, FRI: 5, SAT: 6 },
  WEEK: [
    {
      value: 'MON',
      id: 'weekday_1',
    },
    {
      value: 'TUE',
      id: 'weekday_2',
    },
    {
      value: 'WED',
      id: 'weekday_3',
    },
    {
      value: 'THU',
      id: 'weekday_4',
    },
    {
      value: 'FRI',
      id: 'weekday_5',
    },
    {
      value: 'SAT',
      id: 'weekday_6',
    },
    {
      value: 'SUN',
      id: 'weekday_0',
    },
  ],
  TIME_MERIDIEM: [
    {
      label: 'am',
      value: 'AM',
    },
    {
      label: 'pm',
      value: 'PM',
    },
  ],
  REGEXP: {
    TIME: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
    MASK_TIME: /^([01][0-9]?|2[0-3]?)(:([0-5][0-9]?)?)?$/,

    TIME_24: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
    FULL_TIME_24: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
    MASK_TIME_24: /^([01][0-9]?|2[0-3]?)(:([0-5][0-9]?)?)?$/,

    TIME_12: /^([0]?[0-9]|1[0-1]):[0-5][0-9]$/,
    MASK_TIME_12: /^([0][1-9]?|1[0-2]?)(:([0-5][0-9]?)?)?$/,

    PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/,
  },
  MASK: {
    PHONE_NUMBER: '( XX ) XX - XX - XX',
    TIME: 'XX : XX',
  },
  TABLE_COLUMN_TYPE: {
    OFFREQUEST: 'REQUESTED_OFF',
    UNAVAILABILITIES: 'UNAVAILABILITIES',
    PREFERENCES: 'PREFERENCES',
  },
  HOUR_FORMAT: [
    {
      label: '12 hours format',
      value: 1,
    },
    {
      label: '24 hours format',
      value: 0,
    },
  ],
  CURRENCY_IDS: [
    {
      id: CurrencyId.USD_DOLLAR,
      symbol: '$',
    },
    {
      id: CurrencyId.LBP_LEBANESE_LIRA,
      symbol: 'L.L.',
    },
    {
      id: CurrencyId.AED,
      symbol: 'DH',
    },
    {
      id: CurrencyId.EURO,
      symbol: '€',
    },
    {
      id: CurrencyId.EGP,
      symbol: 'EGP',
    },
    {
      id: CurrencyId.SGD,
      symbol: 'S$',
    },
  ],
  SALES_PROJECTION: [
    {
      label: 'Copy from last week',
      value: 1,
    },
    {
      label: 'Compute the total sales of the last week',
      value: 2,
    },
  ],
  CURRENCY: [
    {
      label: 'USD ($)',
      value: CurrencyId.USD_DOLLAR,
    },
    {
      label: 'LBP (L.L.)',
      value: CurrencyId.LBP_LEBANESE_LIRA,
    },
    {
      label: 'AED (DH)',
      value: CurrencyId.AED,
    },
    {
      label: 'EUR (€)',
      value: CurrencyId.EURO,
    },
    {
      label: 'EGP (EGP)',
      value: CurrencyId.EGP,
    },
    {
      label: 'SGD (S$)',
      value: CurrencyId.SGD,
    },
  ],
  ROLE_ORDER_MAYRIG: [
    'Floor Manager',
    'Shift Leader',
    'Captain Waiter',
    'Waiter',
    'Kitchen Chef',
    'Assistant Kitchen Chef',
    'Senior Cook',
    'Cook',
    'Comis Cook',
  ],
  FORM: {
    EDIT_MULTI_SCHEDULE_TIME: 'EDIT_MULTI_SCHEDULE_TIME',
    EDIT_MULTI_SCHEDULE_NOTE: 'EDIT_MULTI_SCHEDULE_NOTE',
    PUNCHING_FORM: 'PUNCHING_FORM',
    ACTUAL_SALES_FORM: 'ACTUAL_SALES_FORM',
    ADD_SALES_TYPES: 'ADD_SALES_TYPES',
    PROJECTED_SALES_COMPUTATION: 'PROJECTED_SALES_COMPUTATION',
    SALES_PERCENTAGE_CAP: 'SALES_PERCENTAGE_CAP',
    ADD_EMPLOYEE_FORM: 'ADD_EMPLOYEE_FORM',
    EDIT_EMPLOYEE_FORM: 'EDIT_EMPLOYEE_FORM',
    IMPORT_EMPLOYEE_FORM: 'IMPORT_EMPLOYEE_FORM',
    ADD_TEMPORARY_EMPLOYEE_FORM: 'ADD_TEMPORARY_EMPLOYEE_FORM',
    CREATE_PASSWORD_FORM: 'CREATE_PASSWORD_FORM',
  },
  REPORT_SHIFT_ERRORS: {
    EARLY_PUNCHIN_ERROR: {
      ID: 0,
      NAME: 'Early Punch in',
    },
    LATE_PUNCHIN_ERROR: {
      ID: 1,
      NAME: 'Late Punch in',
    },
    EARLY_PUNCHOUT_ERROR: {
      ID: 2,
      NAME: 'Early Punch out',
    },
    LATE_PUNCHOUT_ERROR: {
      ID: 3,
      NAME: 'Late Punch out',
    },
    MISSING_PUNCHOUT_ERROR: {
      ID: 4,
      NAME: 'Missing Punch out',
    },
    NO_SHOW_ERROR: {
      ID: 5,
      NAME: 'No show',
    },
    PUNCH_WITH_NO_SHIFT_ERROR: {
      ID: 6,
      NAME: 'Punch with no shift',
    },
  },
  REPORT_COLUMNS: {
    PUNCHING_FEATURE_COLUMNS: {
      IN: 'IN',
      OUT: 'OUT',
      PUNCH_IN_LOCATION: 'IN Location',
      PUNCH_OUT_LOCATION: 'OUT Location',
      TOTAL_PUNCHING: 'Total (P)',
      SUM_PUNCHING: 'Sum (P)',
    },
    ID: 'ID',
    POSITION: 'Position',
    EMPLOYEE: 'Employee',
    BRANCH: 'Branch',
    DATE: 'Date',
    SHIFT: 'Shift',
    START_TIME: 'Start time',
    END_TIME: 'End time',
    WAGE: 'Wage',
    TOTAL: 'Total',
    SUM: 'Sum',
    REGULAR_SCHEDULE_HOURS: 'Regular hours',
    OVERTIME_SCHEDULE_HOURS: 'Overtime hours',
    HOLIDAY_SCHEDULE_HOURS: 'Holiday hours',
    REGULAR_SCHEDULE_PAYMENT: 'Regular payment',
    OVERTIME_SCHEDULE_PAYMENT: 'Overtime payment',
    HOLIDAY_SCHEDULE_PAYMENT: 'Holiday payment',
    REGULAR_PUNCH_HOURS: 'Regular hours (p)',
    OVERTIME_PUNCH_HOURS: 'Overtime hours (p)',
    HOLIDAY_PUNCH_HOURS: 'Holiday hours (p)',
    REGULAR_PUNCH_PAYMENT: 'Regular payment (p)',
    OVERTIME_PUNCH_PAYMENT: 'Overtime payment (p)',
    HOLIDAY_PUNCH_PAYMENT: 'Holiday payment (p)',
    ANNUAL_LEAVE: 'A/L',
  },
  SCHEDULE_PAYROLL_AND_SALES_FIELDS: [
    { name: 'projected sales', label: 'Projected Sales', id: 1 },
    { name: 'projected payroll', label: 'Schedule Payroll', id: 2 },
    { name: 'projected sales percentage', label: 'SP/Projected Sales %', id: 3 },
    { name: 'actual sales', label: 'Actual Sales', id: 4 },
    { name: 'actual sales percentage', label: 'SP/Actual Sales %', id: 5 },
  ],
  CUSTOM_EVENT: {
    INTERNET_CONNECTION_CHANGE: 'on-internet-connection-change',
  },
  LOGOUT_ACTION: {
    type: 'user/logout',
  },
};
