import dayjs from 'dayjs';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import MoreTime from '@mui/icons-material/MoreTime';
import LessTimeIcon from '../../../components/LessTimeIcon/LessTimeIcon';
import { Time } from '../../../components';
import { MissingPunchPair } from './punches';
import { Punch } from '../../../api/reporting';
import dateUtils, { ReportTimeFormat } from '../date';
import general from '../general';
import { Variance } from '../../../modules/Reporting/types/ReportTable';
import { PaymentType } from '../../../enums';
import { texts } from '../../../resources';
import { BranchSettings } from '../../../types/Authentications';
import payments, { PAYMENTS } from '../../../constants/PaymentTypes';
import TouchTooltip from '../../../components/TouchTooltip/TouchTooltip';

const TIME_FORMAT = 'HH:mm:ss';
const SHORT_TIME_FORMAT = 'HH:mm';
const TIME_LENGTH = TIME_FORMAT.length;
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const today = dateUtils.now(false, 'YYYY-MM-DD');

export const getTimeValue = (time: string, ishour12: boolean, className: string) => {
  const timeFormat = time.length < TIME_LENGTH ? SHORT_TIME_FORMAT : TIME_FORMAT;

  return dateUtils.getDate(`${today} ${time}`, `YYYY-MM-DD ${timeFormat}`).isValid() ? (
    <Time className={className} time={time} hours12={ishour12} />
  ) : (
    <span className={className}>{time}</span>
  );
};

type TotalCellValueType = 'Money' | 'Time';
type OverTimeAndHolidayType<T extends TotalCellValueType> = T extends 'Money'
  ? { currency: string }
  : ReportTimeFormat;

export function getOverTimeAndHolidays<T extends TotalCellValueType>(
  total:
    | number
    | {
        [key: string]: {
          value: number;
        };
      },
  holiday: number,
  overtime: number,
  regular: number,
  type: T,
  args: OverTimeAndHolidayType<T>,
) {
  const result = [];
  if (total !== regular && overtime + holiday !== 0) {
    if (regular)
      result.push({
        label: 'Regular',
        value:
          type === 'Money'
            ? general.formatCurrencyNumber(regular, (args as { currency: string }).currency)
            : dateUtils.getTimeFormat(regular, args as ReportTimeFormat),
      });
    if (holiday)
      result.push({
        label: 'Holiday',
        value:
          type === 'Money'
            ? general.formatCurrencyNumber(holiday, (args as { currency: string }).currency)
            : dateUtils.getTimeFormat(holiday, args as ReportTimeFormat),
      });
    if (overtime)
      result.push({
        label: 'Overtime',
        value:
          type === 'Money'
            ? general.formatCurrencyNumber(overtime, (args as { currency: string }).currency)
            : dateUtils.getTimeFormat(overtime, args as ReportTimeFormat),
      });
  }
  return result;
}

export function getTotalInfoIcon<T extends TotalCellValueType>(
  total:
    | number
    | {
        [key: string]: {
          value: number;
        };
      },
  holiday: number,
  overtime: number,
  regular: number,
  type: T,
  args: OverTimeAndHolidayType<T>,
) {
  const result = getOverTimeAndHolidays(total, holiday, overtime, regular, type, args);

  if (result.length) {
    return (
      <TouchTooltip
        arrow
        placement="top"
        title={
          <div className="title">
            {result.map((item) => (
              <div style={{ padding: '2px' }} key={item.label}>
                {item.label}: {` `}
                <b>{item.value}</b>
              </div>
            ))}
          </div>
        }
      >
        <InfoOutlined className="total-info-icon" />
      </TouchTooltip>
    );
  }
  return null;
}

export const getPunchDuration = (punchInTime: string, punchOutTime: string) =>
  dayjs(punchOutTime, DATE_TIME_FORMAT).diff(dayjs(punchInTime, DATE_TIME_FORMAT), 'second');

export const getTentativePunchesTime = (tentativePunches: MissingPunchPair[], punches: Punch[]) => {
  let result = 0;
  tentativePunches?.forEach((punch) => {
    if (punch?.out) {
      const punchOutTime = punch.out?.value;
      const punchInTime =
        punch.in?.value || // when the punch in is a tentative shift
        punches[(punches?.length || 0) - 1]?.in.date || // get last punch in time from punches array
        punch.out.value; // in case of no punch out get the last punch in time from tentative punches data [calculating the punches time in summary rows]
      if (punchInTime && punchOutTime) result += getPunchDuration(punchInTime, punchOutTime);
    }
  });
  return result;
};

export const getTotalTentativePunchesTime = (
  tentativePunches: {
    [date: string]: {
      [branchId: string]: {
        [positionId: string]: MissingPunchPair[];
      };
    };
  },
  punches: Punch[],
) => {
  const dates = Object.keys(tentativePunches || {});
  return dates.reduce((total: number, date: string) => {
    const branches = Object.keys(tentativePunches[date] || {});
    const newTotal: number =
      total +
      branches.reduce((prevBranchTotal: number, branchId: string) => {
        const positions = Object.keys(tentativePunches[date]?.[branchId] || {});

        return (
          prevBranchTotal +
          positions.reduce((prev: number, positionId: string) => {
            const tentativePunchList = tentativePunches[date]?.[branchId]?.[positionId] || [];

            return prev + getTentativePunchesTime(tentativePunchList, punches);
          }, 0)
        );
      }, 0);
    return newTotal;
  }, 0);
};

export const getSummaryTentativePunchesTime = (
  tentativePunches: {
    [date: string]: {
      [branchId: string]: {
        [positionId: string]: MissingPunchPair[];
      };
    };
  },
  branchId: number,
  positionId: number,
  punches: Punch[],
) => {
  const dates = Object.keys(tentativePunches || {});
  return dates.reduce((prev, date) => {
    const tentativePunchList = tentativePunches[date]?.[branchId]?.[positionId];
    return prev + getTentativePunchesTime(tentativePunchList || [], punches);
  }, 0);
};

type TotalPayment = {
  [key: string]: {
    value: number;
  };
};

export const getTotalCellValue = ({
  total,
  branchSettings,
  isSummaryTotal,
  paymentType: positionPayment,
  isSummary,
}: {
  total: number | TotalPayment;
  branchSettings: BranchSettings;
  isSummaryTotal: boolean;
  paymentType?: PaymentType;
  isSummary: boolean;
}) => {
  if (isSummaryTotal && typeof total !== 'number') {
    return Object.keys(total)
      .map((payment) => {
        const result = [];
        if (+payment === PAYMENTS.HOURLY.id) {
          // per hour
          result.push(
            dateUtils.getTimeFormat(total?.[payment].value, {
              roundToQuarter: branchSettings.report_round_to_quarter === 1,
              displayDecimalTime: branchSettings.report_display_decimal_time === 1,
            }),
          );
        } else {
          result.push(
            `${total[payment].value}${payments?.[+payment]?.abbr?.toLowerCase?.()}${
              total[payment].value > 1 ? 's' : ''
            }`,
          );
        }
        return result;
      })
      .join(', ');
  }
  if (typeof total === 'number' && positionPayment) {
    if (
      positionPayment === PaymentType.HOURLY ||
      (positionPayment === PaymentType.MONTHLY && !isSummary)
    ) {
      // per hour
      return dateUtils.getTimeFormat(total, {
        roundToQuarter: branchSettings.report_round_to_quarter === 1,
        displayDecimalTime: branchSettings.report_display_decimal_time === 1,
      });
    }
    return `${total}${payments?.[positionPayment]?.abbr?.toLowerCase?.()}${total > 1 ? 's' : ''}`;
  }
  return '';
};

const ops = {
  '>=': (a: number, b: number) => a >= b,
  '>': (a: number, b: number) => a > b,
  '<=': (a: number, b: number) => a <= b,
  '<': (a: number, b: number) => a < b,
};

export const shouldHighlightVariance = (value: number, varianceObj: Variance) =>
  ops[varianceObj.leftValueOperator](value, varianceObj.leftValue) ||
  ops[varianceObj.rightValueOperator](value, varianceObj.rightValue);

export function createVarianceDiv(
  varianceVal: number,
  variance: Variance,
  branchSettings: BranchSettings,
) {
  const varianceTime = dateUtils.getTimeFormat(varianceVal, {
    roundToQuarter: branchSettings.report_round_to_quarter === 1,
    displayDecimalTime: branchSettings.report_display_decimal_time === 1,
  });
  if (varianceTime.length) {
    const shouldHighligh = variance.enabled && shouldHighlightVariance(varianceVal, variance);
    return (
      <div key="variance" className="variance-value">
        <i className={`${shouldHighligh ? 'exceed-variance' : ''}`}>
          {varianceVal < 0 ? '-' : '+'}({varianceTime})
        </i>
        {varianceVal < 0 ? (
          <TouchTooltip title={texts.REPORTING.VARIANCE.UNDERTIME} arrow placement="right">
            <span>
              <LessTimeIcon className="variance-icon" />
            </span>
          </TouchTooltip>
        ) : (
          <TouchTooltip title={texts.REPORTING.VARIANCE.OVERTIME} arrow placement="right">
            <MoreTime className="variance-icon" />
          </TouchTooltip>
        )}
      </div>
    );
  }
  return null;
}

export default {
  getTimeValue,
  getTotalInfoIcon,
  getSummaryTentativePunchesTime,
  getTotalTentativePunchesTime,
  getTentativePunchesTime,
  getTotalCellValue,
  createVarianceDiv,
};
