import { FC } from 'react';
import './SchedexLogo.scss';

type SchedexLogoProps = {
  showText?: boolean;
};

const SchedexLogo: FC<SchedexLogoProps> = function SchedexLogo(props: SchedexLogoProps) {
  const { showText } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`schedex-logo ${showText ? 'show-text' : ''}`}
      fill="none"
    >
      <g className="logo-text">
        <path
          d="M39.6078 18.9216C39.7009 20.7845 41.3473 22.151 43.9864 22.151C46.2226 22.151 47.8063 21.1882 47.8063 19.7287C47.8063 17.7412 46.0669 17.5847 43.7073 17.3068C40.1669 16.8715 37.3408 16.1575 37.3408 12.8655C37.3408 9.97758 39.98 8.08318 43.5515 8.11433C47.1542 8.14588 49.7622 9.82224 50.011 13.1143H47.4022C47.2161 11.4994 45.7255 10.2883 43.5515 10.2883C41.3469 10.2883 39.8566 11.2187 39.8566 12.6794C39.8566 14.4189 41.533 14.6361 43.8315 14.9156C47.4318 15.3501 50.3217 16.0333 50.3217 19.5426C50.3217 22.4928 47.4957 24.3249 43.9864 24.3249C39.8873 24.3249 37.0609 22.3994 36.999 18.9216H39.6078Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M53.5198 16.251C53.5198 11.4059 56.6557 8.11432 61.2519 8.11432C65.1353 8.11432 67.5872 10.2882 68.2397 13.7664H65.5698C65.1038 11.6547 63.5508 10.3813 61.222 10.3813C58.1787 10.3813 56.1286 12.804 56.1286 16.251C56.1286 19.6979 58.1787 22.0583 61.222 22.0583C63.4873 22.0583 65.0419 20.7537 65.5382 18.7348H68.2405C67.6191 22.151 65.0419 24.3249 61.1912 24.3249C56.563 24.3249 53.5198 21.1882 53.5198 16.251Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M86.1577 15.3193V24.138H83.612V15.4743C83.612 12.1826 82.0275 10.4436 79.2949 10.4436C76.3443 10.4436 74.5126 12.6487 74.5126 16.2194V24.1384H71.9641V2.40039H74.5126V10.9092C75.0527 10.0189 75.8218 9.28994 76.7397 8.79832C77.6576 8.30671 78.6907 8.07049 79.731 8.11432C83.2698 8.11513 86.1577 10.0706 86.1577 15.3193Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M89.6045 16.2194C89.6045 11.4055 92.6477 8.11432 97.1198 8.11432C101.592 8.11432 104.51 10.9407 104.635 15.6296C104.635 15.9714 104.604 16.3436 104.573 16.7166H92.2752V16.9338C92.3686 19.9463 94.2626 22.0583 97.2751 22.0583C99.5109 22.0583 101.25 20.8779 101.778 18.8282H104.355C103.735 21.9956 101.126 24.3249 97.4612 24.3249C92.7096 24.3249 89.6045 21.064 89.6045 16.2194ZM101.902 14.6045C101.654 11.8724 99.7905 10.3501 97.1509 10.3501C95.9736 10.337 94.8345 10.7674 93.9598 11.5556C93.0852 12.3438 92.5391 13.4322 92.4301 14.6045H101.902Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M120.969 2.40039H123.517V24.1384H121.312L120.971 21.5919C120.362 22.462 119.547 23.1673 118.598 23.6444C117.65 24.1214 116.597 24.3553 115.536 24.3249C111.158 24.3249 107.928 21.3743 107.928 16.2194C107.928 11.3752 111.158 8.11432 115.536 8.11432C118.206 8.11432 120.008 9.20169 120.971 10.7231L120.969 2.40039ZM121 16.2817C121 12.8028 118.889 10.3813 115.782 10.3813C112.646 10.3813 110.534 12.7724 110.534 16.2194C110.534 19.6664 112.646 22.0583 115.782 22.0583C118.887 22.0583 121 19.6664 121 16.2817Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M127.366 16.2194C127.366 11.4055 130.409 8.11432 134.881 8.11432C139.354 8.11432 142.272 10.9407 142.396 15.6296C142.396 15.9714 142.366 16.3436 142.334 16.7166H130.037V16.9338C130.13 19.9463 132.024 22.0583 135.037 22.0583C137.273 22.0583 139.012 20.8779 139.54 18.8282H142.117C141.496 21.9956 138.888 24.3249 135.223 24.3249C130.471 24.3249 127.366 21.064 127.366 16.2194ZM139.664 14.6045C139.415 11.8724 137.552 10.3501 134.913 10.3501C133.735 10.3371 132.596 10.7674 131.722 11.5556C130.847 12.3438 130.301 13.4322 130.192 14.6045H139.664Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M149.943 16.0034L144.32 8.30206H147.366L151.558 14.1713L155.812 8.30206H158.701L153.048 16.0034L159.104 24.1397H156.06L151.433 17.7736L146.806 24.1384H143.918L149.943 16.0034Z"
          fill="black"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g className="logo-icon">
        <path
          d="M17.5784 32C17.1373 31.9943 16.6988 31.9301 16.2746 31.809C12.5699 30.8188 1.71166 27.9207 1.71166 27.9207C1.61538 27.8957 1.51951 27.8702 1.42485 27.8398C1.12781 27.7623 0.856228 27.6084 0.637141 27.3934C0.418054 27.1783 0.259095 26.9097 0.176079 26.6141C0.111913 26.3273 0.12401 26.0286 0.211167 25.7479C0.298323 25.4672 0.457497 25.2143 0.672837 25.0142C1.60837 24.0644 2.54862 23.1193 3.4936 22.1789C4.38787 21.2841 5.28039 20.3875 6.17116 19.4892C6.27694 19.3755 6.40504 19.2848 6.54744 19.2228C6.68984 19.1608 6.8435 19.1288 6.99882 19.1288C7.16632 19.1337 7.33243 19.1609 7.49274 19.2097C7.96523 19.3375 8.43825 19.464 8.91182 19.5891C9.99797 19.8776 11.1205 20.1761 12.2204 20.4961C12.2813 20.5158 12.3446 20.5269 12.4085 20.5292C12.4559 20.5292 12.5279 20.5292 12.7006 20.3545C13.7313 19.3124 14.7649 18.2741 15.8013 17.2396C16.897 16.1418 17.9909 15.042 19.0828 13.9403C19.1932 13.8179 19.3278 13.7198 19.478 13.6521C19.6283 13.5845 19.791 13.5487 19.9558 13.5471C20.0984 13.549 20.2402 13.5699 20.3773 13.6094C22.5391 14.1899 24.8194 14.791 27.3473 15.446C28.3606 15.6299 29.2657 16.1931 29.8784 17.0208C30.3688 17.718 30.5928 18.5681 30.5096 19.4164C30.4264 20.2648 30.0415 21.0551 29.4249 21.6437C28.535 22.5507 27.6379 23.4518 26.7336 24.3472C26.2625 24.8172 25.7915 25.2881 25.3206 25.7598L23.4003 27.6889C22.3335 28.7617 21.2658 29.8337 20.1973 30.9049C19.8027 31.3197 19.3168 31.6368 18.7782 31.8309C18.388 31.9424 17.9842 31.9993 17.5784 32Z"
          fill="url(#paint0_linear_6288_3860)"
        />
        <path
          d="M18.5724 30.5813C17.8496 30.7876 17.0825 30.7798 16.364 30.5591C11.5308 29.2675 6.69722 27.9768 1.8634 26.6869C1.75418 26.6578 1.64456 26.6303 1.53695 26.5967C0.593193 26.3018 0.320542 25.369 1.00864 24.6708C2.83224 22.8205 4.68133 20.9953 6.50615 19.1458C6.6081 19.044 6.73894 18.9763 6.88084 18.9516C7.02274 18.927 7.16877 18.9468 7.29902 19.0082C8.87263 19.4338 10.4543 19.8367 12.0174 20.2922C12.1953 20.3588 12.3897 20.3673 12.5726 20.3164C12.7556 20.2655 12.9177 20.1579 13.0356 20.009C15.1573 17.865 17.2981 15.7384 19.4182 13.5928C19.5102 13.4853 19.6336 13.4093 19.7711 13.3756C19.9085 13.3419 20.053 13.352 20.1844 13.4047C22.5064 14.0285 24.8314 14.6411 27.1596 15.2425C28.0399 15.4702 28.8421 15.8003 29.376 16.5944C29.7931 17.189 29.983 17.9134 29.9113 18.6361C29.8396 19.3588 29.511 20.0318 28.9853 20.5329C27.6289 21.9143 26.2507 23.2748 24.8838 24.6465C23.1759 26.3604 21.4682 28.0751 19.7608 29.7909C19.4297 30.141 19.0233 30.4113 18.5724 30.5813Z"
          fill="url(#paint1_linear_6288_3860)"
        />
        <path
          d="M10.5288 18.6704C10.4058 18.6697 10.2833 18.6527 10.1647 18.6199C9.11702 18.3383 8.06862 18.0596 7.01955 17.7837C5.55557 17.397 4.09159 17.0099 2.63085 16.6106C2.03141 16.4402 1.4861 16.118 1.04767 15.6751C0.609238 15.2322 0.292528 14.6837 0.128182 14.0826C-0.0361639 13.4815 -0.0425826 12.8481 0.109545 12.2438C0.261673 11.6394 0.567198 11.0846 0.996563 10.6329C3.06909 8.5407 5.14687 6.45348 7.22991 4.37125L9.53773 2.05696C9.6251 1.96958 9.71127 1.88019 9.79743 1.79119C10.1154 1.44159 10.4661 1.12326 10.8447 0.840551C11.4348 0.415183 12.144 0.186846 12.8714 0.188054C13.2293 0.190207 13.5855 0.238748 13.9309 0.332468C15.8882 0.851609 17.8452 1.37278 19.8018 1.89596L28.9016 4.32069C29.2221 4.38291 29.5203 4.52914 29.7657 4.74443C30.0111 4.95973 30.195 5.23635 30.2984 5.546C30.3669 5.84744 30.3538 6.16169 30.2606 6.4564C30.1673 6.75111 29.9971 7.01564 29.7677 7.22276C28.6427 8.37364 27.4886 9.53139 26.3721 10.6511C25.6709 11.3523 24.9719 12.0551 24.275 12.7595C24.1776 12.8623 24.0601 12.944 23.9299 12.9997C23.7996 13.0554 23.6594 13.0838 23.5177 13.0831C23.3867 13.0813 23.2565 13.062 23.1306 13.0257C22.5977 12.8828 22.0644 12.7412 21.5307 12.6009C20.4579 12.3178 19.3491 12.0249 18.2613 11.7195C18.2003 11.7008 18.137 11.6904 18.0732 11.6883C18.019 11.6883 17.9373 11.6883 17.7646 11.8639C16.4124 13.2366 15.0551 14.6046 13.6926 15.9678C12.9229 16.7407 12.1543 17.514 11.3868 18.2878C11.2775 18.4067 11.145 18.502 10.9974 18.5678C10.8498 18.6336 10.6904 18.6686 10.5288 18.6704Z"
          fill="url(#paint2_linear_6288_3860)"
        />
        <path
          d="M0.658668 13.2365C0.488203 12.7272 0.465795 12.1801 0.594049 11.6586C0.722304 11.1371 0.995953 10.6628 1.38317 10.2907C4.22268 7.42505 7.0696 4.56639 9.92393 1.71475C10.3013 1.29447 10.7144 0.907659 11.1585 0.558621C11.5356 0.290713 11.9691 0.112911 12.4256 0.0389207C12.8822 -0.0350698 13.3497 -0.00327816 13.792 0.131843C16.6312 0.884531 19.4695 1.64073 22.3069 2.40043C24.4587 2.97404 26.6105 3.54725 28.7623 4.12006C29.844 4.40808 30.149 5.32879 29.3792 6.11681C27.5637 7.9752 25.717 9.80366 23.8894 11.6507C23.811 11.7313 23.7102 11.7864 23.6001 11.8087C23.49 11.8311 23.3756 11.8197 23.2721 11.7761C21.6511 11.3417 20.0249 10.9266 18.4093 10.4719C18.2289 10.4072 18.033 10.3999 17.8484 10.4511C17.6637 10.5022 17.4995 10.6093 17.3781 10.7575C15.2604 12.9068 13.1254 15.0386 11 17.1806C10.9164 17.2776 10.8057 17.3473 10.6821 17.3807C10.5585 17.4142 10.4277 17.4099 10.3066 17.3683C7.79855 16.6944 5.28159 16.0443 2.77515 15.361C2.26013 15.2378 1.7895 14.9739 1.41576 14.5987C1.04203 14.2236 0.779914 13.7519 0.658668 13.2365Z"
          fill="url(#paint3_linear_6288_3860)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6288_3860"
            x1="56.4198"
            y1="-102.687"
            x2="87.8138"
            y2="-102.687"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.001" stopColor="#00C3C2" />
            <stop offset="0.418" stopColor="#00CCC5" />
            <stop offset="1" stopColor="#00E2CE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6288_3860"
            x1="57.118"
            y1="-103.47"
            x2="87.4158"
            y2="-103.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00E2CE" />
            <stop offset="0.321" stopColor="#00C9BD" />
            <stop offset="0.746" stopColor="#00AFAC" />
            <stop offset="0.999" stopColor="#00A6A6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_6288_3860"
            x1="59.7731"
            y1="-117.6"
            x2="91.116"
            y2="-117.6"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF6F00" />
            <stop offset="0.478" stopColor="#FF6100" />
            <stop offset="1" stopColor="#FF5800" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_6288_3860"
            x1="60.5679"
            y1="-118.358"
            x2="90.8596"
            y2="-118.358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF3D46" />
            <stop offset="0.394" stopColor="#FA5224" />
            <stop offset="0.774" stopColor="#F66309" />
            <stop offset="0.999" stopColor="#F56A00" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  );
};

SchedexLogo.defaultProps = {
  showText: true,
};

export default SchedexLogo;
