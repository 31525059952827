import { FC, memo, useEffect, useCallback } from 'react';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import UntitledDialog from '../UntitledDialog/UntitledDialog';
import UntitledDialogHeader from '../UntitledDialog/UntitledDialogHeader';
import { DialogName } from '../../enums';
import Save01 from '../../icons/Save01';
import UntitledDialogFooter from '../UntitledDialog/UntitledDialogFooter';

interface RouterPromptProps {
  when: boolean;
  onLeave?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  message: string | (string | JSX.Element)[];
}

const RouterPrompt: FC<RouterPromptProps> = memo(function RouterPrompt(props: RouterPromptProps) {
  const { when, onLeave, onCancel, okText, cancelText, message } = props;

  // Allow the submission navigation to the same route to go through
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname,
    [when],
  );
  const blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
      onCancel?.();
    }
  }, [blocker, when, onCancel]);

  useEffect(() => {
    return () => {
      blocker.reset?.();
    };
  }, [blocker]);

  const handleClose = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const handleLeave = useCallback(() => {
    if (blocker.state !== 'proceeding') {
      onLeave?.();
      blocker.proceed?.();
    }
  }, [blocker, onLeave]);

  const handleCancel = useCallback(() => {
    if (blocker.state !== 'proceeding') {
      onCancel?.();
      blocker.reset?.();
    }
  }, [blocker, onCancel]);

  return (
    <UntitledDialog open={blocker.state === 'blocked'} dialogId={DialogName.ROUTER_PROMPT}>
      <UntitledDialogHeader
        title="Unsaved changes"
        Icon={Save01}
        onClose={handleClose}
        theme="light-color-outline"
        type="horizontal-left-aligned"
        color="error"
        subTitle={message}
      />

      <UntitledDialogFooter
        color="primary"
        type="horizontal"
        leftButtonText={okText}
        rightButtonText={cancelText}
        leftButtonProps={{
          color: 'error',
          onClick: handleLeave,
        }}
        rightButtonProps={{
          color: 'primary',
          onClick: handleCancel,
        }}
      />
    </UntitledDialog>
  );
});

RouterPrompt.defaultProps = {
  cancelText: 'Cancel',
  okText: 'Leave',
  onCancel: () => {},
  onLeave: () => {},
};

export default RouterPrompt;
