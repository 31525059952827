import { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import Button from '@mui/material/Button';
import { Colors } from '../../types/Shared';
import CheckCircle from '../../icons/CheckCircle';
import AlertCircle from '../../icons/AlertCircle';
import InfoCircle from '../../icons/InfoCircle';
import XClose from '../../icons/XClose';

import './UntitledAlert.scss';

export interface UntitledAlertProps {
  className?: string;
  text: string | undefined;
  open: boolean;
  color?: Colors | 'default';
  size?: 'full' | 'floating';
  title?: string;
  hideActions?: boolean;
  onActionClick?: () => void;
  onClose?: (id?: string | number) => void;
  id?: string | number;
}

const UntitledAlert: FC<UntitledAlertProps> = forwardRef(function UntitledAlert(
  props: UntitledAlertProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { open, text, className, color, size, title, hideActions, onActionClick, onClose, id } =
    props;
  const [show, setShow] = useState(open);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleClose = () => {
    if (onClose) onClose?.(id);
    else setShow(false);
  };

  const icon = useMemo(() => {
    if (color === 'success') return <CheckCircle />;
    if (color === 'error' || color === 'warning') return <AlertCircle />;
    return <InfoCircle />;
  }, [color]);

  return (
    <div
      ref={ref}
      className={`untitled-alert-container ${className} ${
        show ? '' : 'hide'
      } color-${color} size-${size}`}
    >
      <div className="alert-icon-container">
        {color !== 'default' ? (
          <>
            <span className="outer-border" />
            <span className="inner-border" />
          </>
        ) : null}
        {icon}
      </div>

      <div className="content">
        <div className="content-text">
          {title && <div className="alert-title">{title}</div>}
          <div className="alert-description">{text}</div>
        </div>

        {!hideActions && (
          <div className="actions">
            <Button onClick={handleClose} sx={{ padding: 0, minWidth: 'auto' }} variant="text">
              Dismiss
            </Button>
            <Button
              sx={{
                padding: 0,
                minWidth: 'auto',
              }}
              variant="text"
              onClick={onActionClick}
            >
              {color !== 'error' && color !== 'warning' ? 'View changes' : 'Learn more'}
            </Button>
          </div>
        )}
      </div>
      <IconButton className="alert-close-icon-container" size="small" onClick={handleClose}>
        <XClose
          strokeWidth="1.66667"
          width="20"
          height="20"
          pathProps={{
            d: 'M15 5L5 15M5 5L15 15',
          }}
        />
      </IconButton>
    </div>
  );
});

UntitledAlert.defaultProps = {
  className: '',
  size: 'floating',
  color: 'default',
  title: '',
  hideActions: false,
  onActionClick: () => {},
  onClose: undefined,
  id: undefined,
};

export default UntitledAlert;
