import { Components, Theme } from '@mui/material';
import untitledTypography from '../themeTypography';
import colors from '../themeColorPalette';
import themeShadows from '../themeShadows';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        textTransform: 'capitalize',
        borderRadius: '8px',
        whiteSpace: 'nowrap',
        gap: '8px',
        ...untitledTypography.text.sm.semibold,

        '.MuiButton-endIcon, .MuiButton-startIcon': {
          margin: 0,
        },

        ...(ownerState.size === 'small' && {
          padding: '8px 14px',
          height: '36px',
        }),

        ...(ownerState.size === 'medium' && {
          padding: '10px 16px',
          height: '40px',
        }),

        ...(ownerState.size === 'large' && {
          padding: '10px 18px',
          height: '44px',
          ...untitledTypography.text.md.semibold,
        }),
        ...(ownerState.variant === 'contained' && {
          boxShadow: themeShadows.xs,
          ...(ownerState.color === 'primary' && {
            borderColor: colors.primary[600],
            ':hover': {
              borderColor: colors.primary[700],
              backgroundColor: colors.primary[700],
            },

            ':focus': {
              borderColor: colors.primary[600],
              backgroundColor: colors.primary[600],
              boxShadow: ' 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E6F6F6',
            },

            ':disabled': {
              borderColor: colors.primary[300],
              backgroundColor: colors.primary[300],
              color: '#FFF',
            },
          }),
          ...(ownerState.color === 'error' && {
            borderColor: colors.error[600],
            ':hover': {
              borderColor: colors.error[700],
              backgroundColor: colors.error[700],
            },

            ':focus': {
              borderColor: colors.error[600],
              backgroundColor: colors.error[600],
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
            },

            ':disabled': {
              borderColor: colors.error[200],
              backgroundColor: colors.error[200],
              color: '#FFF',
            },
          }),
        }),

        ...(ownerState.variant === 'outlined' && {
          boxShadow: themeShadows.xs,
          backgroundColor: '#FFF',
          ...(ownerState.color === 'primary' && {
            borderColor: colors.grey[300],
            color: colors.grey[700],
            ':hover': {
              backgroundColor: colors.grey[50],
              borderColor: colors.grey[300],
            },

            ':focus': {
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7',
              borderColor: colors.grey[300],
            },

            ':disabled': {
              borderColor: colors.primary[200],
              backgroundColor: '#FFF',
              color: colors.grey[300],
            },
          }),
          ...(ownerState.color === 'error' && {
            borderColor: colors.error[300],
            background: '#FFF',
            color: colors.error[700],
            ':hover': {
              backgroundColor: colors.error[50],
              borderColor: colors.error[300],
            },

            ':focus': {
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
              borderColor: colors.error[300],
            },

            ':disabled': {
              borderColor: colors.error[200],
              backgroundColor: '#FFF',
              color: colors.error[300],
            },
          }),
        }),

        ...(ownerState.variant === 'text' && {
          ...(ownerState.color === 'primary' && {
            color: colors.grey[600],
            ':hover': {
              backgroundColor: colors.grey[50],
            },

            ':disabled': {
              color: colors.grey[300],
            },
          }),
          ...(ownerState.color === 'error' && {
            color: colors.error[700],
            ':hover': {
              backgroundColor: colors.error[50],
            },

            ':disabled': {
              color: colors.error[300],
            },
          }),
        }),
      }),
    },
  },
};

export default components.MuiButton;
