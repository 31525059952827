import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import {
  acceptOffRequestFail,
  acceptOffRequestStarted,
  acceptOffRequestSuccess,
  rejectOffRequestFail,
  rejectOffRequestStarted,
  rejectOffRequestSuccess,
} from '../slices/manageOffRequestsInSchedule';
import { ACCEPT_OFF_REQUEST, REJECT_OFF_REQUEST } from '../actionTypes/weeklySchedule';
import { actions } from '../slices/weeklyScheduleSlice';
import utils from '../../shared/utils';
import { isResponseSuccess } from '../../shared/utils/general';
import { Response } from '../../types/Response';
import { UpdateOffRequest } from '../../types/WeeklySchedule';

function* acceptOffRequest({ payload }: PayloadAction<UpdateOffRequest>) {
  try {
    yield put(acceptOffRequestStarted(payload));
    const response: Response<boolean> = yield call(() =>
      api.requests.putOffApprove(payload.branchId, payload.requestId, {
        manager_id: payload.managerId,
      }),
    );
    if (isResponseSuccess(response.data)) {
      yield put(acceptOffRequestSuccess(response.data));
      yield put(actions.updateOffRequest(payload));
    } else {
      yield put(acceptOffRequestFail(response.data));
    }
  } catch (error) {
    yield put(acceptOffRequestFail(utils.general.convertAxiosError(error)));
  }
}

function* rejectOffRequest({ payload }: PayloadAction<UpdateOffRequest>) {
  try {
    yield put(rejectOffRequestStarted(payload));
    const response: Response<boolean> = yield call(() =>
      api.requests.putOffReject(payload.branchId, payload.requestId, {
        manager_id: payload.managerId,
      }),
    );
    if (isResponseSuccess(response.data)) {
      yield put(rejectOffRequestSuccess(response.data));
      yield put(actions.updateOffRequest(payload));
    } else {
      yield put(rejectOffRequestFail(response.data));
    }
  } catch (error) {
    yield put(rejectOffRequestFail(utils.general.convertAxiosError(error)));
  }
}

function* watchManageOffRequestsInSchedule() {
  yield all([
    takeLatest(ACCEPT_OFF_REQUEST, acceptOffRequest),
    takeLatest(REJECT_OFF_REQUEST, rejectOffRequest),
  ]);
}

export default watchManageOffRequestsInSchedule;
