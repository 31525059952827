import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import LINKS from './NavLinksConfig';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import NavItem from './NavItem';

import './NavLinks.scss';

type NavLinksProps = {
  open: boolean;
};

function NavLinks(props: NavLinksProps) {
  const { open } = props;
  const location = useLocation();
  const { branch_id: selectedBranchId } = useSettings();
  const {
    auth: {
      userData: { roles },
    },
  } = useAuth();

  const currentRole = useMemo(
    () => roles.find((_role) => _role.branch_id === selectedBranchId),
    [selectedBranchId, roles],
  );

  const navLinks = useMemo(
    () =>
      LINKS.upper.filter((link) => {
        if (link.requiredFeatures?.length) {
          return link.requiredFeatures.every(
            (featureId) => currentRole?.features?.includes(featureId),
          );
        }
        return true;
      }),
    [currentRole],
  );

  return (
    <List component="nav" className={`untitled-navigation ${open ? 'open' : ''} `}>
      {navLinks.map((link) => (
        <NavItem link={link} open={open} pathname={location.pathname} key={link.id} />
      ))}
    </List>
  );
}

export default NavLinks;
